import React from 'react';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import * as constants from '../../constants';
import i18next from "../../i18n";
import { PasswordInput } from '../forms/PasswordInput';
import { checkPassword, checkPhone } from '../helpers/CheckFunctions';
import { AccountFormHeader } from './AccountFormHeader';
import { AccountMessageContainer } from './AccountMessageContainer';
import * as AccountHelper from "./AccountHelper"
export class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        let pathArray = window.location.href.split('?');
        let code = '';
        let isError = false;
        if (pathArray.length > 1)
            code = pathArray[1];
        else
            isError = true;
        this.state = {
            currentCompanyName: '',
            password: '',
            firstName: '',
            lastName: '',
            position: '',
            phone: '',
            passwordRepeat: '',
            submitted: false,
            incorrectLogin: false,
            isError: isError,
            code: code,
            companyName: '',
            ready: false,
            errorText: '',
            iAgree: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.register = this.register.bind(this);
    }
    componentDidMount() {
        require('../helpers/HelperFunctions').getCurrentCompanyInfo().then((info) => { this.setState({ currentCompanyName: info?.name }); })
    }
    handleChange(e) {
        const { name, value, type } = e.target;
        this.setState({ [name]: type === "checkbox" ? e.target.checked : value });
    }
    handleSubmit(e) {
        e.preventDefault();
        const { code, incorrectLogin, firstName, position, lastName, phone, password, passwordRepeat } = this.state;
        this.setState({ submitted: true });
        if (code && !incorrectLogin && position && firstName && lastName && phone && checkPhone(phone) && password && password === passwordRepeat && checkPassword(password)) {
            const formData = new FormData();
            ["password", "firstName", "lastName", "position", "phone", "code"]
                .forEach(item => formData.append(item, this.state[item]));
            this.register(formData);
        }
    }
    register(data) {
        const currentObject = this;
        fetch('/account/register', {
            method: "POST",
            headers: { "Contetnt-Type": "multipart/form-data" },
            body: data
        })
            .then(async (response) => {
                if (response.ok) {
                    currentObject.setState({ ready: true });
                }
                else {
                    let error = response.ok ? "" : await response.text();
                    if (error === "userAlreadyRegistered") {
                        require('../ModalDialog').messageDialog(i18next.t("error"), i18next.t("alreadyRegisteredMessage"), () => {
                            constants.History.push(constants.urls.loginPage);
                        });
                    }
                    else require('../ModalDialog').unknownErrorMessageDialog();
                }
            });
    }
    render() {
        const { ready, errorText, password, passwordRepeat, firstName, lastName, position,
            submitted, phone, iAgree, currentCompanyName } = this.state;
        if (ready)
            return (<AccountMessageContainer>
                {errorText ?
                    <div className="form-content">
                        <div className="info-message-title">{i18next.t("error")}</div>
                        <div className="info-message">{errorText}</div>
                    </div> : ""}
                {!errorText ? <div className="form-content">
                    <div className="info-message-title">{i18next.t("done")}</div>
                    <div className="info-message">{i18next.t("registerCompletedMessage")}</div>
                    <div className="form-group form-group__last">
                        <NavLink className="bottom-form-link message-form-link" tag={Link} to={constants.urls.loginPage} >
                            {i18next.t("logInToYourAccount")} <div className="collapsed-menu-icone"></div>
                        </NavLink>
                    </div>
                </div> : ""}
            </AccountMessageContainer>);
        return (
            <div className="form-page register-large-form">
                <div className="form-block large-form">
                    <AccountFormHeader className="form-header-subtitle-right form-subtitle" />
                    <div className="form-content">
                        <div className="form-title">Реєстрація в системі</div>
                        <form name="form">
                            <div className="row">
                                {currentCompanyName &&
                                    <div className="col-lg-12">
                                        <label className="form-info-label">
                                            <img src="/img/info-icon.svg" alt="" /> {i18next.t("registerByInvitationInfoText").replaceAll('{companyName}', currentCompanyName)}
                                        </label>
                                    </div>
                                }
                                <div className="col-lg-6">
                                    <div className={'form-group' + (submitted && !lastName ? ' has-error' : '')}>
                                        <label htmlFor="lastName" className="required">{i18next.t("lastName")}</label>
                                        <input className="form-control" name="lastName" value={lastName} onChange={this.handleChange} />
                                        {AccountHelper.isRequiredMessage(submitted && !lastName)}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className={'form-group' + (submitted && !firstName ? ' has-error' : '')}>
                                        <label htmlFor="firstName" className="required">{i18next.t("firstNameAndMiddleName")}</label>
                                        <input className="form-control" name="firstName" value={firstName} onChange={this.handleChange} />
                                        {AccountHelper.isRequiredMessage(submitted && !firstName)}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className={'form-group' + (submitted && !position ? ' has-error' : '')}>
                                        <label htmlFor="position" className="required">{i18next.t("positionHeld")}</label>
                                        <input className="form-control" name="position" value={position} onChange={this.handleChange} />
                                        {AccountHelper.isRequiredMessage(submitted && !position)}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className={'form-group' + (submitted && !phone ? ' has-error' : '')}>
                                        <label htmlFor="phone" className="required">{i18next.t("phone")}</label>
                                        <InputMask type="tel" className="form-control" name="phone" mask='+380999999999' maskChar={null} value={phone} onChange={this.handleChange} />
                                        {AccountHelper.isRequiredMessage(submitted && !phone)}
                                        {AccountHelper.isBadFormatMessage(submitted && phone && !checkPhone(phone))}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" className="required">{i18next.t("password")}</label>
                                        <PasswordInput className="form-control" name="password" value={password} onChange={this.handleChange} />
                                        {AccountHelper.isRequiredMessage(submitted && !password)}
                                        {AccountHelper.errorMessage(submitted && password && !checkPassword(password), 'incorrectPasswordErrorText')}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className={'form-group' + (submitted && !passwordRepeat ? ' has-error' : '')}>
                                        <label htmlFor="passwordRepeat" className="required">{i18next.t("confirmPassword")}</label>
                                        <PasswordInput className="form-control" name="passwordRepeat" value={passwordRepeat} onChange={this.handleChange} />
                                        {AccountHelper.isRequiredMessage(submitted && !passwordRepeat)}
                                        {AccountHelper.errorMessage(submitted && passwordRepeat && password && passwordRepeat !== password, 'passwordsDoNotMatch')}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={'form-group'}>
                                        <div className="tape-checkbox-form">
                                            <div>
                                                <input id="iAgree" name="iAgree" className="form-control" type="checkbox" onChange={this.handleChange} checked={iAgree} />
                                                <label htmlFor="iAgree"></label>
                                            </div>
                                            <label>{i18next.t("iAgreeWithOfferText")} <a onClick={() => import('../ModalDialog').then(templates => { templates.viewFileFolderSystemFile(`/home/getOfferAgreementFileContent`, i18next.t("offerAgreement")) })}>{i18next.t("iAgreeWithOfferLink", { company: currentCompanyName })}</a></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-footer">
                        <div className="form-group form-group__last">
                            {iAgree && <button className="btn btn-primary" onClick={this.handleSubmit}>{i18next.t("register")}</button>}
                            <NavLink className="bottom-form-link" tag={Link} to={constants.urls.loginPage} >
                                {i18next.t("youAlreadyHaveAnAccount")} <div className="collapsed-menu-icone"></div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}