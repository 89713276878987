import React from 'react';
import i18next from "../../i18n";
import { AccountFormHeader } from './AccountFormHeader';
import * as HelperFunctions from '../helpers/HelperFunctions';
export function SessionEndedPage(props) {
    return (<div className="form-page" >
        <div className="form-block large-form">
            <AccountFormHeader className="form-header-subtitle-right form-subtitle" />
            <div className="form-content">
                <div className="form-group">
                    <div className="info-message-title">{i18next.t("attentionTitle")}</div>
                    <div className="info-message">{i18next.t("sessionEndedMessage")}</div>
                </div>
                <div className="form-group form-group__last"><button className="btn btn-primary" onClick={HelperFunctions.logout}>{i18next.t("ok")}</button></div>
            </div>
        </div>
    </div>);
}