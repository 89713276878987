import React from 'react';
import { History, actionTypes, alfrescoTypes, attachmentStatuses, documentBpActions, signatureStates, documentStatuses, formFieldTypes, formModes, statutoryDocTypes, searchTableTypes, alfrescoRoles, allowedAdditionalFileExtensions, acceptAdditionalInputFileString } from '../../constants';
import { buildFormField, getFormSection, getFormSectionSettings, padWithZeroes, InputContainer } from '../form/Form';
import { AttachmentForm } from './attachments/AttachmentForm';
import { CommentForm } from '../comments/CommentForm';
import { buildComentAction } from '../comments/Comment';
import { CheckSignsForm } from '../forms/CheckSignsForm';
import { SignForm } from '../forms/SignForm';
import { StatutoryDocsForm } from '../forms/StatutoryDocsForm';
import * as HelperFunctions from '../helpers/HelperFunctions';
import * as ModalDialogsTemplates from '../ModalDialog';
import * as ModalDialog from '../ModalDialog';
import { Svg } from '../Svg';
import { DocumentInfo } from './DocumentInfo';
import { InfoBtnControl } from '../InfoBtnControl';
import { documentTabs, statuses } from './Document';
import * as ListOfContracts from '../listsOfAlfresco/ListOfContracts';
import * as ListOfUsersTaskExecuters from '../listsOfAlfresco/ListOfUsersTaskExecuters';
import { ListOfAlfresco } from '../listsOfAlfresco/ListOfAlfresco';
import { Table } from '../table/Table';
import { clientSettings } from '../../settings/clientSettings';
import { DynamicForm } from './DynamicForm';
import * as ListOfDocuments from '../listsOfAlfresco/ListOfDocuments';
import i18n from '../../i18n';
import { v4 } from 'uuid';
const mime = require("mime-types");
export const getFile = async (id, type, asZip = false, withSign = false) => await fetch(`file/${type === alfrescoTypes.counterparties ? "GetXml" : `get${asZip ? "Zip" : ""}`}?nodeRefs=${id.split('/').pop()}&withSign=${withSign}`, { method: 'post', headers: { 'Content-Type': 'application/json' } }).then(async (reponse) => (await reponse.json())[0]);

export async function downloadFile(id, title = "", type = "", asZip = false, withSign = false) {
	try {
		var data = await getFile(id, type, asZip, withSign);
		if (data.content) {
			downloadContent(data, title);
			return true;
		}
	}
	catch (e) {
		console.error('Get file error');
	}
	return false;
}
export const downloadSign = async(id, title = "") => downloadContent(await (await fetch(`/file/Signs?id=${id}`, { method: 'post', headers: { 'Content-Type': 'application/json' } })).json(), title);
export async function downloadContent(data, title) {
	try {
		let fileExtension = mime.extension(data.mimeType);
		if (fileExtension)
			fileExtension = '.' + fileExtension;
		else
			fileExtension = '';
		let anchor = document.createElement("a");
		anchor.href = `data:${data.mimeType};base64,${data.content}`;
		anchor.download = `${title ? title : data.fileName}${fileExtension}`;
		anchor.click();
	} catch (e) { ModalDialogsTemplates.errorMessageDialog('1'); }
}
export async function getComents() {
	ModalDialog.hideModalDialog('modalDialog');
	const { prop_sys_node_uuid } = this.state;
	if (prop_sys_node_uuid) this.setState({ comments: await buildComentAction('GET', `comments/get/${prop_sys_node_uuid}`) });
}
export async function addComment(comment) {
	const { prop_sys_node_uuid } = this.state;
	if (prop_sys_node_uuid) await buildComentAction('POST', 'comments/crud', {
		action: 'addComment',
		content: comment.content,
		documentNodeRef: prop_sys_node_uuid,
	});
	else this.setState({ comments: [...this.state.comments, comment] })
	getComents.bind(this)()
}
export async function getFormSetting(type) {
	const response = await fetch(`Document/Form/${type}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
	if (response.ok) return await response.json();
	handleGetDocumentError(response.status);
}
export function getFormFields() {
	const { form, prop_sx_dfState } = this.state;
	let result = {};
	for (let i = 0; i < form?.Fields?.length; ++i) {
		if (!form.Fields[i].Ignore && (!form.Fields[i].IgnoreWhile || form.Fields[i].IgnoreWhile.indexOf(prop_sx_dfState) === -1)) {
			let { Name, Type } = form?.Fields[i];
			let fieldValue = this.state[Name];
			if (fieldValue && Type === formFieldTypes.datetime) {
				if (fieldValue.includes("T")) {
					let date = HelperFunctions.getLocalDateString(new Date(fieldValue)).split(".");
					fieldValue = `${date[2]}-${date[1]}-${date[0]}`;
				}
				else {
					fieldValue = fieldValue.split("-");
					fieldValue = `${padWithZeroes(fieldValue[0], 4)}-${fieldValue[1]}-${fieldValue[2]}`;
				}
			}
			result[Name] = (fieldValue || Type === formFieldTypes.bool) ? fieldValue : (Type === formFieldTypes.datetime ? null : "");
		}
	}
	return result;
}
export function handlerCloseDocument(pathname) {
	const { formMode, type } = this.state;
	if ((type && (pathname?.toLowerCase() !== window.location.pathname.toLowerCase())) || !pathname) {
		if (formMode !== formModes.read) {
			let closeDialog = () => ModalDialog.hideModalDialog('modalDialog');
			let handlerCloseCreateForm = function () {
				closeDialog();
				if (pathname) {
					History.block(true);
					History.push(pathname);
				}
				else window.location.replace("/");
			}
			ModalDialog.showModalDialog('modalDialog', i18n.t("attentionTitle"), "", <div className="info-message">{i18n.t("ifNeedSaveMessage")}</div>, "feedPopup", true, [{ title: i18n.t("save"), clickHandler: () => { ModalDialog.hideModalDialog('modalDialog'); this.actionHandlerCallback(actionTypes.save); }, class: "btn-primary" }, { title: "Не зберігати", clickHandler: () => formMode === formModes.create ? handlerCloseCreateForm() : (() => window.location.reload())(), class: "btn-secondary" }, { title: i18n.t("cancel"), clickHandler: closeDialog, class: "btn-secondary" }], () => closeDialog());
		}
		else History.push(alfrescoTypes.counterparties === type ? '/' : '/documents');
		return false
	}
	return true;
}
export function handleChangeRelatedDocuments(type = '', rows = []) {
	switch (type) {
		case actionTypes.add:
		case actionTypes.remove:
			this.state["assoc_sx_relatedDocuments_" + (actionTypes.add ? "added" : "removed")] = rows.map((item) => "workspace://SpacesStore/" + item.columns.Id).join(',');
			break;
	}
	this.actionHandlerCallback(actionTypes.save);
}
export async function addFile(e) {
	e.persist();
	if (ModalDialogsTemplates.isValidFileSizeDialog(e.target.files[0].size))
		this.setState({
			additionalDocuments: [...this.state.additionalDocuments, { id: (new Date().getTime()).toString(), file: e.target.files[0], fileName: e.target.files[0]?.name }]
		});
	e.target.value = null;
}
export async function handleGetDocumentError(code) {
	switch (code) {
		case 404: History.replace("/404"); break;
		default: require('../ModalDialog').unknownErrorMessageDialog(window.location.pathname + window.location.search); break;
	}
}
export async function signDocument(ids = []) {
	const { type, prop_sys_node_uuid, attachments } = this.state;
	ids.push(...(attachments ? attachments?.filter(item => item?.nodeRef && item.state !== attachmentStatuses.noDoc)?.map(item => item.nodeRef) : []));
	let handlerResult = function (isSuccessResult = true) {
		if (isSuccessResult) {
			let onClodeHandler = function () {
				ModalDialog.hideModalDialog('modalDialog');
				window.location.reload();
			}
			ModalDialog.showModalDialog('modalDialog', i18n.t("done"), "", <div className="info-message">{i18n.t("Документ успішно підписано")}</div>, "feedPopup", true, [{ title: i18n.t("ok"), clickHandler: onClodeHandler, class: "btn-primary" }], onClodeHandler);
		}
	}
	await signFiles.bind(this)(ids.concat(type !== alfrescoTypes.counterparties ? [prop_sys_node_uuid] : []), {}, handlerResult, false, false, type === alfrescoTypes.contract || type === alfrescoTypes.universalContract);
}
export async function signFiles(ids = [], body = {}, handlerResult = null, withoutStamp = false, withoutSign = false, isSingleFile) {
	let fielesContent = [];
	let requestOptions = { method: 'post', headers: { 'Content-Type': 'application/json' } };
	for (let i = 0; i < ids.length; ++i) {
		const response = await fetch(`file/GetFileHash?id=${ids[i]}`, requestOptions);
		if (!response.ok) return require('../ModalDialog').unknownErrorMessageDialog();
		fielesContent.push({ id: ids[i], value: await response.text() });
	}
	let onSignHandler = async function (signs) {
		let isSuccessResult = true;
		for (let j = 0; j < signs.length; ++j) {
			requestOptions.body = JSON.stringify(signs[j].value);
			let response = await fetch(`File/AddSignature?id=${signs[j].id}`, requestOptions);
			if (!response.ok) {
				isSuccessResult = false;
				if (handlerResult) handlerResult(false);
				require('../ModalDialog').unknownErrorMessageDialog();
			}
			else if (!isSingleFile) {
				requestOptions.body = JSON.stringify({ properties: { state: attachmentStatuses.Signed }, currentElementNodeRef: `workspace://SpacesStore/${signs[j].id.indexOf("/") !== -1 ? signs[j].id.split("/").pop() : signs[j].id}`, assocName: "sx:additionalFiles" });
				let response = await fetch(`Document/EditAdditionalFiles`, requestOptions);
				if (!response.ok) {
					isSuccessResult = false;
					if (handlerResult) handlerResult(false);
					require('../ModalDialog').unknownErrorMessageDialog();
				}
			}
		}
		if (handlerResult) handlerResult(isSuccessResult);
	}
	ModalDialog.showModalDialog('modalDialog', i18n.t("digitalSignature"), "", <SignForm files={fielesContent} handlerRestart={() => {
		handlerResult(false);
		signFiles.bind(this)(ids, body, handlerResult, withoutStamp, withoutSign);
	}} onSignHandler={onSignHandler.bind(this)} onCloseHandler={() => { ModalDialog.hideModalDialog('modalDialog'); handlerResult(false); }} withoutStamp={withoutStamp} withoutSign={withoutSign} />, "feedPopup middle-size", true, [], () => {
		handlerResult(false);
		ModalDialog.hideModalDialog('modalDialog');
	});
}
export async function checkDocumentSigns() {
	const { attachments, attachmentTypes, type, prop_sys_node_uuid } = this.state;
	let ids = attachments.filter(at => at.signs !== null && at.signs?.length > 0).map((item) => Object.assign(item, { documentTypeValue: attachmentTypes?.filter(type => type.value === item.documentType)[0]?.title })).map((item) => item.nodeRef);
	checkSigns.bind(this)(ids.concat([prop_sys_node_uuid]), type === alfrescoTypes.counterpartyAccreditationCard ? i18n.t("xmlDocumentTypeValue") : "");
}
export async function checkSigns(ids, documentTypeValue = " ") {
	const prop_sys_node_uuid = this?.state?.prop_sys_node_uuid;
	let withSignatures = (await Promise.all(ids.map(async (item) => await fetch(`File/Sign/${item.split('/').pop()}`)
		.then(async (response) => {
			if (!response.ok) require('../ModalDialog').unknownErrorMessageDialog();
			else return { signs: (await response.json())?.signatures, documentTypeValue: prop_sys_node_uuid === item.split('/').pop() ? documentTypeValue : "" };
		})))).filter(item => item.signs?.length > 0);
	ModalDialog.showModalDialog('modalDialog', i18n.t("listOfSignatures"), "", <CheckSignsForm signedFiles={withSignatures} docId={prop_sys_node_uuid} />, "feedPopup check-signs-dialog", true, [{ title: i18n.t("ok"), clickHandler: () => ModalDialog.hideModalDialog('modalDialog'), class: "btn-primary" }], null, [{ src: '/img/icn-refresh.svg', onClickCallback: () => checkSigns.bind(this)(ids, documentTypeValue) }]);
}
export async function documentActionHandlerCallback(actionType, actionRequest) {
	const { prop_sys_node_uuid, type } = this.state;
	let checkInputValues = () => {
		if (this.checkInputPage) {
			const checkResult = this.checkInputPage();
			if (checkResult.error) {
				showCheckFailedMessage.bind(this)(checkResult);
				return false;
			}
		}
		return true;
	}
	let currentObject = this;
	this.setState({ submitted: true });
	switch (Number(actionType)) {
		case actionTypes.invoice: window.location.replace(`/content/sx:Invoice?parent=${prop_sys_node_uuid}`);
		case actionTypes.score: window.location.replace(`/content/sx:score?parent=${prop_sys_node_uuid}`);
		case actionTypes.cancel: handlerCloseDocument.bind(this)(); break;
		case actionTypes.edit: return this.setState({ formMode: formModes.edit });
		case actionTypes.changeTaslExecuter: return ModalDialog.showModalDialog("loadingDialog", i18n.t("14"), "", <ListOfAlfresco prop_sys_node_uuid={prop_sys_node_uuid} parentComponent={ListOfUsersTaskExecuters} type={searchTableTypes.Users} />, "feedPopup middle-size");
		case actionTypes.reject: return ModalDialog.showModalDialog('modalDialog', i18n.t("attentionTitle"), "", <div className="info-message">{i18n.t("rejectDocumentMessage")}</div>, "feedPopup", true, [{ title: i18n.t("yes"), clickHandler: () => currentObject.actionHandlerCallback(actionTypes.save, actionType), class: "btn-primary" }, { title: i18n.t("no"), clickHandler: () => { ModalDialog.hideModalDialog('modalDialog') }, class: "btn-secondary" }]);
		case actionTypes.save:
			if (checkInputValues())
				await handleSubmit.bind(this)(actionRequest ? actionRequest : "");
			break;
		case actionTypes.annul: return ModalDialog.showModalDialog('modalDialog', i18n.t("attentionTitle"), "", <div className="info-message">{i18n.t("cancelDocumentMessage")}</div>, "feedPopup", true, [{ title: i18n.t("yes"), clickHandler: () => currentObject.actionHandlerCallback(actionTypes.save, actionRequest), class: "btn-primary" }, { title: i18n.t("no"), clickHandler: () => { ModalDialog.hideModalDialog('modalDialog') }, class: "btn-secondary" }]);
		case actionTypes.sign:
			if (checkInputValues()) {
				if (type === alfrescoTypes.counterparties && !this.checkAttachmentsMandatoryAdding()) {
					ModalDialog.showModalDialog('modalDialog', i18n.t("attentionTitle"), "", <div className="info-message">{i18n.t("notAllFileAdded")}</div>, "feedPopup", true, [{ title: i18n.t("ok"), clickHandler: () => { this.setState({ activeTab: documentTabs.docs }); ModalDialog.hideModalDialog('modalDialog') }, class: "btn-primary" }], () => this.setState({ activeTab: documentTabs.docs }));
				}
				else signDocument.bind(this)();
			}
			break;
	}
}
export async function deleteDocument(id) {
	await fetch(`Document/Delete?id=${id}`, { method: "DELETE" })
		.then(res => {
			if (!res.ok) require('../ModalDialog').unknownErrorMessageDialog();
		});
}
export function buildFormSection(sectionName) {
	const { form, submitted, formMode, type } = this.state;
	let section = getFormSection(form, sectionName);
	let sectionSettings = getFormSectionSettings(form, sectionName);
	let sectionItems = section.sectionItems;
	if (sectionItems) {
		let fields = [];
		for (let i = 0; i < sectionItems.length; ++i) {
			let item = JSON.parse(JSON.stringify(sectionItems[i]));
			if (item?.Name) item.Value = item.Name.split('+').map(value => this.state[value]).join(' ');
			item = this?.buildFormItem ? this.buildFormItem.bind(this)(item) : item;
			if (item) fields.push(item);
			else if (item?.Type === formFieldTypes.select && !this.state[item.Name])
				item.Value = this.state[item.Name] = item.Values.Data[0].Value;
			let indexFieldConfiguration = form.Fields.findIndex((field) => field.Name === item?.Name);
			if (indexFieldConfiguration !== -1) {
				form.Fields[indexFieldConfiguration].Ignore = form.Fields[indexFieldConfiguration].Ignore || fields.findIndex((field) => field?.Name === item?.Name) === -1 || item?.Type === formFieldTypes.empty;
				item.Disabled = formMode === formModes.read ? true : item.Disabled;
				item.Check = submitted || formModes.edit === formMode;
			}

			if (type === alfrescoTypes.counterpartyDoc && item?.Name === 'prop_sx_caDocKind' && formMode !== formModes.create) {
				item.Disabled = true;
			}
		}
		return (
			<>
				{sectionSettings?.Label?.Label ?
					<div className="form-group col-sm-12 col-xs-12">
						<label className="header-label">{sectionSettings?.Label?.Label}</label>
					</div> :
					""
				}
				{fields.map((item) =>
					<div key={item?.Name} name={item.Name} className={'form-group col-sm-' + item.Columns + ' col-xs-' + item.Columns} >
						<InputContainer {...item}>{buildFormField(item, HelperFunctions.changeInputValue.bind(this))}</InputContainer>
					</div>
				)}
			</>
		);
	}
	return ([]);
}
export function renderFormHeader() {
	const { prop_sys_node_uuid, prop_cm_title, formMode, isCretor, prop_sx_dfState, isPerformer, type, settings, signatures, externalSigners, mimetype, associations, form, prop_sx_grainStorage, showGrainStorageInfo } = this.state;
	let btns = {
		download: { src: "icn-download.svg", title: i18n.t("download"), onClick: () => downloadFile(associations.mainContent[0], prop_cm_title, type, false, false) },
		view: { src: "download.svg", title: i18n.t("view"), onClick: () => ModalDialogsTemplates.viewPdfDialog(associations.mainContent[0]), show: "application/pdf" === mimetype || "application/octet-stream" === mimetype },
		downloadWithSign: { src: "icn-sign1.svg", title: i18n.t("25"), onClick: () => downloadFile(associations.mainContent[0], prop_cm_title, "", false, true) },
		downloadSign: { src: "icn-sign1.svg", title: i18n.t("26"), onClick: () => downloadSign(associations.mainContent[0], prop_cm_title) },
		checkSign: { src: "check.svg", title: i18n.t("checkSignatures"), onClick: checkDocumentSigns.bind(this) },
		downloadTemplate: { src: "icn-download.svg", title: i18n.t("downloadTemplate"), onClick: () => ModalDialog.showModalDialog('modalDialog', i18n.t(i18n.t("downloadDocumentTemplate")), "", <StatutoryDocsForm key={statutoryDocTypes.template} docType={statutoryDocTypes.template} canEdit={false} inPopup={false} />, "feedPopup middle-size") },
		newConnection: { src: "sep.svg", title: i18n.t("addConnection"), onClick: () => ModalDialog.showModalDialog('modalDialog', i18n.t("addNewConnection"), "", <ListOfAlfresco parentComponent={ListOfContracts} withAction={false} type={searchTableTypes.Documents} additionalFilter={[{ "DocType": [] }]} actionHandlerCallback={handleChangeRelatedDocuments.bind(this)} />, "feedPopup middle-size") },
		addComment: { src: "icn-comadd.svg", title: i18n.t("addComment"), onClick: () => ModalDialog.showModalDialog('modalDialog', i18n.t("addComment"), "", <CommentForm documentId={prop_sys_node_uuid} handleChangeComment={addComment.bind(this)} />, "feedPopup") },
		getComents: { classes: "has-additionnal-btn", src: "icn-refresh.svg", title: i18n.t("update"), onClick: getComents.bind(this) },
		youControlRequest: { src: "refresh.svg", title: i18n.t("fillWithYouControl"), onClick: this.youControlRequest },
		transliterate: { src: "transliteration.svg", title: i18n.t("fieldsTransliteration"), onClick: this.transliterate },
		addBank: { src: "users.svg", title: i18n.t("addBank"), onClick: () => ModalDialog.showModalDialog('modalDialog', i18n.t(i18n.t("19")), "", <DynamicForm path='Bank' />, "feedPopup  middle-form") },
		addSigner: { src: "users.svg", title: i18n.t("31"), onClick: () => ModalDialog.showModalDialog('modalDialog', i18n.t(i18n.t("31")), "", <DynamicForm path='cpSignatory' />, "feedPopup  middle-form") },
		addAttacment: {
			src: "icn-addatach.svg", title: i18n.t("addDocument"),
			onClick: () => ModalDialog.showModalDialog('modalDialog', i18n.t("addDocument"), "",
				<AttachmentForm typesOfDocumens={this.getCurAttacmentsTypes.bind(this)()} onCloseHandler={() => { ModalDialog.hideModalDialog('modalDialog'); }} />, "feedPopup middle-form")
		}
	};
	let tabName = HelperFunctions.getUrlParam("tab")?.toLowerCase();
	let activeBtns = documentTabs.comments === tabName ? [btns.addComment] : [];
	switch (formMode) {
		case formModes.create: case formModes.edit: break;
		case formModes.read:
			if (type === alfrescoTypes.counterparties && [statuses.preparing, statuses.revision, statuses.rejected].indexOf(prop_sx_dfState) !== -1) {
				if (documentTabs.signers === tabName) activeBtns.push(btns.addSigner);
				if (documentTabs.financialinfo === tabName) activeBtns.push(btns.addBank);
			}
			if (documentTabs.docs === tabName && (prop_sx_dfState === statuses.preparing || prop_sx_dfState === statuses.revision)) activeBtns.push(btns.addAttacment);
			if (type !== alfrescoTypes.counterparties) {
				activeBtns.push(btns.view, btns.download);
				if (externalSigners?.length > 0) {
					activeBtns.push(btns.downloadWithSign, btns.downloadSign);
				}
			}
			break;
	}
	if (documentTabs.comments === tabName && formMode !== formModes.create) activeBtns.push(btns.getComents);
	let validSignatures = signatures?.filter((signature) => signature.state === signatureStates.signed);
	let actions = settings.actions.filter((item) => (
		(((isPerformer) && (isCretor && (prop_sx_dfState === documentStatuses?.project || prop_sx_dfState === documentStatuses?.refinementCounterparty) || prop_sx_dfState !== documentStatuses?.project && prop_sx_dfState !== documentStatuses?.refinementCounterparty) &&
			((item?.statuses)?.includes(prop_sx_dfState) || (item?.statuses)?.includes(documentStatuses?.All)) &&
			((item?.modes)?.includes(formMode) || (item?.modes)?.includes(formModes.all) || item?.modes === null && formModes.read === formMode) || item.type == actionTypes.cancel) && !((item.type === actionTypes.additionalSign || item.type === actionTypes.sign) && prop_sx_dfState === documentStatuses?.project && validSignatures?.length === 2))));
	if ((prop_sx_dfState === documentStatuses?.project || prop_sx_dfState === documentStatuses?.refinementCounterparty) && JSON.parse(localStorage.getItem('user')).role === alfrescoRoles.MODERATOR && formMode === formModes.read) actions.splice(actions.length - 1, 0, settings.actions.find((item) => item.type === actionTypes.changeTaslExecuter));

	let showRequestDocsSwitcher = tabName === documentTabs.docs && (prop_sx_dfState === statuses.preparing || prop_sx_dfState === statuses.revision);

	return (
		<>
			<div className="buttons-block-header">
				<ul>
					{actions.map((item) => (
						<li key={v4()}>
							<button onClick={() => { if (item?.type) this.actionHandlerCallback(item.type, item.action) }} className={"btn " + item.btnclass}>
								{item.title}
							</button>
						</li>
					))}
					<DocumentInfo {...this.state} />
				</ul>
			</div>
			<div style={{ display: activeBtns.length === 0 ? "none" : "" }} className="filter-block-header">
				<ul className="filter-header-buttons">
					{activeBtns.map((item) =>
						<li key={v4()} className={item?.classes ? item.classes : ""} style={{ display: item?.show === true || item?.show === undefined ? '' : 'none' }}>
							<button type="button" className="btn no-bg-btn" onClick={item?.onClick}><Svg src={`/img/${item.src}`} /><span>{item.title}</span></button>
						</li>)}
					{showRequestDocsSwitcher &&
						<li key={v4()} className="filter-header-check">
							<input id="prop_sx_grainStorage" name="prop_sx_grainStorage" onChange={grainStorageChanged.bind(this)} checked={prop_sx_grainStorage?.toString().toLowerCase() === 'true'} class="form-control" type="checkbox" />
							<label for="prop_sx_grainStorage">{i18n.t("grainStorageLabel")}</label>
							<InfoBtnControl show={showGrainStorageInfo} infoText={i18n.t("grainStorageLabelInfo")} />
						</li>
					}
				</ul>
			</div>
			<div className="form-block doc-form pb-0"><div className="form-content"><div dangerouslySetInnerHTML={{ __html: (formMode in (form.Messages ?? {})) ? form.Messages[formMode] : "" }} ></div></div></div>
		</>
	);
}

function grainStorageChanged(e) {
	let { checked } = e.target;
	if (e.target) {
		this.setState({ prop_sx_grainStorage: checked }, () => executeSubmitRequest.bind(this)('', (response) => {
			if (response.ok) {
				fetch(`Document/GetAttachmentTypes?grainStorage=${checked}`).then(async (response) => {
					if (response.ok) {
						const responseText = await response.text();
						const attachmentTypes = JSON.parse(responseText.replaceAll('sxd:', ''));
						this.setState({ attachmentTypes: attachmentTypes, showGrainStorageInfo: checked }, () => this.fillNotAddedAttachments());
					}
					else window.location.reload();
				})
					.catch(() => window.location.reload());
			}
			else if (response.status === 422) {
				require('../ModalDialog').errorMessageDialog(i18n.t("4"));
				this.setState({ prop_sx_grainStorage: !checked, showGrainStorageInfo: false });
			}
			else {
				handleGetDocumentError(response.status);
				this.setState({ prop_sx_grainStorage: !checked, showGrainStorageInfo: false });
			}
		}));
	}
}

function showCheckFailedMessage(checkResult) {
	const currentObject = this;
	ModalDialog.showModalDialog("modalDialog", i18n.t("attentionTitle"), "",
		<div className="info-message">{checkResult.error}</div>,
			"feedPopup", true,
		[{ title: i18n.t("ok"), clickHandler: () => { ModalDialog.hideModalDialog('modalDialog'); onCheckFailedMessageClose.bind(currentObject)(checkResult.incorrectTab); }, class: "btn-primary" }],
			() => onCheckFailedMessageClose.bind(currentObject)(checkResult.incorrectTab)
		);
}

function onCheckFailedMessageClose(errorTab) {
	this.setState({ activeTab: errorTab });
}

export async function handleSubmit(action) {
	const { prop_sys_node_uuid, type, externalSigners } = this.state;

	let message;
	if (type === alfrescoTypes.counterparties && [documentBpActions.activitiprocessAccreditationCA, documentBpActions.completeCounterpartyCorrections, documentBpActions.activiti$completeContractsSigning].indexOf(action) !== -1) {
		let message = !this.checkAttachmentsMandatoryAdding() ? i18n.t("22") : (!this.checkAttachmentsMandatorySigning() ? i18n.t("22") : "");
		if (message)
			return ModalDialog.showModalDialog('modalDialog', i18n.t("attentionTitle"), "", <div className="info-message">{message}</div>, "feedPopup", true, [{ title: i18n.t("ok"), clickHandler: () => { this.setState({ activeTab: documentTabs.docs }); ModalDialog.hideModalDialog('modalDialog') }, class: "btn-primary" }], () => this.setState({ activeTab: documentTabs.docs }));
	}
	else if (!externalSigners && action && (action !== documentBpActions.activiti$returnContractToCorrections)) return require('../ModalDialog').errorMessageDialog(i18n.t("1"));
	switch (action) {
		case documentBpActions.activitiprocessAccreditationCA:
		case documentBpActions.completeCounterpartyCorrections: message = i18n.t("documentSuccessfullySentToWork"); break;
		case documentBpActions.activitiProcessDocCA:
		case documentBpActions.activiti$processInvoice:
		case documentBpActions.activiti$completeContractsSigning: message = i18n.t("documentSuccessfullySent"); break;
		case documentBpActions.activiti$returnContractToCorrections: message = i18n.t("documentSuccessfullySentToRevision"); break;
		default: message = i18n.t("documentSuccessfullySaved");
	}
	await executeSubmitRequest.bind(this)(action, (response) => {
		if (response.status === 422) require('../ModalDialog').errorMessageDialog(i18n.t("4"));
		else if (!response.ok) handleGetDocumentError(response.status);
		else ModalDialogsTemplates.messageDialog(i18n.t("done"), message, async () => document.location.replace(`/content/${prop_sys_node_uuid ?? (await response.json()).nodeRef.split('/').pop()}` + document.location.search));
	});
}
async function executeSubmitRequest(action, onResponse) {
	const { prop_sys_node_uuid, parent, type, file, comments, addedContractId, associations } = this.state;
	const currentCompany = HelperFunctions.getCurrentCompany();

	let contractNodeRef = null;
	if (parent) contractNodeRef = `workspace://SpacesStore/${parent}`;
	else if (addedContractId) contractNodeRef = `workspace://SpacesStore/${addedContractId}`;
	else if (associations?.universalContractDoc?.length > 0) contractNodeRef = associations.universalContractDoc[0];

	await fetch(`Document/${prop_sys_node_uuid ? "Edit" : "Add"}/${prop_sys_node_uuid ?? ''}${action ? `?actionDoc=${action}` : ""}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			properties: JSON.stringify(getFormFields.bind(this)()),
			comments, content: file ? {
				properties: { title: file.name },
				data: file ? (await HelperFunctions.getBase64(file, false))?.content : null
			} : {},
			contractNodeRef: contractNodeRef,
			counterpartyNodeRef: currentCompany ? `workspace://SpacesStore/${currentCompany.id}` : null,
			type
		})
	})
		.then((response) => {
			if (onResponse) onResponse(response);
		});
}
export async function addAdditionaSignatory(id, type) {
	this.state.assoc_sxcfc_externalSigners_added = id;
	this.actionHandlerCallback(actionTypes.save, type);
}

async function addMainContract(type, rows = []) {
	if (rows.length > 0) {
		this.setState({
			addedContractId: rows[0].columns.Id
		});
	}
}
function getContractContent(ids, canEdit = false) {
	const { formMode, addedContractId, associations, type } = this.state;
	const contractIds = addedContractId ? [addedContractId] : (associations?.universalContractDoc?.length > 0 ? [associations.universalContractDoc[0]] : ids);
	const editTitle = contractIds?.length > 0 ? i18n.t("changeContract") : i18n.t("addContract");

	return [
		contractIds?.length > 0 &&
		<ListOfAlfresco actionsForAll={clientSettings.table.MainContent.actions.filter(i =>
			i.modes.includes(formModes.all) || i.modes.includes(formMode))}
			actionHandlerCallback={mainContractActionHandler.bind(this)}
			columns={["DocumentNumber", "DateDocument", "Kind"]}
			showPagination={false} showFilter={false}
			parentComponent={ListOfContracts}
			type={searchTableTypes.Documents}
			withAction={false}
			additionalFilter={JSON.parse(JSON.stringify({
				"Id":
					contractIds.map(id => id.split('/').pop())
			}))} />,
		canEdit && <div className="form-group form-group__last">
			{formMode !== formModes.read && (type !== alfrescoTypes.counterpartyDoc || formMode === formModes.create) ?
				<button type="button" onClick={() => ModalDialog.showModalDialog('modalDialog', editTitle, "",
					<ListOfAlfresco
						saveApi={`Table/Save?searchTableTypes=${searchTableTypes.Documents}`}
						withAction={false}
						parentComponent={ListOfContracts}
						type={searchTableTypes.Documents}
						additionalFilter={{ "SearchType": [alfrescoTypes.universalContract], "Kind": ["C33"] }}
						actionHandlerCallback={addMainContract.bind(this)}
						singleSelect={true}
					/>, "feedPopup agreement middle-size")}
					className="btn btn-secondary">
					{editTitle}
				</button> :
				(!contractIds || contractIds?.length === 0) &&
				<div className="col-lg-12"><label>{i18n.t("contractNotSelected")}</label></div>
			}
		</div>
	];
}
export function renderAttachment() {
	let addMainFile = (e) => {		
		if (ModalDialogsTemplates.isAllowedFileTypeDialog(e.target.files[0], allowedAdditionalFileExtensions) && ModalDialogsTemplates.isValidFileSizeDialog(e.target.files[0].size)) {
			ModalDialogsTemplates.messageDialog(i18n.t("done"), i18n.t('30'));
			this.setState({ file: e.target.files[0] })
		}
	}
	const { formMode, associations, prop_cm_title, file, type, parent, prop_sx_caDocKind } = this.state;
	const mainContent = associations?.mainContent;
	let items = [];

	if (type === alfrescoTypes.counterpartyDoc) {
		if (prop_sx_caDocKind === 'Doc_C33') {
			items.push({
				title: i18n.t('29'),
				content: getContractContent.bind(this)(associations?.contract, true)
			});
		}
	}
	else {
		if (associations?.contracts?.length > 0) {
			items.push({
				title: i18n.t('27'),
				content: getContractContent.bind(this)(associations.contracts)
			});
		}
		if (associations?.contract?.length > 0) {
			items.push({
				title: i18n.t('29'),
				content: getContractContent.bind(this)(associations.contract)
			});
		}
		else if (parent) {
			items.push({
				title: i18n.t('29'),
				content: getContractContent.bind(this)([parent])
			});
		}

		if (associations?.invoice?.length > 0) {
			items.push({
				title: i18n.t('28'),
				content: getContractContent.bind(this)(associations.invoice)
			});
		}
	}

	let mainFileIsPdf = file ? (/.?\.pdf$/i).test(file.name): true;
	items.push({
		title: i18n.t('attachedFiles'),
		subTitle: i18n.t('mainDocumentFile'),
		content:
			<>
				<Table columns={clientSettings.table.MainContent.columns}
					actionHandlerCallback={attachedFilesActionHandler.bind(this)}
					actionsForAll={clientSettings.table.MainContent.actions.filter(i =>
						(i.modes.includes(formModes.all) || i.modes.includes(formMode))
						&& (i.type !== actionTypes.previewMainDocument || mainFileIsPdf))
					}
					items={file || mainContent?.length > 0 ?
						[{
							columns: {
								Id: mainContent?.length > 0 ? mainContent[0] : v4(), FileName: prop_cm_title ?? i18n.t(`form.${type.replace(':', '_')}.title`)
							}
						}] :
						[]}
				/>
				{formMode !== formModes.read &&
					[v4()].map(item => (<>
						<button onClick={() =>
							document.getElementById(item).click()
						} type="button" className="btn btn-primary">
							{file || mainContent?.length > 0 ? i18n.t('changeDocumentFile') : i18n.t('addDocumentFile')}
						</button>
						<input name="file" id={item} accept={acceptAdditionalInputFileString}
							onChange={addMainFile.bind(this)} type="file" style={{ display: "none" }}
						/>
					</>))
				}
			</>
	});
	return (items.map((v, k) => <div key={k}>
		{v?.content && v.title &&
			<div className="col-lg-12">
				<label className="required table-label">{v.title}</label>
			</div>
		}
		<div className="col-lg-12">
			{v.subTitle &&
				<label>{v.subTitle}</label>
			}
			{v?.content}
		</div>
	</div>));
}
export function mainContractActionHandler(actionType, rows) {
	ListOfDocuments.actionHandlerCallback(actionType, [rows[0].columns.Id]);
}
export async function attachedFilesActionHandler(actionType, ids) {
	const { associations, file } = this.state;
	switch (actionType) {
		case actionTypes.download:
			if (file) {
				let fileName = file.name;
				let fileNameParts = fileName.split('.');
				if (fileNameParts.length > 1) {
					fileNameParts.pop();
					fileName = fileNameParts.join('.');
				}
					
				const fileData = {
					fileName: fileName,
					mimeType: file.type,
					content: (await HelperFunctions.getBase64(file, false)).content
				}
				downloadContent(fileData);
			}
			else if (associations?.mainContent) {
				ListOfDocuments.actionHandlerCallback(actionType, ids);
			}
			break;
		case actionTypes.previewMainDocument:
			if (file) {
				let base64 = (await HelperFunctions.getBase64(file, false)).content;
				let objectUrl = window.URL.createObjectURL(HelperFunctions.base64toBlob(base64, "application/pdf"))
				ModalDialog.viewFile(objectUrl, file.name)
			}
			else if (associations?.mainContent) {
				ListOfDocuments.actionHandlerCallback(actionType, ids);
			}
			break;
		default:
			ListOfDocuments.actionHandlerCallback(actionType, ids);
			break;
	}
}