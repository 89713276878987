import React, { Component } from 'react';
import { EditLinkForm } from './EditLinkForm';
import i18next from "../../i18n";
import { actionTypes, urls } from '../../constants';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
import { Table } from '../table/Table';
import { TableButtonsBlock } from '../table/TableButtonsBlock';
import { clientSettings } from '../../settings/clientSettings';
import { capitalizeObjectKeys } from '../helpers/HelperFunctions';
import { globalEventDispatcher, dispatcherActionTypes } from '../helpers/GlobalEventDispatcher';

const settings = clientSettings.table.MenuLinks;
const addLinkBtn = { title: i18next.t("addLink"), type: actionTypes.add }
export class MenuLinksForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actionsForAll: JSON.parse(JSON.stringify(settings.actions)),
            columns: JSON.parse(JSON.stringify(settings.columns)),
            rows: []
        };
        this.actionHandlerCallback = this.actionHandlerCallback.bind(this);
        this.orderChangedHandler = this.orderChangedHandler.bind(this);
        this.deleteLinks = this.deleteLinks.bind(this);
        this.onSucces = this.onSucces.bind(this);
    }
    async componentDidMount() {
        let rows = [];
        const response = await fetch(`/SiteSettings/GetMenuLinks`);
        if (response.ok) {
            const result = await response.json();
            if (result && result.data) {
                result.data.forEach((link, index) => {
                    link = capitalizeObjectKeys(link);
                    link.Id = link.Id.toString();
                    let row = { actions: [] };
                    row.columns = link;
                    row.columns.OpenInNewTab = link.Target === '_blank';
                    const firstRow = index === 0;
                    const lastRow = index === result.data.length - 1;
                    row.columns["Reorder"] = {
                        firstRow: firstRow,
                        lastRow: lastRow,
                        reorderUpHandler: firstRow ? undefined : () => {
                            this.orderChangedHandler(link.Id, true);
                        },
                        reorderDownHandler: lastRow ? undefined : () => {
                            this.orderChangedHandler(link.Id, false);
                        }
                    };
                    rows.push(row);
                });
                this.setState({ rows: rows });
            }
        }
        else require('../ModalDialog').unknownErrorMessageDialog();
    }
    actionHandlerCallback(actionType, ids) {
        switch (actionType) {
            case actionTypes.add:
                showModalDialog('modalDialog', i18next.t("addLink"), "",
                    <EditLinkForm
                        onCloseHandler={() => { hideModalDialog('modalDialog'); }}
                        onSucces={() => { this.onSucces(); }}
                    />,
                    "feedPopup feedPopup__add  middle-form");
                break;
            case actionTypes.edit:
                showModalDialog('modalDialog', i18next.t("editLink"), "",
                    <EditLinkForm
                        linkId={ids[0]}
                        onCloseHandler={() => { hideModalDialog('modalDialog'); }}
                        onSucces={() => { this.onSucces(); }}
                    />,
                    "feedPopup feedPopup__add  middle-form");
                break;
            case actionTypes.remove:
                {
                    let closeDialog = () => {
                        hideModalDialog("loadingDialog");
                    }
                    let confirmDialog = () => {
                        hideModalDialog("loadingDialog");
                        this.deleteLinks(ids);
                    }
                    showModalDialog("loadingDialog", i18next.t("removing"), "", <div className="info-message">{i18next.t("removeElementsMessage")}</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: confirmDialog, class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                }
                break;
        }
    }
    async orderChangedHandler(id, up) {
        const response = await fetch(`/SiteSettings/MoveLink`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, up})
        });
        if (response.ok) {
            this.onSucces();
        }
        else {
            if (response.status === 403) History.push(urls.accessDeniedPage);
            else require('../ModalDialog').unknownErrorMessageDialog();
        }
    }
    async deleteLinks(ids) {
        const response = await fetch(`/SiteSettings/RemoveMenuLinks`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(ids.join('|'))
        });
        if (response.ok) {
            this.onSucces();
        }
        else {
            if (response.status === 403) History.push(urls.accessDeniedPage);
            else require('../ModalDialog').unknownErrorMessageDialog();
        }
    }
    onSucces() {
        hideModalDialog('modalDialog');
        globalEventDispatcher.dispatch(dispatcherActionTypes.onMenuChanged);
        globalEventDispatcher.dispatch(dispatcherActionTypes.onUnselectTableAllRows);
        this.componentDidMount();
    }

    render() {
        const { columns, rows, actionsForAll } = this.state;
        return (
            <>
                <TableButtonsBlock key="links-buttons" buttons={[addLinkBtn]} onClickHandler={this.actionHandlerCallback} title={i18next.t("linkListLabel")} />,
                <Table actionHandlerCallback={this.actionHandlerCallback} columns={columns} items={rows} actionsForAll={actionsForAll} />
            </>
        );
    }
}