import React from 'react';
import { alfrescoTypes } from '../../constants';
import i18next from "../../i18n";
import * as HelperFunctions from '../helpers/HelperFunctions';
import { Svg } from '../Svg';
export function DocumentTitle(props) {
    const { prop_cm_title, type } = props;
    return (
        <div className="title-main">
            <button type="button" className="btn btn-back" onClick={() => { window.location.replace(type === alfrescoTypes.counterparties ? '/' : (HelperFunctions.getUrlParam('parent') ? `/content/${HelperFunctions.getUrlParam('parent')}` : '/documents')) }}>
                <Svg src="/img/icn-backk.svg" />
            </button>
            <h1>{prop_cm_title ?? i18next.t(`form.${type.replace(":", "_")}.title`)}</h1>
        </div>
    );
}