import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import i18next from "../i18n";
import { checkEmail, checkPhone } from './helpers/CheckFunctions';
import { hideModalDialog, showModalDialog } from './ModalDialog';
export class FeedbackForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: {
                contactPerson: '',
                phone: '',
                email: '',
                message: '',
                files: []
            },
            submitted: false,
            sending: false,
            responseMessage: {
                isOk: true,
                message: ''
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    handleChange(event) {
        const { name, value } = event.target;
        const { feedback } = this.state;
        this.setState({
            feedback: {
                ...feedback,
                [name]: value
            }
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { feedback, sending } = this.state;
        if (!sending && feedback.contactPerson && feedback.phone && checkPhone(feedback.phone) && feedback.email && checkEmail(feedback.email) && feedback.message) {
            this.setState({ sending: true });
            this.sendData();
        }
    }
    handleFileRemove(event) {
        const index = event.target.getAttribute("index");
        if (index !== null) {
            const { feedback } = this.state;
            const { files } = feedback;
            const i = parseInt(index) - 1;
            if (i > -1 && i < files.length) {
                files.splice(i, 1);
            }
            this.setState({
                feedback: {
                    ...feedback,
                    files: files
                }
            });
        }
    }
    handleFileChange(event) {
        const { feedback } = this.state;
        const { files } = feedback;
        for (var i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        this.setState({
            feedback: {
                ...feedback,
                files: files
            }
        });
    }
    componentDidMount() {
        this.fillUserData();
    }
    render() {
        const { feedback, submitted, sending, responseMessage } = this.state;
        var keyNumber = 0;
        if (responseMessage && responseMessage.message) {
            return (<div className="row">
                <div className="col-sm-12 col-xs-12 feedback-message">
                    <div className={responseMessage.isOk ? "info-message response-ok" : "info-message response-error"}>{responseMessage.message}</div>
                    <div className="modal-footer"><button type="button" className="btn btn-primary" onClick={this.onCancel}>{i18next.t("ok")}</button></div>
                </div>
            </div>);
        }
        else {
            return (
            <>
                    <div className="modal-body">
                        <form name="form" className="popupSimple">
                            <div className="row">
                                <div className={'form-group col-sm-12 col-xs-12' + (submitted && !feedback.contactPerson ? ' has-error' : '')}>
                                    <label htmlFor="contactPerson" className="required">{i18next.t("contactPerson")}</label>
                                    <input type="text" className="form-control" name="contactPerson" value={feedback.contactPerson} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                                    {submitted && !feedback.contactPerson &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                </div>
                                <div className={'form-group col-sm-6 col-xs-12' + (submitted && !feedback.phone ? ' has-error' : '')}>
                                    <label htmlFor="phone" className="required">{i18next.t("phone")}</label>
                                    <InputMask className="form-control" name="phone" mask='+380999999999' maskChar={null} value={feedback.phone} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                                    {submitted && feedback.phone && !feedback.phone &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                    {submitted && !checkPhone(feedback.phone) &&
                                        <div className="help-block">{i18next.t("badFieldFormat")}</div>
                                    }
                                </div>
                                <div className={'form-group col-sm-6 col-xs-12' + (submitted && !feedback.email ? ' has-error' : '')}>
                                    <label htmlFor="email" className="required">{i18next.t("email")}</label>
                                    <input type="email" className="form-control" name="email" value={feedback.email} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                                    {submitted && !feedback.email &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                    {submitted && feedback.email && !checkEmail(feedback.email) &&
                                        <div className="help-block">{i18next.t("badFieldFormat")}</div>
                                    }
                                </div>
                                <div className={'form-group col-sm-12 col-xs-12' + (submitted && !feedback.message ? ' has-error' : '')}>
                                    <label htmlFor="message" className="required">{i18next.t("message")}</label>
                                    <textarea rows="4" className="form-control" name="message" value={feedback.message} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                                    {submitted && !feedback.message &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                </div>
                                <div className="form-group col-sm-12 col-xs-12">
                                    {feedback.files.map(file =>
                                        <label className={"upload-file-label" + (sending ? " disabled" : "")} key={"uploaded-file-" + keyNumber++}>{file.name} <span className="close-btn" onClick={this.handleFileRemove} index={keyNumber}></span></label>
                                    )}
                                    <label className={"upload-file-label upload-file-btn" + (sending ? " disabled" : "")} onClick={() => document.getElementById("file-iput-controll").click()}>{i18next.t("addFile")}</label>
                                    <input className="file-select" name="file" id="file-iput-controll" style={{ display: "none" }} onChange={this.handleFileChange} type="file" />
                                </div>
                                <div className='form-group col-sm-12 col-xs-12'>
                                    <label>{i18next.t("Просимо залишити Ваше повідомлення, служба підтримки зв'яжеться з Вами найближчим часом.")}</label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <div className="form-group form-group__last">
                            <button className="btn btn-primary" onClick={this.handleSubmit} disabled={(sending ? 'disabled' : '')}>{i18next.t("send")}</button>
                            <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                            <div className="required-label"><label className="required"></label>{i18next.t("requiredFields")}</div>
                        </div>
                    </div>
                </>
            );
        }
    }
    async fillUserData() {
        const response = await fetch('account/getCurrentUser');
        if (response.ok) {
            const data = await response.json();
            const { feedback } = this.state;
            if (!feedback.contactPerson && data.fullName)
                feedback.contactPerson = data.fullName;
            if (!feedback.email && data.email)
                feedback.email = data.email;
            if (!feedback.phone && data.phoneNumber)
                feedback.phone = data.phoneNumber.replace(/[^+\d]/g, '');
            this.setState({
                feedback: feedback
            });
        }
    }
    async sendData() {
        const { feedback } = this.state;
        const formData = new FormData();
        Object.keys(feedback).map(key => formData.append(key, feedback[key]));
        for (const file of feedback.files) {
            formData.append('files', file, file.name);
        }
        const response = await fetch('/home/sendFeedback', {
            method: 'POST',
            headers: { "Contetnt-Type": "multipart/form-data" },
            body: formData
        });
        if (response.ok) {
            showModalDialog("loadingDialog", i18next.t("feedback"), "", <div className="info-message">{i18next.t("messageSentSuccessfully")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
        }
        else {
            import("./ModalDialog").then(templates => { templates.unknownErrorMessageDialog() });
        }
    }
    handleResponse(response) {
        const currentObject = this;
        return new Promise((resolve, reject) => {
            if (response.ok) {
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    response.json().then(json => resolve(json));
                } else {
                    resolve();
                }
                currentObject.setState(
                    {
                        responseMessage: {
                            isOk: true,
                            message: i18next.t("messageSentSuccessfully")
                        }
                    }
                );
            } else {
                response.text().then(text => reject(text));
                currentObject.setState(
                    {
                        responseMessage: {
                            isOk: false,
                            message: response.text()
                        }
                    }
                );
            }
        });
    }
    handleError(error) {
        console.log(error.message);
        return Promise.reject(error && error.message);
    }
}
