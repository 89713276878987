import { getFilterString, parseFilterString } from '../helpers/HelperFunctions';
export async function loadListData(path, page, take, sortBy = '', isDesk = false, filterValues) {
    const { mainSearchFilter } = this.props;
    let skip = (page - 1) * take;
    let mainFilter = parseFilterString(mainSearchFilter);
    let filter = Array.isArray(filterValues) || typeof filterValues === 'object' ? filterValues : parseFilterString(filterValues);
    filter = Object.assign(mainFilter, filter);
    let filterForRequest = getFilterString(filter);
    const response = await getListDataResponse(path, skip, take, sortBy, isDesk, filterForRequest);
    if (!response.ok) return require('../ModalDialog').unknownErrorMessageDialog();
    const data = await response.json();
    const pageCount = Math.ceil(data.allCount / take);
    data.columns.forEach(item => {
        if (Object.keys(filterValues).includes(item.name) && item.showFilterInColumn && item.enums?.length > 0)
            item.filteredValues = filterValues[item.name];
    })
    this.setState({
        content: data, pageCount: pageCount, sortBy: data.sortBy, isDesk: data.isDesk, page: page > pageCount ? 1 : page,
        loadItemsForExport: async () => {
            let result = [];
            let response = await getListDataResponse(path, 0, 2147483646, data.sortBy, data.isDesk, filterForRequest);
            if (response.ok) {
                let data = await response.json();
                if (data && data.rows)
                    result = data.rows;
            }
            return result;
        }
    });
}
export async function getListDataResponse(path, skip, take, sortBy = '', isDesk = false, filter) {
    const response = await fetch(path, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            skip: skip,
            take: take,
            sortBy: sortBy,
            isDesk: isDesk,
            filter: filter
        })
    });
    return response;
}