import { Component } from 'react';
import { actionTypes, UserContext } from '../../constants';
import { ExportToCSV, getArrayIds, getCurrentUrlPath, deepObjectEqual } from '../helpers/HelperFunctions';
import { getOnPageCount, setOnPageCount } from '../helpers/HelperFunctions';
export class ListOfAlfresco extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        const { parentComponent } = this.props;
        this.state = {
            content: null,
            page: 1,
            pageSize: getOnPageCount(),
            pageCount: 1,
            sortBy: '',
            isDesk: false,
            filterValues: [],
            wasFiltered: false,
            selectedInboxNumber: 0,
            location: getCurrentUrlPath().toLowerCase(),
            menuItems: []
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleReloadData = this.handleReloadData.bind(this);
        this.onColumnsUpdateHandler = this.onColumnsUpdateHandler.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.onColumnFilter = this.onColumnFilter.bind(this);
        this.actionHandlerCallbackDefault = this.actionHandlerCallbackDefault.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.getItemsForExport = this.getItemsForExport.bind(this);
        const component = this;
        if (parentComponent) {
            Object.getOwnPropertyNames(parentComponent).forEach(function (property) {
                if (typeof parentComponent[property] === 'function') {
                    component[property] = parentComponent[property].bind(component);
                }
            });
        }
    }
    componentDidUpdate(prevProps) {
        const { page, pageSize, sortBy, isDesk, filterValues, selectedInboxNumber, location } = this.state;
        const { additionalFilter } = this.props;
        const nextLocation = getCurrentUrlPath().toLowerCase();
        if ((selectedInboxNumber !== 0 && this.props.selectedInboxNumber !== selectedInboxNumber && this.props.selectedInboxNumber)
            || nextLocation !== location
            || !deepObjectEqual(additionalFilter, prevProps.additionalFilter)
            || this.props.mainSearchFilter !== prevProps.mainSearchFilter
        ) {
            let newPage = page;
            if (nextLocation !== location)
                newPage = 1;
            this.setState({ page: newPage, selectedInboxNumber: 0, location: nextLocation, wasFiltered: true },
                () => { this.loadData(newPage, pageSize, sortBy, isDesk, filterValues); }
            );
        }
    }
    componentDidMount() {
        const { sortBy, isDesk } = this.state;
        this.handleSortChange(sortBy, isDesk);
    }
    handleSortChange(sortBy, isDesk) {
        const { page, pageSize, filterValues } = this.state;
        this.loadData(page, pageSize, sortBy, isDesk, filterValues);
    }
    onColumnsUpdateHandler(columnsSettings) {
        const { saveApi } = this.props;
        const { content, page, pageSize, sortBy, isDesk, filterValues } = this.state;
        if (columnsSettings.selected, content && content.allColumns) {
            let selected = columnsSettings.selected;
            content.columns = [];
            for (var i = 0; i < selected.length; i++) {
                let index = content.allColumns.findIndex(col => col.id === selected[i]);
                if (index > -1)
                    content.columns.push(content.allColumns[index]);
            }
            import("../table/Table").then((item) => item.saveColumnSettings(saveApi, { columns: getArrayIds(content.columns).join(';') }, () => this.loadData(page, pageSize, sortBy, isDesk, filterValues)));
        }
    }
    initFilterValues(filterValues, fill = false) {
        const { content } = this.state;
        for (var i = 0; i < content.columns.length; i++) {
            if (Object.keys(filterValues).includes(content.columns[i].name) && content.columns[i].showFilterInColumn && content.columns[i].enums && content.columns[i].enums.length > 0) {
                content.columns[i].filteredValues = fill === true ? filterValues[content.columns[i].name] : [];
            }
        }
    }
    onSearch(filterValues) {
        const { content, pageSize, sortBy, isDesk } = this.state;
        this.initFilterValues.bind(this)(filterValues, true);
        this.setState({ filterValues: filterValues, wasFiltered: true, page: 1, content: content });
        this.loadData(1, pageSize, sortBy, isDesk, filterValues);
    }
    onSearchClear() {
        const { filterValues, content } = this.state;
        this.initFilterValues.bind(this)(filterValues, false);
        const { wasFiltered, pageSize, sortBy, isDesk } = this.state;
        let { page } = this.state;
        if (wasFiltered) page = 1;
        this.setState({ wasFiltered: false, page: page, content: content, filterValues: {} });
        if (wasFiltered) this.loadData(page, pageSize, sortBy, isDesk);
    }
    onColumnFilter(colName, filterValues) {
        let currentFilterValues = this.state.filterValues;
        if (!currentFilterValues)
            currentFilterValues = {};
        const { content, pageSize, sortBy, isDesk} = this.state;
        let index = content.columns.findIndex(col => col.name === colName);
        if (index >= 0) {
            let updatedFilterValues = [];
            if (this.getAllowedEnumValues) {
                let allowedEnumValues = this.getAllowedEnumValues();
                if (allowedEnumValues && allowedEnumValues[colName] && allowedEnumValues[colName].length > 0
                    && !(filterValues.length === 1 && filterValues[0] === 'hideAllValue'))
                    for (var i = 0; i < filterValues.length; i++) {
                        if (allowedEnumValues[colName].includes(filterValues[i]))
                            updatedFilterValues.push(filterValues[i]);
                    }
                else
                    updatedFilterValues = filterValues;
            }
            else updatedFilterValues = filterValues;
            content.columns[index].filteredValues = updatedFilterValues;
            currentFilterValues[colName] = updatedFilterValues;
        }
        this.setState({ content: content, filterValues: currentFilterValues, wasFiltered: true });
        this.loadData(1, pageSize, sortBy, isDesk, currentFilterValues);
    }
    render() {
        return this.renderContent ? this.renderContent() : "";
    }
    async actionHandlerCallbackDefault(actionType, ids) {
        const { content, exportFileName } = this.state;
        switch (actionType) {
            case actionTypes.export:
                if (content && content.rows && content.columns) {
                    var filteredRows = content.rows.filter(row => row.columns && ids.includes(row.columns.Id));
                    ExportToCSV(content.columns, filteredRows, exportFileName);
                }
                break;
        }
    }
    handlePageChange(page) {
        const { pageSize, sortBy, isDesk, filterValues } = this.state;
        this.setState({ page: page });
        this.loadData(page, pageSize, sortBy, isDesk, filterValues);
    }
    handleReloadData() {
        const { page, pageSize, sortBy, isDesk, filterValues } = this.state;
        this.loadData(page, pageSize, sortBy, isDesk, filterValues).then(() => {
            const { pageCount } = this.state;
            if (page > 1 && page > pageCount) {
                this.setState({ page: pageCount > 0 ? pageCount : 1 }, () => this.handleReloadData());
            }
        });
    }
    handleChangePageSize(pageSize) {
        const { sortBy, isDesk, filterValues } = this.state;
        setOnPageCount(pageSize);
        this.setState({ pageSize: pageSize, page: 1 });
        this.loadData(1, pageSize, sortBy, isDesk, filterValues);
    }
    async getItemsForExport() {
        let result = [];
        const { loadItemsForExport, content } = this.state;
        if (loadItemsForExport) {
            result = await loadItemsForExport();
        }
        else if (content && content.rows) {
            result = content.rows;
        }
        return result;
    }
}