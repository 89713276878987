import React from 'react';
import i18next from "./i18n";
const mime = require('mime-types');
export const History = require('history').createBrowserHistory();
export const UserContext = React.createContext();
export const urls = {
    accessDeniedPage: '/no-acces',
    loginPage: '/login',
    registerPage: '/register',
    recoveryPasswordPage: '/password-recovery',
    restorePasswordPage: '/restorepassword'
}
export const actionTypes = {
    preview: 0,
    sign: 1,
    edit: 2,
    remove: 3,
    download: 4,
    block: 5,
    unblock: 6,
    export: 7,
    add: 8,
    addDir: 9,
    toAcreditation: 10,
    additionalSign: 11,
    save: 12,
    cancel: 13,
    loadSigns: 14,
    notAdd: 15,
    reject: 16,
    annul: 17,
    previewMainDocument: 18,
    reset: 19,
    join: 20,
    changeTaslExecuter: 21,
    invoice: 22,
    score: 23
}
export const constants = {
    allowedInfoFileExtensions: ['pdf'],
    acceptInfoIputFileString: "application/pdf",
    placeholderDialogId: "placeholder-modal-dialog"
}
export const allowedStatutoryFileExtensions = ["pdf", "png", "jpg", "jpeg", "tif", "tiff", "txt", "zip", "rar", "doc", "docx", "xlsx", "xls"];
export const allowedPricesFileExtensions = ['xlsx', 'xls'];
export const acceptPricesInputFileString = allowedPricesFileExtensions.map((item) => mime.lookup(item)).join(",");
export const allowedAdditionalFileExtensions = ["pdf", "txt", "doc", "docx", "odt", "xlsx", "xls"];
export const acceptAdditionalInputFileString = allowedAdditionalFileExtensions.map((item) => mime.lookup(item)).join(",");
export const allowedIconFileExtensions = ["png", "svg", "jpg", "jpeg", "bmp", "ico", "gif"];
export const acceptIconFileString = allowedIconFileExtensions.map((item) => mime.lookup(item)).join(",");;

export const statusFieldColors = {
    "Default": "rgba(84, 188, 235, 0.2)",
    "Selected": "rgba(244, 123, 32, 0.2)",
    "Не підписано": "#FDE5D3",
    "Активний": "#CDF2DA",
    "Заблокований": "#F2D6D1",
    "Підтвердження пошти": "#FFF5CE"
}
export const companyTypes = {
    Entity: 'caLegal',
    Individual: 'caFOP'
}
export const companyTypeTitles = {
    Entity: 'Юридична особа',
    Individual: 'ФОП'
}
export const companyTypeValues = [
    {
        title: companyTypeTitles.Entity,
        value: companyTypes.Entity
    },
    {
        title: companyTypeTitles.Individual,
        value: companyTypes.Individual
    }
]
export const formFieldTypes = {
    select: "select",
    int: "int",
    bool: "bool",
    string: "string",
    datetime: "datetime",
    phone: "phone",
    file: "file",
    empty: "empty"
}
export const formStringFieldPatterns = {
    phone: "phone",
    email: "email",
    codeIPN: "codeIPN",
    codeEDRPO: "codeEDRPO",
    mFO: "mFO",
    legalPersonIPN: "legalPersonIPN",
    number: "number",
    integer: "integer",
    iBan: "iBan"
}
export const counterpartyKind = {
    OtherPartners: "Other Partners",
    OwnCompany: "Own Company",
    Mediator1: "Mediator 1",
    Mediator2: "Mediator 2",
    Maker: "Maker"
}
export const settingsFormTypes = {
    settingsType: 'settings',
    adminType: 'admin'
}
export const statutoryDocTypes = {
    statutory: 0,
    template: 1,
    announcement: 2
}
export const filterOperatioTypes = {
    And: 0,
    Or: 1
}
export const searchTableTypes = {
    Users: 0,
    Companies: 1,
    Documents: 2,
    Requests: 3,
    PendingDocuments: 4,
    Outgoings: 5
}
export const formModes = {
    all: "all",
    create: "create",
    read: "read",
    edit: "edit",
}
export const attachmentStatuses = {
    noDoc: "noDoc",
    NotAdded: "not_add",
    New: "new",
    Signed: "signed"
}
export const filterDividers = {
    FilterPartsDivider: 'ˍ',
    FilterValuesDivider: '`',
    FilterNameValueDivider: '^'
}
export const alfrescoTypes = {
    counterparties: "sx:counterparties",
    contract: "sx:Contract",
    universalContract: "sx:universalContract",
    outgoingDocument: "sxcfc:outgoingDocument",
    incomingDocument: "sxcfc:incomingDocument",
    additionalDocument: "sxcfc:additionalDocument",
    additionalFiles: "sx:additionalFiles",
    specification: "sx:Specification",
    invoice: "sx:Invoice",
    score: "sx:score",
    counterpartyDoc: "sx:counterpartyDoc"
}
export const signatureStates = {
    signed: "1"
}
export const paginationSettings = {
    select: [{ value: 5 }, { value: 10 }, { value: 50 }, { value: 100 }],
    buttonsCount: 9
}
export const documentsRegisterRoutes = [
    {
        lable: i18next.t("pendingDocuments"),
        path: "/",
        saveApi: ``,
        type: searchTableTypes.PendingDocuments,
        withTabs: false
    },
    {
        lable: i18next.t("documents"),
        path: "/documents/:status?",
        saveApi: `Table/save?searchTableTypes=${searchTableTypes.Documents}`,
        type: searchTableTypes.Documents,
        withTabs: true
    },
    {
        lable: i18next.t("documents"),
        path: "/documents/:status/:status",
        saveApi: `Table/save?searchTableTypes=${searchTableTypes.Documents}`,
        type: searchTableTypes.Documents,
        withTabs: true
    },
    {
        lable: i18next.t("outgoingDocuments"),
        path: "/outgoingDocuments/:status?",
        saveApi: `Table/save?searchTableTypes=${searchTableTypes.Documents}`,
        type: searchTableTypes.Outgoings,
        withTabs: true
    },
    {
        lable: i18next.t("outgoingDocuments"),
        path: "/outgoingDocuments/:status/:status",
        saveApi: `Table/save?searchTableTypes=${searchTableTypes.Documents}`,
        type: searchTableTypes.Outgoings,
        withTabs: true
    }
]
export const docsForRepresentationTypes = {
    extraction: "Extraction",
    powerOfAttorney: "Power of Attorney",
    statute: "Statute",
    order: "Order",
    protocol: "Protocol"
}
export const docsForRepresentationDictionary = {
    [docsForRepresentationTypes.extraction]: "Витяг з ЄДР",
    [docsForRepresentationTypes.powerOfAttorney]: "Довіреність",
    [docsForRepresentationTypes.statute]: "Статут", 
    [docsForRepresentationTypes.protocol]: "Протокол",
    [docsForRepresentationTypes.order]: "Наказ", 
}
export const alfrescoRoles = {
    PORTAL_ADMINISTRATOR: "PORTAL_ADMINISTRATOR",
    MODERATOR: "MODERATOR"
}
export const documentStatuses = {
    preparing: "preparing",
    All: "All"
}
export const documentBpActions = {
    activitiprocessAccreditationCA: "activiti$processAccreditationCA",
    activitiProcessDocCA: "activiti$processDocCA1",
    completeCounterpartyCorrections: "activiti$completeCounterpartyCorrections",
    activiti$processInvoice: "activiti$processInvoice",
    activiti$returnContractToCorrections: "activiti$returnContractToCorrections",
    activiti$completeContractsSigning: "activiti$completeContractsSigning",
}
export const initiatorSystem = {
    COFCO: "COFCO",
    PORTAL: "PORTAL"
}
export const resources = {
    pricesTemplateFileName: 'PricesTemplate.xlsx',
    loadingIcone: '/img/loading.svg',
    logoutIcone: '/img/logout.svg',
    userInstructionFileName: 'instruction'
}
export const loginFaildeStatus = {
    userBlocked: "userIsNotActive",
    userNotExist: "userDoesNotExist"
}
export const maxFileSize = 47190000;
export const leftMenuTypes = {
    divider: 'divider',
    link: 'link'
}