import React, { Component } from 'react';
import { actionTypes } from '../../constants';
import { sortArray } from '../helpers/HelperFunctions';
import { TableColumn } from './TableColumn';
export class TableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
        this.handlerCheck = this.handlerCheck.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.onDocLinkClick = this.onDocLinkClick.bind(this);
    }
    componentDidMount() {
        const { item } = this.props;
        const currentObject = this;
        var row = document.getElementById("table_row_" + item.columns.Id);
        row.addEventListener("contextmenu", function (event) {
            event.preventDefault();
            var ckeckBox = document.getElementById(item.columns.Id);
            ckeckBox.checked = !ckeckBox.checked;
            currentObject.handlerCheck({ target: ckeckBox });
        }, false);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.checked !== this.state.checked) 
            this.setState({ checked: nextProps.checked });
    }
    handlerCheck(event) {
        this.setState({ checked: event.target.checked });
        if (this.props.handlerCheck)
            this.props.handlerCheck(event);
    }
    renderCols(item, cols) {
        if (!item || !cols)
            return '';
        const { checked } = this.state;
        if (item.actions && item.actions.length > 0)
            sortArray(item.actions, "sortIndex");
        return (
            [
                <TableColumn key={item.columns.Id}>
                    <input id={item.columns.Id} type="checkbox" onChange={this.handlerCheck} checked={checked} />
                    <label htmlFor={item.columns.Id} className="select-row-check-label"></label>
                </TableColumn>,
                cols.map((col) => (
                    <TableColumn col={col} key={col.name} value={item.columns[col.name]} onDocLinkClick={() => item.columns && this.onDocLinkClick(item.columns.Id, item.actions)} />
                ))
            ]
        );
    }
    onRowClick(id) {
        if (id) {
            var ckeckBox = document.getElementById(id);
            ckeckBox.checked = !ckeckBox.checked;
            var event = { target: ckeckBox };
            this.handlerCheck(event);
        }
    }
    onDocLinkClick(id, actions) {
        if (id && actions && actions.length > 0) {
            let actionIndex = actions.findIndex(act => act.type === actionTypes.preview || act.type === actionTypes.edit || act.type === actionTypes.download);
            if (this.props.handlerAction && actionIndex >= 0)
                this.props.handlerAction(actions[actionIndex].type, [id]);
        }
    }
    onRowDoubleClick(id, actions) {
        if (!this.props.allowDoubleClickAction) {
            var ckeckBox = document.getElementById(id);
            ckeckBox.checked = true;
            var event = { target: ckeckBox };
            this.handlerCheck(event);
        }
        else this.onDocLinkClick(id, actions);
    }
    render() {
        const { children, cols, item } = this.props;
        if (cols && item)
            return (
                <tr id={"table_row_" + item.columns.Id} className={this.state.checked ? "active" : ""} onDoubleClick={() => item.columns && this.onRowDoubleClick(item.columns.Id, item.actions)} onClick={() => item.columns && this.onRowClick(item.columns.Id)}>
                    {this.renderCols(item, cols)}                    
                </tr>
            );
        return (
            <tr className={this.state.checked ? "active" : ""}>
                {children}
            </tr>
        );
    }
}
