import React, { Component } from 'react';
import i18next from "../../i18n";
import { Svg } from '../Svg';
import * as HelperFunctions from '../helpers/HelperFunctions';
import * as constants from '../../constants';
import { ListOfAlfresco } from '../listsOfAlfresco/ListOfAlfresco';
export class ListOfSearchResults extends Component {
    static contextType = constants.UserContext;
    static getSearchStr() {
        let search = HelperFunctions.getUrlParam("search");
        if (!search) return "";
        return decodeURI(search);
    }
    static renderSearchResult(options) {
        let table;
        switch (options.tableType) {
            case constants.searchTableTypes.Companies: table = require(`./ListOfCompanies`); break;
            case constants.searchTableTypes.Users: table = require(`./ListOfUsers`); break;
            default: table = require(`./ListOfDocuments`); break;
        }
        return (<div className="search-result-item">
            <div className="search-result-item-header">
                <b>{options.tableName}</b>
            </div>
            <div className="search-result-item-content">
                <ListOfAlfresco type={options.tableType} parentComponent={table} mainSearchFilter={options.filterStr.replaceAll("{searchStr}", ListOfSearchResults.getSearchStr())} />
            </div>
        </div>);
    }
    render() {
        const searchOptions = this.context.searchOptions;
        return (
            <div className="content">
                <h1>{searchOptions.title}</h1>
                <div className="docs-page-block search-result-block">
                    <div className="filter-block-info" style={{ display: "flex" }}>
                        <div className="filter-info">
                            <Svg src="/img/search.svg" /><b>{i18next.t("searchQuery")}: </b>{ListOfSearchResults.getSearchStr()}
                        </div>
                    </div>
                    <div className="search-result-content">
                        {searchOptions.searchResults.map(item => ListOfSearchResults.renderSearchResult(item))}
                    </div>
                </div>
            </div>
        );
    }
}
