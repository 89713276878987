import {TableColumnType} from '../components/table/Table'
import i18next from "../i18n";
export default {
    "columns": [
        {
            "name": "Id",
            "headerName": "",
            "type": TableColumnType.String,
            "hidden": true
        },
        {
            "name": "companyName",
            "headerName": i18next.t("24"),
            "type": TableColumnType.String
        },
        {
            "name": "creatorFullName",
            "headerName": i18next.t("fullName"),
            "type": TableColumnType.String
        },
        {
            "name": "creatorPosition",
            "headerName": i18next.t("positionHeld"),
            "type": TableColumnType.String
        },
        {
            "name": "signingDate",
            "headerName": i18next.t("dateSigning"),
            "type": TableColumnType.String
        }
    ]
}