import React from 'react';
export function TableButtonsBlock(props) {
    const { buttons, onClickHandler, title } = props;
    return (
        <div className="buttons-block-header">
            {title && <h2>{title}</h2>}
            <div id="columnSettingsDialog"></div>
            {buttons && buttons.length > 0 &&
                <ul>
                    {buttons.map((button) => (
                        <li>
                            <button type="button" className={"btn " + (button.btnClass ? button.btnClass : "btn-primary")} onClick={() => { if (onClickHandler) onClickHandler(button.type) }}>{button.title}</button>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}