import React from 'react';
import { formModes, attachmentStatuses, companyTypes } from '../../constants';
import { AttachmentsForm } from './attachments/AttachmentsForm';
import { statuses, documentTabs } from './Document';
import i18next from "../../i18n";
export function getCurAttacmentsTypes() {
	const { attachmentTypes, prop_sx_caType, prop_sx_caKind } = this.state;
	return attachmentTypes?.filter((item) => item.configuration?.some((conf) => conf.counterpartyType === prop_sx_caType && conf.counterpartyKind === prop_sx_caKind && (conf.portalEnabled)));
}
export function fillNotAddedAttachments() {
	const curAttacmentsTypes = this.getCurAttacmentsTypes.bind(this)();
	const { attachments } = this.state;
	for (let i = 0; i < curAttacmentsTypes?.length; ++i)
		if (attachments.findIndex(a => a.documentType === curAttacmentsTypes[i].value) < 0)
			attachments.push({
				Id: i,
				state: attachmentStatuses.NotAdded,
				documentType: curAttacmentsTypes[i].value,
				prop_sx_addDocumentType_label: curAttacmentsTypes[i].title
			});
	this.setState({ attachments: attachments });
}
export function getAttachmentConfiguration(attachment,properties) {
	let {prop_sx_caType,prop_sx_caKind,attachmentTypes}=properties;
	let {documentType}=attachment;
	let configuration=attachmentTypes.find((item) => item.value===documentType)?.configuration?.find((item) =>
		(item.counterpartyType===prop_sx_caType&&item.counterpartyKind===prop_sx_caKind)
	);
	return configuration;
}
export function renderdocs() {
	const { attachments, formMode, prop_sx_dfState } = this.state;
	const canEditAttachments = formModes.read === formMode && (prop_sx_dfState === statuses.preparing || prop_sx_dfState === statuses.revision);
	let getAttachmentIndicator = (attachment) => {
		let { state, nodeRef } = attachment;
		let configuration = this.getAttachmentConfiguration(attachment, this.state);
		if (configuration) {
			if (!nodeRef && state === attachmentStatuses.NotAdded && configuration?.portalMandatoryAdding)
				return <img src="/img/icn-alert.svg" title={i18next.t("mandatoryDocument")} alt="" />;
			else if ((state === attachmentStatuses.New || state === attachmentStatuses.NotAdded) && configuration?.portalMandatorySigning)
				return <img src="/img/icn-need-sign.svg" title={i18next.t("signatureRequired")} alt="" />;
		}
	}
	const typesOfDocumens = this.getCurAttacmentsTypes.bind(this)()
	const renderedAttachments = attachments.filter(attach => typesOfDocumens.some(type => type.value === attach.documentType));
	return (<div className="large-form doc-form">
		<AttachmentsForm typesOfDocumens={typesOfDocumens} getAttachmentIndicatorHandler={getAttachmentIndicator.bind(this)} attachments={renderedAttachments} edite={canEditAttachments} />
	</div>);
}
export function buildFormItem(item) {
	const { prop_sx_caType, prop_sx_caVAT } = this.state;
	if (["prop_sx_caDocRegNum", "prop_sx_caDocRegDate"].indexOf(item.Name) !== -1 && companyTypes.Individual !== prop_sx_caType) this.state[item.Name] = null;
	else if (prop_sx_caVAT !== true && ["prop_sx_caVATCertificate"].indexOf(item.Name) !== -1);
	else return item;
}
export function checkAttachmentsMandatoryAdding() {
	const { attachments } = this.state;
	const typesOfDocumens = this.getCurAttacmentsTypes.bind(this)()
	const renderedAttachments = attachments.filter(attach => typesOfDocumens.some(type => type.value === attach.documentType));
	for (let i = 0; i < renderedAttachments.length; ++i) {
		let configuration = this.getAttachmentConfiguration(renderedAttachments[i], this.state);
		let state = renderedAttachments[i].state;
		let nodeRef = renderedAttachments[i].nodeRef;
		if (state === attachmentStatuses.NotAdded && !nodeRef && configuration?.portalMandatoryAdding === true)
			return false;
	}
	return true;
}
export function checkAttachmentsMandatorySigning() {
	const { attachments } = this.state;
	const typesOfDocumens = this.getCurAttacmentsTypes.bind(this)()
	const renderedAttachments = attachments.filter(attach => typesOfDocumens.some(type => type.value === attach.documentType));
	for (let i = 0; i < renderedAttachments.length; ++i) {
		let configuration = this.getAttachmentConfiguration(renderedAttachments[i], this.state);
		let state = renderedAttachments[i].state;
		let nodeRef = renderedAttachments[i].nodeRef;
		if (((state === attachmentStatuses.NotAdded && nodeRef) || state === attachmentStatuses.New) && configuration?.portalMandatorySigning) return false;
	}
	return true;
}

export function checkInputPage() {
	const checkResult = { error: '', incorrectTab: documentTabs.information };
	this.checkInputTab = this.checkInputTab.bind(this);

	let incorectTabs = Object.keys(documentTabs).map((key) => {
		if (!this.checkInputTab(documentTabs[key]))
			return documentTabs[key];
		else
			return null;
	}).filter(item => item);

	if (incorectTabs.length > 0) {
		let currentTabPos = incorectTabs.indexOf(this.state.activeTab);
		if (currentTabPos > -1) {
			checkResult.incorrectTab = incorectTabs[currentTabPos];
		}
		else {
			checkResult.incorrectTab = incorectTabs[0];
		}
		if (checkResult.incorrectTab === documentTabs.signers) {
			checkResult.error = i18next.t("signersInfoRequiredMessage");
		}
		else if (checkResult.incorrectTab === documentTabs.financialinfo) {
			checkResult.error = i18next.t("banksInfoRequiredMessage");
		}
		else {
			checkResult.error = i18next.t("notAllRequiredFildsAreSpecifiedMessage");
		}
	}
	return checkResult;
}

export function checkInputTab(tab) {
	const { prop_sx_caType,
		prop_sx_caKind,
		prop_sx_caEDRPOU,
		prop_sx_caNameUk,
		prop_sx_caShortNameUk,
		prop_sx_caLegalForm,
		prop_sx_caTaxType,
		prop_sx_caVAT,
		prop_sx_caVATCertificate,
		prop_sx_caDocRegNum,
		prop_sx_caDocRegDate,
		prop_sx_caLegalZIP,
		prop_sx_caLegalRegion,
		prop_sx_caLegalDistrict,
		prop_sx_caLegalCity,
		prop_sx_caLegalStreet,
		prop_sx_caLegalBuilding,
		prop_sx_caLegalOffice,
		prop_sx_caPostZIP,
		prop_sx_caPostRegion,
		prop_sx_caPostDistrict,
		prop_sx_caPostCity,
		prop_sx_caPostStreet,
		prop_sx_caPostBuilding,
		prop_sx_caPostOffice,
		prop_sx_caMainPhone,
		prop_sx_caEmail,
		cpAuthorityPersons,
		banks
	} = this.state;

	switch (tab) {
		case documentTabs.information:
			if (!(prop_sx_caType && prop_sx_caKind && prop_sx_caEDRPOU && prop_sx_caNameUk && prop_sx_caShortNameUk &&
				prop_sx_caLegalForm && prop_sx_caTaxType && (!prop_sx_caVAT || prop_sx_caVATCertificate)
				&& (prop_sx_caType !== companyTypes.Individual || (prop_sx_caDocRegNum && prop_sx_caDocRegDate)))) {
				return false;
			}
			break;
		case documentTabs.contactinfo:
			if (!(prop_sx_caLegalZIP && prop_sx_caLegalRegion && prop_sx_caLegalDistrict && prop_sx_caLegalCity && prop_sx_caLegalStreet
				&& prop_sx_caLegalBuilding && prop_sx_caLegalOffice && prop_sx_caPostZIP && prop_sx_caPostRegion && prop_sx_caPostDistrict
				&& prop_sx_caPostCity && prop_sx_caPostStreet && prop_sx_caPostBuilding && prop_sx_caPostOffice && prop_sx_caMainPhone
				&& prop_sx_caEmail)) {
				return false;
			}
			break;
		case documentTabs.signers:
			return cpAuthorityPersons?.length > 0;
			break;
		case documentTabs.financialinfo:
			return banks?.length > 0;
			break;
		default:
			break;
	}

	return true;
}