import { History, urls } from '../constants';
import React, { Component } from 'react';

export class AccessContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasAccess: false
        }

        this.checkAccessToPage().then(ok => {
            if (ok)
                this.setState({ hasAccess: true });
            else
                History.push(urls.accessDeniedPage);
        })
    }
    async checkAccessToPage() {
        const response = await fetch(`/Account/HasAccessToPage?url=${window.location.pathname}`, { method: "POST" });
        return response.ok;
    }
    render() {
        const { hasAccess } = this.state;
        return hasAccess ? this.props.children : <></>;
    }
}