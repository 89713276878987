import React, { Component } from 'react';
import i18next from "../../i18n";
import { unknownErrorMessageDialog } from '../ModalDialog';
export class PricesTable extends Component {
    constructor(props) {
        super(props);
        this.state = { data: null };
    }
    componentDidMount() {
        this.populateTableData();
    }
    static renderTableItems(items, index) {
        let currentIndex = 0;
        return items.map(item =>
            <tr key={"prices-info-" + index + "_" + currentIndex++}>
                {Object.keys(item).map((key, index) => <td>{item[key]}</td>)}
            </tr>
        );
    }
    static renderPricesTable(data) {
        let titles = i18next.t("form.prices.titles", { returnObjects: true });
        return (
            <div className="table-response">
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            {Object.keys(titles).map((key, index) => <th>{titles[key]}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.pricesInfos.map((item, index) =>
                            [(<tr key={"prices-info-" + index++}>
                                <td colSpan="3"><strong>{item.product}</strong></td>
                            </tr>)
                                , PricesTable.renderTableItems(item.prices, index)]
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    render() {
        const { data } = this.state;
        let date = data?.date ? new Date(data.date) : null;
        return (
            data ? <div className="content">
                <h1>{i18next.t("form.prices.tableTitle")}</h1>
                <div className="table-block">
                    <p><strong>{date ? i18next.t("form.prices.asOf", { date: (date ? date.toLocaleDateString("uk-UA") : "-") }) : ""}</strong></p>
                    <p>{data.condition}</p>
                    {PricesTable.renderPricesTable(data)}
                </div>
            </div> : ""
        );
    }
    async populateTableData() {
        const response = await fetch('/home/getPricesInfo');
        if (!response.ok) {
            unknownErrorMessageDialog();
        }
        else {
            const data = await response.json();
            this.setState({ data: data });
        }
    }
}