import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import { paginationSettings } from '../constants';
export class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
            pageSize: this.props.pageSize,
            keyIndex: 1
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page) {
            this.setState({
                page: nextProps.page,
                pageSize: nextProps.pageSize,
                keyIndex: this.state.keyIndex + 1
            });
        }
    }
    render() {
        let items = [];
        let buttonsCount = paginationSettings.buttonsCount;
        let allCount = this.props.pageCount;
        let current = this.state.page;
        let leftRangeAdded = false;
        let leftRightCount = Math.floor((buttonsCount - 4) / 2);
        items.push(<li key={1} className={"page-item " + (1 === current ? "active" : "")}><a onClick={() => this.handlePageSelected(1)}>{1}</a></li>);
        if (current > (buttonsCount + 1) / 2 && allCount > buttonsCount) {
            if (current + leftRightCount + 2 >= allCount) {
                let addedCount = buttonsCount - (2 + 2 * leftRightCount + 1 + (allCount - (current + leftRightCount)));
                if (addedCount > 0) {
                    items.push(<li key={current - leftRightCount - addedCount - 1} className="page-item range-page-item"><a onClick={() => this.handlePageSelected(current - leftRightCount - addedCount - 1)}>...</a></li>);
                    leftRangeAdded = true;
                    for (var i = addedCount; i > 0; i--) {
                        let index = current - leftRightCount - i;
                        items.push(
                            <li key={index} className={"page-item " + (index === current ? "active" : "")}><a onClick={() =>
                                this.handlePageSelected(index)}>{index}</a></li>);
                    }
                }
            }
            if (!leftRangeAdded) {
                leftRangeAdded = true;
                items.push(<li key={current - leftRightCount - 1} className="page-item range-page-item"><a onClick={() => this.handlePageSelected(current - leftRightCount - 1)}>...</a></li>);
            }
        }
        if (leftRangeAdded) {
            for (let i = current - leftRightCount; i <= Math.min(current + leftRightCount, allCount); i++) {
                items.push(
                    <li key={i} className={"page-item " + (i === current ? "active" : "")}><a onClick={() => this
                        .handlePageSelected(i)}>{i}</a></li>);
            }
            if (current + leftRightCount + 2 >= allCount) {
                for (let i = current + leftRightCount + 1; i <= allCount; i++) {
                    items.push(
                        <li key={i} className={"page-item " + (i === current ? "active" : "")}><a onClick={() => this
                            .handlePageSelected(i)}>{i}</a></li>);
                }
            } else {
                let number = Math.min(current + leftRightCount, allCount) + 1;
                items.push(<li key={number} className="page-item range-page-item"><a onClick={() => this.handlePageSelected(number)}>...</a></li>);
                items.push(<li key={allCount} className={"page-item " + (allCount === current ? "active" : "")}><a onClick={() => this.handlePageSelected(allCount)}>{allCount}</a></li>);
            }
        } else {
            for (let i = items.length + 1; i <= Math.min(buttonsCount - 2, allCount); i++) {
                items.push(
                    <li key={i} className={"page-item " + (i === current ? "active" : "")}><a onClick={() => this
                        .handlePageSelected(i)}>{i}</a></li>);
            }
            if (items.length < allCount) {
                if (items.length + 2 >= allCount) {
                    for (let i = items.length + 1; i <= Math.min(buttonsCount, allCount); i++) {
                        items.push(
                            <li key={i} className={"page-item " + (i === current ? "active" : "")}><a onClick={() => this
                                .handlePageSelected(i)}>{i}</a></li>);
                    }
                } else {
                    let number = Math.min(buttonsCount - 2, allCount) + 1;
                    items.push(<li key={number} className="page-item range-page-item"><a onClick={() => this.handlePageSelected(number)}>...</a></li>);
                    items.push(<li key={allCount} className={"page-item " + (allCount === current ? "active" : "")}><a onClick={() => this.handlePageSelected(allCount)}>{allCount}</a></li>);
                }
            }
        }
        if (this.props.allCount >= 0) {
            var firtNumber = this.state.pageSize * ((this.state.page > 0 ? this.state.page : 1) - 1) + 1;
            if (this.props.allCount === 0)
                firtNumber = 0;
            var lastNumber = firtNumber;
            if (this.state.page !== this.props.pageCount)
                lastNumber += this.state.pageSize - 1;
            else
                lastNumber = this.props.allCount;
        }
        return (
            <nav className="pagintion-main" key={this.state.keyIndex}>
                {items.length > 1 ?
                    <ul className="cofco-pagination">
                        <li className="page-item arr-left">
                            <a onClick={() => this.handlePageSelected(this.state.page - 1)}>
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.292818 5.29291L5.29282 0.292908L6.70703 1.70712L2.41414 6.00001L6.70703 10.2929L5.29282 11.7071L0.292818 6.70712C-0.0977066 6.3166 -0.0977066 5.68343 0.292818 5.29291Z" fill="#54BCEB" />
                                </svg>
                            </a>
                        </li>
                        {items.map((item) => (
                            item
                        ))}
                        <li className="page-item arr-right">
                            <a onClick={() => this.handlePageSelected(this.state.page + 1)}>
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.70718 6.70709L1.70718 11.7071L0.292969 10.2929L4.58586 5.99999L0.292969 1.70709L1.70718 0.292879L6.70718 5.29288C7.09771 5.6834 7.09771 6.31657 6.70718 6.70709Z" fill="#54BCEB" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                    : ""}
                <div className="pagintion-group">
                    <span>На сторінці</span>
                    <FormControl className="SelMob pagination-select">
                        <Select value={this.state.pageSize} onChange={(e) => this.handleChangePageSize(e.target.value)}>
                            {paginationSettings.select.map(item =>
                                <MenuItem key={item.value} value={item.value}>{item.label ?? item.value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {this.props.allCount >= 0 &&
                        <span>{firtNumber}-{lastNumber} з {this.props.allCount}</span>
                    }
                </div>
            </nav>
        );
    }
    handlePageSelected(page) {
        if (page > 0 && page <= this.props.pageCount) {
            this.setState({
                page: page,
                keyIndex: this.state.keyIndex + 1 
            });
            if (this.props.handlePageChange)
                this.props.handlePageChange(page);
        }
    }
    handleChangePageSize(pageSize) {
        this.setState({
            pageSize: pageSize,
            keyIndex: this.state.keyIndex + 1
        });
        if (this.props.handleChangePageSize)
            this.props.handleChangePageSize(pageSize);
    }
}
