import { companyTypes } from '../../constants';
import i18next from "../../i18n";
const mime = require('mime-types');
export function checkPhone(phone) {
    phone = phone.trim();
    //const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){5,14}(\s*)?$/;
    //const re = /^\+38 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
    const re = /^\+380\d{9}$/;
    return re.test(String(phone));
}
export function checkEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email));
}
export function checkCompanyRegisterCode(code) {
    try {
        if (!code) return false;
        const re = /^\d{8}$/;
        if (!re.test(code))
            return false;
        var number = parseInt(code);
        var getSum = function (shift = false, step = 0) {
            let controlSum = 0;
            for (var i = 0; i < 7; i++) {
                var multiplayer = i + 1;
                if (shift)
                    multiplayer = i === 0 ? 7 : i;
                multiplayer += step;
                controlSum += (parseInt(code[i]) * multiplayer);
            }
            return controlSum % 11;
        }
        var lastNumber = parseInt(code[7]);
        const shift = !(number < 30000000 || number > 60000000);
        let sum = getSum(shift);
        if (sum < 10)
            return sum === lastNumber;
        sum = getSum(shift, 2);
        return sum < 10 ? sum === lastNumber : false;
    }
    catch (e) { return false; }
}
export function checkPersoneRegisterCode(code, companyType) {
    if (!code) return false;
    if (companyType === companyTypes.Entity) {
        const re = /^\d{12}$/;
        if (!re.test(code))
            return false;
    }
    else {
        const re = /^\d{10}$/;
        if (!re.test(code))
            return false;
    }
    return true;
}
export function checkPassword(password) {
    const re1 = /^.{0,}[А-Яа-я\s].{0,}$/;
    const re2 = /^.{0,}[A-Z].{0,}$/;
    const re3 = /^.{0,}[a-z].{0,}$/;
    const re4 = /^.{0,}[0-9].{0,}$/;
    const re5 = /^.{0,}[^A-Za-z0-9А-Яа-я\s].{0,}$/;
    return password && !re1.test(password) && re2.test(password) && re3.test(password) && re4.test(password) && re5.test(password);
}
export function checkFileName(name) {
    let re = /^[^a-zA-Zа-яА-Я0-9\-_\+='\x20].{0,}$/;
    return !re.test(name.trim());
}
export function checkMFO(value) {
    const re = /^\d{6}$/;
    return re.test(value);
}
export function checkSystemName(value) {
    return /^(?![0-9])[a-zA-Z-0-9_ ]+$/.test(value);
}
export function checkBankScore(value) {
    if (value) {
        var re = /^UA\d{27}$/;
        return re.test(value);
    }
    return false;
}
export function getFileAttachmentErrorMessage(file, allowedExtensions, maxFileSize) {
    let errorMessage;
    try {
        let fileName = file?.name;
        if (allowedExtensions && !allowedExtensions.map(item => mime.lookup(item)).includes(mime.lookup(fileName))) {
            errorMessage = i18next.t("invalidFileFormatMessage", { extensions: allowedExtensions.join(', ') });
        }
        if (maxFileSize && maxFileSize < file?.size) {
            errorMessage = i18next.t("wrongFileZize", { size: Math.round((maxFileSize / (1024 * 1024))) });
        }
    }
    catch { }
    return errorMessage;
}