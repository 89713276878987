import { getFile } from '../documents/DocumentHelpers';
import * as constants from '../../constants';
import * as HelperFunctions from '../helpers/HelperFunctions';
export async function handleSubmit(e) {
    if(e) this.setState({ submitted: true });
    let _this = this.state;
    let { file, content, signs, state } = this.state;
    var attachment = {
        currentElementNodeRef: this.state.nodeRef,
        properties: {
            state: file ? constants.attachmentStatuses.NotAdded : state
        },
        files: null
    };
    Object.keys(this.state).forEach(function (key) {
        if (key !== "Indicator")attachment.properties[key] = _this[key];
    });
    if (file && this.state.nodeRef)
        attachment.properties.state = constants.attachmentStatuses.NotAdded;
    if (!file && this.state.nodeRef) {
        content = await getFile(this.state.nodeRef);
    }
    if (file || content) {
        let base64File = file ? await HelperFunctions.getBase64(file) : null;
        let signFile = signs?.length > 0 ? this.state?.signs[0] : null;
        attachment.files = [{
            originalDocument: (base64File ? {
                content: base64File.content,
                fileName: this.state.file?.name,
                mimeType: base64File.mimeType
            } : content), signedDocument: signFile ? signFile.content : null
        }];
    }
    await fetch(`Document/${attachment.currentElementNodeRef ? "Edit" : "Create"}AdditionalFiles/${window.location.pathname.split('/').pop()}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(attachment)
    }).then(response => {
        if (response.ok) window.location.reload();
    });
}