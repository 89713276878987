import React from 'react';
import { AccountFormHeader } from './AccountFormHeader';
export function AccountMessageContainer(props) {
    const { className } = props;
    return (<div className={`form-page ${className}`}>
        <div className="form-block large-form">
            <AccountFormHeader className="form-header-subtitle-right form-subtitle" />
            {props.children}
        </div>
    </div>);
}