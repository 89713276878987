import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import i18next from "../i18n";
import * as HelperFunctions from './helpers/HelperFunctions';
const minScale = 25;
const maxScale = 500;
const scaleStep = 10;
export class PdfViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 0,
            bufferScale: "100%",
            scale: 100,
            pageIndex: 0
        }
    }
    setScale(scale) {
        const re = /[0-9]/gi;
        let value = scale?.toString()?.match(re)?.join('') ?? minScale;
        if (value < minScale) value = minScale;
        else if (value > maxScale) value = maxScale;
        value = (re.test(value) ? value : this.state.bufferScale);
        this.setState({ bufferScale: value + "%", scale: value });
    }
    render() {
        const { src } = this.props;
        const { numPages, bufferScale, scale } = this.state;
        return (
            <div className="pdf-viewer">
                <div className="pdf-viewer__settings">
                    <button onClick={(e) => { e.preventDefault(); this.setScale.bind(this)(Number(scale) - scaleStep) }}>–</button>
                    <input value={bufferScale} onChange={HelperFunctions.changeInputValue.bind(this)} name={Object.keys({ bufferScale })[0]} onBlur={(e) => this.setScale.bind(this)(e.target.value)} onKeyDown={(e) => { if (e.keyCode === 13) e.target.blur() }} />
                    <button onClick={(e) => { e.preventDefault(); this.setScale.bind(this)(Number(scale) + scaleStep) }}>+</button>
                </div>
                <div className="pdf-viewer__body scrollYInner">
                    <Document file={src} onLoadSuccess={(page) => this.setState({ numPages: page.numPages })} loading={i18next.t("loading")} onSourceError={() => i18next.t("unknownErrorMessage")}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} scale={scale / 100} pageIndex={index} loading={i18next.t("pageLoading")} />
                        ))}
                    </Document>
                </div>
            </div>
        );
    }
}