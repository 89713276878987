import React, { Component } from 'react'
import { Route, Router, Switch, Link, Redirect } from 'react-router-dom'
import { NavLink } from 'reactstrap';
import { LoginPage } from './components/account/LoginPage'
import { RegisterPage } from './components/account/RegisterPage'
import { RestorePasswordPage } from './components/account/RestorePasswordPage'
import { PasswordRecoveryPage } from './components/account/PasswordRecoveryPage'
import { SessionEndedPage } from './components/account/SessionEndedPage'
import { dispatcherActionTypes, globalEventDispatcher } from './components/helpers/GlobalEventDispatcher'
import { ResponseStatusePage } from './components/pages/ResponseStatusePage'
import { constants, settingsFormTypes, History, statutoryDocTypes, urls, searchTableTypes, resources, alfrescoRoles } from './constants'
import { logout } from './components/helpers/HelperFunctions'
import * as ModalDialog from './components/ModalDialog'
import { Content } from './components/Content'
import { AccessContent } from './components/AccessContent'
import { ListOfSearchResults } from './components/listsOfAlfresco/ListOfSearchResults'
import { AccessDeniedPage } from './components/pages/AccessDeniedPage'
import { StatutoryDocumentsPage } from './components/pages/StatutoryDocumentsPage';
import { TemplateDocumentsPage } from './components/pages/TemplateDocumentsPage';
import { AnnouncementDocumentsPage } from './components/pages/AnnouncementDocumentsPage';
import { Document } from './components/documents/Document'
import { ListOfAlfresco } from './components/listsOfAlfresco/ListOfAlfresco'
import { UserContext } from './constants';
import i18next from "./i18n";
import { InfoSettingsForm } from './components/forms/InfoSettingsForm';
import { ProfileSettingsForm } from './components/forms/ProfileSettingsForm';
import { StatutoryDocsForm } from './components/forms/StatutoryDocsForm';
import { PricesSettingsForm } from './components/forms/PricesSettingsForm';
import { PricesTable } from './components/forms/PricesTable';
import * as ListOfUsers from './components/listsOfAlfresco/ListOfUsers';
import * as ListOfCompanies from './components/listsOfAlfresco/ListOfCompanies';
import { DocumentsRegisterRoutes } from './components/DocumentsRegisterRoutes'
import './style/app.css';
export default class App extends Component {
    constructor(props) {
        super(props)
        let counterOfrequest = 0
        window.fetch = new Proxy(window.fetch, {
            apply(fetch, that, args) {
                counterOfrequest++
                ModalDialog.showModalDialog(constants.placeholderDialogId, "", "", <img src={resources.loadingIcone} className="loading-icone" alt="" />, "transparente", false);
                let user = JSON.parse(localStorage?.getItem('user'));
                let authHeader = user ? { 'Authorization': 'Bearer ' + user.token } : null;
                if (authHeader) {
                    if (user && (!args[0].startsWith('data:')))
                        args[0] += (args[0].includes("?") ? "&" : "?") + `companyCode=${user?.companies[0]?.registerCode}`
                    if (args.length === 1) args.push({ headers: {} })
                    args[1].headers = args[1].headers ? args[1].headers : {}
                    args[1].headers = Object.assign(args[1].headers, authHeader)
                }
                const result = fetch.apply(that, args)
                result.then(async (response) => {
                    counterOfrequest--
                    if (counterOfrequest === 0) ModalDialog.hideModalDialog(constants.placeholderDialogId);
                    if (response.status === 401 && args[0]?.toLower()?.startsWith('/account/logout')) logout()
                    if (response.status === 403) History.push('/no-acces')
                    if (response.status === 500) window.location.replace("/500")
                })
                return result
            }
        })
    }
    render() {
        document.addEventListener('DOMContentLoaded', function () {
            var target = document.getElementById('root')
            target.onclick = () => { globalEventDispatcher.dispatch(dispatcherActionTypes.onUserAction) }
            const callback = function (mutationsList) {
                let mutationWasDone = false
                for (let mutation of mutationsList)
                    if (mutation.type === 'childList' || mutation.type === 'attributes')
                        mutationWasDone = true
                if (mutationWasDone)
                    globalEventDispatcher.dispatch(dispatcherActionTypes.onUserAction)
            }
            const observer = new MutationObserver(callback);
            observer.observe(target, {
                attributes: true,
                childList: true,
                subtree: true
            })
        })
        return (
            <UserContext.Provider value={JSON.parse(localStorage.getItem('user'))}>
                <div className="root-block">
                    <div id='modalDialog'></div>
                    <div id="loadingDialog"></div>
                    <div id={constants.placeholderDialogId}></div>
                    <Router history={History}>
                        <Switch>
                            <Redirect from="/document/:id" to="/content/:id" />
                            {[
                                [urls.loginPage, LoginPage],
                                [urls.registerPage, RegisterPage],
                                [urls.recoveryPasswordPage, PasswordRecoveryPage],
                                ['/session-ended', SessionEndedPage],
                                ['/documents/:status/:status', DocumentsRegisterRoutes],
                                ['/documents/:status?', DocumentsRegisterRoutes],
                                ['/outgoingDocuments/:status/:status', DocumentsRegisterRoutes],
                                ['/outgoingDocuments/:status?', DocumentsRegisterRoutes],
                                ['/settings/:tab?'],
                                ['/search'],
                                ['/no-acces'],
                                ['/content/:id']].map((value, index) =>
                                    <Route exact key={index} path={value[0]} component={value.length === 1 ? Home : value[1]} />
                                )
                            }
                            {['/404', '/500'].map((value, index) => <Route key={index} exact path={value}><ResponseStatusePage code={value.split('/').pop()} /></Route>)}
                            <Route path='/create/counterpartyDoc' component={Home} />
                            <Route path='/restorepassword' component={RestorePasswordPage} />
                            <Route exact path='/prices' component={Home} />
                            <Route path='/statutoryDocs/:tab?' component={Home} />
                            <Route path='/templates/:tab?' component={Home} />
                            <Route path='/announcements/:tab?' component={Home} />
                            <Route path='/admin/:tab?' component={Home} />
                            <Route exact path='/' component={MainPage} />
                            <Route><ResponseStatusePage code={"404"} /></Route>
                        </Switch>
                    </Router>
                </div>
            </UserContext.Provider>
        )
    }
}
function MainPage() {
    const userData = localStorage.getItem("user");
    if (userData && JSON.parse(userData).role === alfrescoRoles.PORTAL_ADMINISTRATOR) return (<Redirect to="/admin" />);
    return (<DocumentsRegisterRoutes />);
}
export function Home() {
    return (
        <Content>
            <Switch>
                {[['/search', <ListOfSearchResults />], ['/no-acces', <AccessDeniedPage />], ['/content/:id', <Document />]].map((item, index) => <Route key={index} exact path={item[0]} render={() => item[1]} />)}
                <Route path='/create/counterpartyDoc'><Document type='sx:counterpartyDoc' /></Route>
                <Route path='/statutoryDocs/:tab?' component={StatutoryDocumentsPage} />
                <Route path='/templates/:tab?' component={TemplateDocumentsPage} />
                <Route path='/announcements/:tab?' component={AnnouncementDocumentsPage} />
                <Route exact path='/prices' component={PricesTable} />
                <Route path='/settings/users'><AccessDeniedPage /></Route>
                <Route path='/settings/:tab?'><Settings controller={settingsFormTypes.settingsType} /></Route>
                <Route path='/admin/:tab?'>
                    <AccessContent>
                        <Settings controller={settingsFormTypes.adminType} />
                    </AccessContent>
                </Route>;
            </Switch>
        </Content>
    );
}
export function Admin() {
    return (
        <Content>
            AccessContent
            <Switch>
                <Route path='/admin/:tab?'><Settings controller={settingsFormTypes.adminType} /></Route>;
            </Switch>
        </Content>
    )
    return
}
class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: []
        };
    }
    componentDidMount() {
        let tabUrlParts = window.location.pathname.split('/');
        let tabUrl = '/';
        if (tabUrlParts.length > 1)
            tabUrl += tabUrlParts[1];
        if (tabUrlParts.length > 2)
            tabUrl += `/${tabUrlParts[2]}`;
        fetch(`home/navigation?tabUrl=${tabUrl}&countInBoxes=${true}`)
            .then(async (response) => {
                if (!response.ok) return require("./components/ModalDialog").unknownErrorMessageDialog();
                let data = await response.json();
                this.setState({ menuItems: data.Menu.menuItems });
            });
    }
    renderContent(menuItem) {
        switch (menuItem?.name) {
            case 'profile': return (<ProfileSettingsForm key="profile" />);
            case 'myCompany': return (<ListOfAlfresco saveApi={`Table/Save?searchTableTypes=${searchTableTypes.Companies}`} key="myCompany" parentComponent={ListOfCompanies} hideFilter={true} />);
            case 'companies': return (<ListOfAlfresco saveApi={`Table/Save?searchTableTypes=${searchTableTypes.Companies}`} key="companies" parentComponent={ListOfCompanies} />);
            case 'users': return (<ListOfAlfresco saveApi={`Table/Save?searchTableTypes=${searchTableTypes.Users}`} key="users" parentComponent={ListOfUsers} type={this.props.controller} />);
            case 'statutoryDocs': return (<StatutoryDocsForm key="statutoryDocs" docType={statutoryDocTypes.statutory} baseUrl={menuItem.link?.url} canEdit={true} />);
            case 'templates': return (<StatutoryDocsForm key="templates" docType={statutoryDocTypes.template} baseUrl={menuItem.link?.url} canEdit={true} />);
            case 'announcements': return (<StatutoryDocsForm key="announcements" docType={statutoryDocTypes.announcement} baseUrl={menuItem.link?.url} canEdit={true} />);
            case 'prices': return (<PricesSettingsForm />);
            case 'info': return (<InfoSettingsForm key="info" />);
        }
    }
    render() {
        const { menuItems } = this.state;
        let selectedIndex = menuItems.findIndex(item => item.link && window.location.pathname.includes(item.link.url));
        selectedIndex = selectedIndex < 0 ? 0 : selectedIndex;
        return (<div className="content">
            <h1>{this.props.controller === settingsFormTypes.settingsType ? i18next.t("settings") : i18next.t("administration")}</h1>
            <div className="tabsMobile">
                <ul className="tabs">
                    {menuItems?.filter((item) => !item.hiddenForTabs)?.map((item, index) =>
                        <li className={index === selectedIndex ? "active" : ""} key={item?.name + index}>
                            <NavLink tag={Link} to={item.link?.url}>{item.link?.text}</NavLink>
                        </li>
                    )}
                </ul>
            </div>
            <div className="settings-block">{this.renderContent(menuItems[selectedIndex])}</div>
        </div>);
    }
}