import React, { Component } from 'react';
import { signatureStates } from '../../constants';
import i18next from "../../i18n";
import { Svg } from '../Svg';
export function CheckSignsForm(props) {
    const { signedFiles } = props;
    let groupsOfSigns = {}
    for (let i = 0; i < signedFiles.length; ++i) {
        let { documentTypeValue } = signedFiles[i];
        for (let j = 0; j < signedFiles[i].signs.length; ++j) {
            let sign = signedFiles[i].signs[j];
            let { subjEdrpou, subjDrfo } = sign;
            if (!groupsOfSigns[subjEdrpou ? subjEdrpou : subjDrfo]) groupsOfSigns[subjEdrpou ? subjEdrpou : subjDrfo] = [];
            groupsOfSigns[subjEdrpou ? subjEdrpou : subjDrfo].push(Object.assign(sign, { documentTypeValue: documentTypeValue }));
        }
    }
    return signedFiles.length > 0 ? Object.keys(groupsOfSigns).map((key, index) => <CheckSignsGroup key={index} signs={groupsOfSigns[key]} /> ) : <div className="info-message" > {i18next.t("checkSignatoriesNotFoundMessage")}</div>;
}
class CheckSignsGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
        this.onExpand = this.onExpand.bind(this);
    }
    onExpand(expanded) {
        this.setState({ expanded: expanded });
    }
    render() {
        const { expanded } = this.state;
        const { signs } = this.props;
        return (
            <div className="check-signs-form">
                <div className="check-signs-item">
                    <div className="user-name">{signs[0].subjDrfo ? signs[0].name : signs[0].organization}</div>
                    {expanded ?
                        <button type="button" className="btn btn-secondary expand-btn" onClick={() => this.onExpand(false)}>{i18next.t("hide")}</button> :
                        <button type="button" className="btn btn-primary expand-btn" onClick={() => this.onExpand(true)}>{i18next.t("readMore")}</button>
                    }
                    {expanded &&
                        signs.map((data, index) => <CheckSignsItem key={signs[index].date + signs[index].signSerial} data={data} />)
                    }
                </div>
            </div>
        );
    }
}
function CheckSignsItem(props) {
    const { data } = props;
    if (!data)
        return (<div className="check-signs-item"></div>);
    var fileName = data?.documentTypeValue ?? data.content?.fileName;
    if (data.documentTypeValue && data.content?.fileName)
        fileName += ' (' + data.content.fileName + ')';
    return (<SignItem signatory={data} fileName={fileName} />);
}
class SignItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }
    render() {
        const { signatory, fileName } = this.props;
        const { expanded } = this.state;
        if (!signatory)
            return (<div className="check-signs-item"></div>);
        return (
            <div className="check-signs-item">
                <div className="user-name">{signatory.name}</div>
                <div className="file-name">{fileName}</div>
                <div className="sign-date">{signatory.date}</div>
                <span className={"status-r" + (signatory.state === signatureStates.signed ? " signed" : " not-signed")}>{signatory.state === "1" ? "Підписано" : "Не підписано"}</span>
                <Svg className="sign-svg" src="/img/sign.svg" />
                {expanded ?
                    <button type="button" className="btn btn-secondary expand-btn" onClick={() => this.setState({ expanded: false })}>{i18next.t("hide")}</button> :
                    <button type="button" className="btn btn-primary expand-btn" onClick={() => this.setState({ expanded: true })}>{i18next.t("readMore")}</button>
                }
                {expanded &&
                    <div className="sign-detail-info">
                        <div className={"sign-detail-message" + (signatory.state === "1" ? " signed" : " not-signed")}>
                            <Svg src={signatory.state === "1" ? "/img/ok.svg" : "/img/warning.svg"} />
                            {signatory.state === "1" ? i18next.t("succesfullCheckSignMessage") : i18next.t("failedCheckSignMessage") }
                        </div>
                        <div className="sign-detail-info-item">
                            <span>{i18next.t("Підписувач:")}</span>{signatory.name}
                        </div>
                        <div className="sign-detail-info-item">
                            <span>{i18next.t("Організація:")}</span>{signatory.organization}
                        </div>
                        <div className="sign-detail-info-item">
                            <span>{i18next.t("Час підпису:")}</span>{signatory.date}
                        </div>
                        <div className="sign-detail-info-item">
                            <span>{i18next.t("Сертифікат виданий:")}</span>{signatory.issuerCN}
                        </div>
                        <div className="sign-detail-info-item">
                            <span>{i18next.t("Серійний номер:")}</span>{signatory.signSerial}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
