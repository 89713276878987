import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import i18next from "../../i18n";
import { PasswordInput } from '../forms/PasswordInput';
import { getWordWithCorrectEnding, decodeHtml } from '../helpers/HelperFunctions';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
import * as ModalDialogsTemplates from '../ModalDialog';
import { AccountFormHeader } from './AccountFormHeader';
import { AccountMessageContainer } from './AccountMessageContainer';
import * as AccountHelper from "./AccountHelper";
import { loginFaildeStatus } from '../../constants';
export class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            submitted: false,
            incorrectLogin: false,
            loggedIn: false,
            confirmationEmailSended: false,
            confirmationEmailError: '',
            isHasLoginAttempts: true,
            allowedAttempts: 1
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendedEmailCallback = this.sendedEmailCallback.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, incorrectLogin: false });
    }
    handleSubmit(e) {
        e.preventDefault();
        const { email, password } = this.state;
        if (email && password)
            this.logIn(email, password);
        else
            this.setState({ submitted: true });
    }
    sendedEmailCallback(error) {
        this.setState({ confirmationEmailSended: true, confirmationEmailError: error });
    }
    logIn(email, password) {
        localStorage.removeItem('user');
        const currentObject = this;
        fetch('/account/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.ok && data && data.token) {
                    if (data.companies?.length > 0) {
                        data.companies.forEach(c => {
                            c.companyName = decodeHtml(c.companyName);
                            c.companyShortName = decodeHtml(c.companyShortName);
                        });                        
                    }
                    localStorage.setItem('user', JSON.stringify(data));
                    currentObject.setState({ loggedIn: true });
                }
                else {
                    if (data?.error === loginFaildeStatus.userBlocked)
                        showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("blockedErrorMessage")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
                    else if (data?.error === loginFaildeStatus.userNotExist)
                        currentObject.setState({ submitted: true, incorrectLogin: true, allowedAttempts: 1, isHasLoginAttempts: true });
                    else if (data?.allowedAttempts >= 0)
                        currentObject.setState({ submitted: true, incorrectLogin: true, allowedAttempts: data.allowedAttempts, isHasLoginAttempts: !(data.allowedAttempts <= 0) });
                    else ModalDialogsTemplates.unknownErrorMessageDialog();
                }
            });
    }
    render() {
        const { allowedAttempts, isHasLoginAttempts, email, password, submitted, incorrectLogin, loggedIn, confirmationEmailSended, confirmationEmailError } = this.state;
        if (loggedIn) window.location.replace("/");
        if (!isHasLoginAttempts) {
            return (<AccountMessageContainer>
                <div className="form-content">
                    <div className="info-message-title">{i18next.t("error")}</div>
                    <div className="info-message">{i18next.t("hasNoLoginAttempts")}</div>
                </div>
            </AccountMessageContainer>);
        }
        if (confirmationEmailSended) {
            return (<AccountMessageContainer>
                <div className="form-content">
                    <div className="info-message-title">{confirmationEmailError ? i18next.t("error") : i18next.t("done")}</div>
                    {confirmationEmailError ? <div className="info-message">{confirmationEmailError}</div> :
                        <>
                            <div className="info-message">{i18next.t("registerCompletedMessage")}</div>,
                            <div className="form-group form-group__last">
                                <NavLink className="bottom-form-link message-form-link" tag={Link} onClick={() => window.location.reload()}>
                                    {i18next.t("logInToYourAccount")} <div className="collapsed-menu-icone"></div>
                                </NavLink>
                            </div>
                        </>
                    }
                </div>
            </AccountMessageContainer>);
        }
        return (
            <div className="form-page login-page">
                <div className="form-block login-form">
                    <AccountFormHeader className="form-header-subtitle form-subtitle" />
                    <div className="form-content">
                        <div className="form-title">{i18next.t("Вхід в систему")}</div>
                        {false && submitted && incorrectLogin && allowedAttempts > 0 &&
                            <div className="allowed-login-attempts">Залишилось {allowedAttempts} {getWordWithCorrectEnding(allowedAttempts, 'спроба')}</div>
                        }
                        <form name="form" onSubmit={this.handleSubmit} >
                            <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                <label htmlFor="email" className="required">{i18next.t("21")}</label>
                                <input className="form-control" name="email" value={email} onChange={this.handleChange} />
                                {AccountHelper.isRequiredMessage(submitted && !email)}
                                {AccountHelper.errorMessage(submitted && incorrectLogin, 'failedLoginAttempt')}
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <label htmlFor="password" className="required">{i18next.t("password")}</label>
                                <PasswordInput className="form-control" name="password" value={password} onChange={this.handleChange} />
                                {AccountHelper.isRequiredMessage(submitted && !password)}
                            </div>
                            <div className="form-group form-group__last">
                                <button className="btn btn-primary">{i18next.t("logIn")}</button>
                                <NavLink className="bottom-form-link" tag={Link} to="/password-recovery" onClick={this.resetForm}>
                                    {i18next.t("forgotYourPassword")} <div className="collapsed-menu-icone"></div>
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}