import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { constants } from '../../constants';
import i18next from "../../i18n";
import { checkEmail, checkPhone } from '../helpers/CheckFunctions';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
import { MenuLinksForm } from './MenuLinksForm';
export class InfoSettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: true,
            submitted: false,
            wasChanged: false,
            phone: '',
            email: '',
            file: null,
            badExtension: false,
            fileHasBadExtension: false,
            offerAgreementFile: null,
            offerAgreementFileHasBadExtension: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    handleFileRemove(name) {
        let id = "file-iput-controll" + name;
        if (document.getElementById(id)?.value) {
            document.getElementById(id).value = "";
        }
        this.setState({ [name]: null, [name + "HasBadExtension"]: false });
    }
    handleFileChange(event, name) {
        if (event.target.files && event.target.files.length > 0) {
            var parts = event.target.files[0].name.split('.');
            if (parts.length > 1 && constants.allowedInfoFileExtensions.findIndex(ex => ex === parts[parts.length - 1].toLowerCase()) > -1) {
                this.setState({
                    [name + "HasBadExtension"]: false,
                    wasChanged: true,
                    [name]: event.target.files[0]
                });
            }
            else {
                this.setState({
                    [name + "HasBadExtension"]: true,
                    file: null,
                    [name]: event.target.files[0]
                });
            }
        }
        else {
            event.target.value = "";
            this.setState({
                [name + "HasBadExtension"]: false,
                file: null
            });
        }
    }
    async loadData() {
        const { file, offerAgreementFile } = this.state;
        const response = await fetch('/admin/getInfoSettings');
        if (!response.ok) {
            require('../ModalDialog').unknownErrorMessageDialog();
            return;
        }
        const data = await response.json();
        this.setState({ wasChanged: false, sending: false, email: data.email, phone: data.phone, submitted: false });
        this.handleFileRemove(Object.keys({ file })[0]);
        this.handleFileRemove(Object.keys({ offerAgreementFile })[0]);
    }
    onCancel() {
        this.loadData();
    }
    componentDidMount() {
        this.loadData();
    }
    handleChange(event) {
        const { name, value } = event.target;
        let wasChanged = name !== 'phone' ? true : this.state.wasChanged || (value !== '+380' && value !== '');
        this.setState({
            [name]: value,
            wasChanged: wasChanged
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const { sending, email, phone } = this.state;
        this.setState({ submitted: true });
        if (!sending && email && checkEmail(email) && phone && checkPhone(phone)) {
            this.setState({ sending: true });
            this.submit();
        }
    }
    async submit() {
        const { phone, email } = this.state
        const currentObject = this;
        const formData = new FormData();
        formData.append('phone', phone);
        formData.append('email', email);
        function onClose() {
            hideModalDialog("loadingDialog");
            if (currentObject.props.onSubmitedHandler) currentObject.props.onSubmitedHandler();
            currentObject.loadData();
        }
        fetch('/admin/updateInfoSettings', {
            method: 'POST',
            headers: { "Contetnt-Type": "multipart/form-data" },
            body: formData
        })
            .then((response) => {
                return response.ok ? response.text().then(() => {
                    currentObject.setState({ sending: false });
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">{i18next.t("infoSuccessfullyUpdated")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: onClose, class: "btn-primary" }], onClose);
                }) : response.text().then((errorText) => {
                    console.log(errorText);
                    showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("unknownErrorMessage")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: onClose, class: "btn-primary" }], onClose);
                });
            });
    }
    render() {
        const { sending, submitted, email, phone, wasChanged } = this.state;
        return [
            <div className="form-block large-form settings-form" key="settings-form">
                <form name="form" className="popupSimple file-upload-form" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-sm-6 col-xs-6">
                            <label htmlFor="phone" className="required">Контактний телефон</label>
                            <InputMask type="tel" className="form-control" name="phone" mask='+380999999999' maskChar={null} value={phone} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                            {submitted && !phone &&
                                <div className="help-block">{i18next.t("requiredField")}</div>}
                            {submitted && phone && !checkPhone(phone) &&
                                <div className="help-block">{i18next.t("badFieldFormat")}</div>}
                        </div>
                        <div className="form-group col-sm-6 col-xs-6">
                            <label htmlFor="email" className="required">{i18next.t("feedbackMail")}</label>
                            <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                            {submitted && !email &&
                                <div className="help-block">{i18next.t("requiredField")}</div>}
                            {submitted && email && !checkEmail(email) &&
                                <div className="help-block">{i18next.t("badFieldFormat")}</div>}
                        </div>
                    </div>
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary">{i18next.t("save")}</button>
                        {wasChanged &&
                            <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        }
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                    </div>
                    </div>
                </form>
                
            </div>,
            <MenuLinksForm />
        ];
    }
}
