import React from 'react';
import { statusFieldColors } from '../../constants';
import { TableColumnType } from './Table';
export function TableColumn(props) {
    const getPhoneHref = (value) => {
        value = value.replaceAll(' ', '');
        value = value.replaceAll('-', '');
        value = value.replaceAll('(', '');
        value = value.replaceAll(')', '');
        return 'tel:' + value;
    }
    const getStatusColColor = (status) => {
        let color = statusFieldColors["Default"];
        if (statusFieldColors[status])
            color = statusFieldColors[status];
        return color;
    }
    const onDocLinkClick = (e) => {
        e.preventDefault();
        if (props.onDocLinkClick)
            props.onDocLinkClick();
    }
    const { children, value, col } = props;
    if (col && (value || (col.type === "button" && col.buttonSettings)) && col.type) {
        switch (col.type) {
            case TableColumnType.Phone:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><a href={getPhoneHref(value)}>{value}</a></td>);
            case TableColumnType.Email:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><a href={'mailto:' + value}>{value}</a></td>);
            case TableColumnType.DocLink:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><a href="" onClick={onDocLinkClick}>{value}</a></td>);
            case TableColumnType.Status:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><span className='status-r' style={{ backgroundColor: getStatusColColor(value) }}>{value}</span></td>);
            case TableColumnType.Button:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><button type="button" className="btn btn-no-fill">{col.buttonSettings.title}</button></td>);
            case TableColumnType.Bool:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><img src="/img/icn-check.svg" alt=""></img></td>);
            case TableColumnType.Image:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><img src={value} alt=""></img></td>);
            case TableColumnType.Comment:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}><img src="/img/icn-chat.svg" title={value} alt=""></img></td>);
            case TableColumnType.String:
                return (<td style={{display: (col.hidden? "none":"table-cell")}} dangerouslySetInnerHTML={{__html: value}} />);
            case TableColumnType.Date:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }} dangerouslySetInnerHTML={{ __html: value.length === 10 ? value : (new Date(value)).toLocaleDateString("uk-UA")}} />);
            case TableColumnType.DateTime:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }} dangerouslySetInnerHTML={{ __html: (new Date(value)).toLocaleString("uk-UA") }} />);
            case TableColumnType.Reorder:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}>
                    <ul className='reorder-r'>
                        <li>
                            <button disabled={value.firstRow ? "disabled" : ""} onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!value.firstRow && value.reorderUpHandler)
                                    value.reorderUpHandler();
                            }}>
                                <span className="icon-op icon-op-up">
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.70703 0.29294L11.707 5.29294L10.2928 6.70715L5.99993 2.41426L1.70703 6.70715L0.292818 5.29294L5.29282 0.29294C5.68334 -0.0975848 6.31651 -0.0975847 6.70703 0.29294Z" fill="#fff" />
                                    </svg>
                                </span>
                            </button>
                        </li>
                        <li>
                            <button disabled={value.lastRow ? "disabled" : ""} onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!value.lastRow && value.reorderDownHandler)
                                    value.reorderDownHandler();
                            }}>
                                <span className="icon-op icon-op-down">
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29297 6.70706L0.292969 1.70706L1.70718 0.292847L6.00008 4.58574L10.293 0.292847L11.7072 1.70706L6.70718 6.70706C6.31666 7.09758 5.68349 7.09758 5.29297 6.70706Z" fill="#fff" />
                                    </svg>
                                </span>
                            </button>
                        </li>
                    </ul>
                </td>);
            default:
                return (<td style={{ display: (col.hidden ? "none" : "table-cell") }}>{value}</td>);
        }
    }
    return (
        <td style={{ display: (col && col.hidden ? "none" : "table-cell") }}>{children}</td>
    );
}