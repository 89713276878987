import React,{Component} from 'react';
import { formModes, UserContext, alfrescoTypes } from '../../constants';
import {Svg} from '../Svg';
import i18next from "../../i18n";
import { getLocalDateString, getLocalTimeString } from '../helpers/HelperFunctions';
import { globalEventDispatcher, dispatcherActionTypes } from '../helpers/GlobalEventDispatcher';
export class DocumentInfo extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { show: this.props.type === alfrescoTypes.counterparties };
        this.clickEvent = this.clickEvent.bind(this);
        this.globalEventListerner = this.globalEventListerner.bind(this);
        globalEventDispatcher.subscribe(this.globalEventListerner);
    }
    clickEvent(e) {
        let targetId = e.target.id;
        if (targetId !== 'status-item-btn' && !this.hasParent(e.target, 'status-item-btn') && targetId !== 'about-doc-info' && !this.hasParent(e.target, 'about-doc-info')) {
            this.setState({ show: false }, () => globalEventDispatcher.dispatch(dispatcherActionTypes.onDocInfoClicked, false));
        }
    }
    componentDidMount() {
        if (this.props.type !== alfrescoTypes.counterparties)
            document.addEventListener("click", this.clickEvent);
    }
    componentWillUnmount() {
        if (this.props.type !== alfrescoTypes.counterparties)
            document.removeEventListener("click", this.clickEvent);
        globalEventDispatcher.unsubscribe(this.globalEventListerner);
    }
    hasParent(element, id) {
        while (element.parentElement) {
            if (element.parentElement.id === id)
                return true;
            element = element.parentElement;
        }
        return false;
    }
    globalEventListerner(actionType, show) {
        if (actionType === dispatcherActionTypes.onDocInfoClicked) {
            if (this.state.show !== show) {
                this.setState({ show: show });
            }
        }
    }
    render() {
        const { cretorInfo, prop_cm_created, prop_sx_dfState, prop_sx_dfStates, formMode, prop_sx_caDateNextAcreditation, caBranchManager } = this.props;
        let info = { date: prop_cm_created ? `${getLocalDateString(new Date(prop_cm_created))} ${getLocalTimeString(new Date(prop_cm_created)).substring(0, 5)}` : '', status: prop_sx_dfStates?.find(obj => obj.Value === prop_sx_dfState)?.Label, author: cretorInfo ? `${cretorInfo.creatorFullName ? cretorInfo.creatorFullName : ""}${cretorInfo.creatorPosition ? ', ' + cretorInfo.creatorPosition : ""}` : "" };
        let ul = [];
        ul.push(caBranchManager ? { src: "/img/icon-person.svg", key: "Менеджер:", value: <label className="info-content-label">{caBranchManager.lastName} {caBranchManager.firstName}</label> } : { src: "/img/icon-person.svg", key: "Автор:", value: info.author ? <label className="info-content-label">{info.author}</label> : "" });
        if (caBranchManager) ul.push({ src: "/img/info.svg", key: "Номер телефону фах. філії:", value: <label className="info-content-label">{caBranchManager.telephone}</label> });
        ul.push({ src: "/img/location.svg", key: "Статус:", value: <label className="status-r">{info.status}</label> });
        if (formMode !== formModes.create) ul.push({ src: "/img/clock.svg", key: "Дата створення:", value: info?.date && <label className="info-content-label">{info.date}</label> });
        if (prop_sx_caDateNextAcreditation) ul.push({ src: "/img/clock.svg", key: "Акредитовано до:", value: <label className="info-content-label">{getLocalDateString(new Date(prop_sx_caDateNextAcreditation))}</label> });
        return (
            <li className="status-item">
                <div className="status-item-btn" id="status-item-btn" onClick={() => {
                    this.setState({ show: !this.state.show }, () => globalEventDispatcher.dispatch(dispatcherActionTypes.onDocInfoClicked, this.state.show));
                }}>
                    <Svg src="/img/info.svg" />
                    <label>{i18next.t("aboutDocument")}</label>
                </div>
                {this.state.show &&
                    <div className="about-doc-info" id="about-doc-info">
                        <ul>
                            <li>
                                <Svg src="/img/info.svg" />
                                <label>{i18next.t("aboutDocumentInfo")}</label>
                            </li>
                            {ul.map((item) => item?.value ? <li key={require('uuid').v4()}>
                                <Svg src={item.src} />
                                <label>{item.key}</label>
                                {item.value}
                            </li> : "")}
                        </ul>
                    </div>
                }
            </li>
        );
    }
}