import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import * as constants from '../../constants';
import * as DocumentHelpers from '../documents/DocumentHelpers';
import { getArrayIds, getCurrentUrlPath, parseFilterString } from '../helpers/HelperFunctions';
import * as ModalDialog from '../ModalDialog';
import { History } from '../../constants';
import { Table } from '../table/Table';
import { TableFilterBlock } from '../table/TableFilterBlock';
import * as ListHelpers from './ListHelpers';
import i18next from "../../i18n";
import { dispatcherActionTypes, globalEventDispatcher } from '../helpers/GlobalEventDispatcher'
export function inboxNavigate(index) {
    globalEventDispatcher.dispatch(dispatcherActionTypes.onTabChanged);

    this.setState({ selectedInboxNumber: index });
    const { sortBy, isDesk, filterValues, pageSize } = this.state;
    var allowedValues = this.getAllowedEnumValues(index);
    if (allowedValues["Status"] && allowedValues["Status"].length > 0
        && filterValues["Status"] && filterValues["Status"].length > 0
        && !(filterValues["Status"].length === 1 && filterValues["Status"][0] === 'hideAllValue')) {
        if (filterValues["Status"].filter(it => allowedValues["Status"].includes(it)).length === 0)
            filterValues["Status"] = [];
    }
    this.loadData(1, pageSize, sortBy, isDesk, filterValues);
}
export function getSelectedMenuItem() {
    let curUrl = getCurrentUrlPath();
    const { menuItems } = this.state;
    if (menuItems.length > 0) {
        let tabIndex = menuItems.findIndex(item => item.link && item.link.url === curUrl);
        if (tabIndex >= 0) return menuItems[tabIndex];
    }
}
export function getAllowedEnumValues(inbox = -1) {
    let result = {};
    let selectedMenuItem = this.getSelectedMenuItem();
    if (inbox === -1)
        inbox = this.state.selectedInboxNumber;
    if (selectedMenuItem) {
        let statuses;
        if (selectedMenuItem?.inBoxes?.length > inbox) {
            let currentInbox = selectedMenuItem.inBoxes[inbox];
            statuses = currentInbox.counter?.settings?.Status;
        }
        if (!statuses)
            statuses = selectedMenuItem.counter?.settings?.Status;
        if (statuses && statuses.length > 0) result["Status"] = statuses;
    }
    return result;
}

export function getFilterValuesFromMenu(filterValues, menuItems = null) {
    let inbox = this.state.selectedInboxNumber;
    let additionalFilers = {};
    if ((!menuItems ? menuItems = this.state.menuItems : menuItems).length > 0) {
        var index = menuItems.findIndex(item => item.link?.url.toLocaleLowerCase() === getCurrentUrlPath().toLocaleLowerCase());
        if (index >= 0) {
            if (inbox >= 0 && menuItems[index]?.inBoxes?.length > inbox) {
                var currentInBox = menuItems[index].inBoxes[inbox];
                if (currentInBox.counter?.settings?.Filter?.length > 0) {
                    for (let i = 0; i < currentInBox.counter.settings.Filter.length; i++) {
                        let values = parseFilterString(currentInBox.counter.settings.Filter[i]);
                        additionalFilers = Object.assign(additionalFilers, values);
                    }
                }
            }
            if (menuItems[index]?.counter?.settings?.Filter?.length > 0) {
                for (let i = 0; i < menuItems[index].counter.settings.Filter.length; i++) {
                    let values = parseFilterString(menuItems[index].counter.settings.Filter[i]);
                    additionalFilers = Object.assign(additionalFilers, values);
                }
            }
        }
    }
    Object.keys(additionalFilers).forEach(name => {
        if (filterValues[name] && filterValues[name].length > 0) {
            filterValues[name] = additionalFilers[name].filter(item => filterValues[name].includes(item));
        }
        else {
            filterValues[name] = additionalFilers[name];
        }
    });

    return filterValues;
}
export async function actionHandlerCallback(actionType, ids) {
    const actionHandlerCallback = this?.props?.actionHandlerCallback;
    let find = this?.state?.content?.rows?.find(item => item.columns.Id === ids[0]);
    if (actionHandlerCallback) actionHandlerCallback(actionType, ids);
    else {
        switch (actionType) {
            case constants.actionTypes.sign:
                let attachmentTypes;
                let files = [];
                for (var i = 0; i < ids.length; i++) {
                    const response = await fetch(`document/${ids[i]}`, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.ok) {
                        let data = await response.json();
                        let properties = JSON.parse(data.properties);
                        Object.keys(properties).map((key) => {
                            properties["prop_" + key.replaceAll(":", "_").replace("-", "_")] = properties[key];
                        });
                        properties.attachmentTypes = attachmentTypes;
                        let { type, attachments } = data;
                        const { prop_cm_title, prop_sys_node_uuid } = properties;
                        files.push({ id: prop_sys_node_uuid, title: prop_cm_title, type: type, isMain: true });
                        for (let j = 0; j < attachments?.length && attachmentTypes.length > 0; ++j) {
                            let { state, id } = attachments[j].properties;
                            if (state === constants.attachmentStatuses.New || state === constants.attachmentStatuses.Signed) {
                                //let configuration = counterpartyAccreditationCard.getAttachmentConfiguration(attachments[j].properties, properties);
                                //if (configuration && configuration.portalMandatorySigning) {
                                //    files.push({ id: id });
                                //}
                            }
                        }
                    }
                    else return require('../ModalDialog').unknownErrorMessageDialog();
                }
                let handlerResult = function (isSuccessResult = true) {
                    let message = files.length === 1 ? i18next.t("documentSignedSuccessfully") : i18next.t("documentsSignedSuccessfully");
                    if (!isSuccessResult) message = i18next.t("documentSignedError");
                    ModalDialog.showModalDialog('modalDialog', isSuccessResult ? i18next.t("done") : i18next.t("error"), "", <div className="info-message">{message}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => ModalDialog.hideModalDialog('modalDialog'), class: "btn-primary" }]);
                }
                if (files.length > 0) DocumentHelpers.signFiles(files, {}, handlerResult);
                break;
            case constants.actionTypes.download: {
                const results = await Promise.all(ids.map((id) => DocumentHelpers.downloadFile(id, find?.columns?.Name)));
                if (!results.some(r => r === true)) {
                    ModalDialog.showModalDialog("modalDialog", i18next.t("loadingDocuments"), "",
                        <div className="info-message">{i18next.t("loadedDocumentNotFound")}</div>,
                        "feedPopup", true,
                        [{ title: i18next.t("ok"), clickHandler: () => ModalDialog.hideModalDialog('modalDialog'), class: "btn-primary" }]);
                }
                return;
            }
            case constants.actionTypes.previewMainDocument: return require('../ModalDialog').viewPdfDialog(ids[0]);
            case constants.actionTypes.preview: return History.push(`/content/${ids[0]}`);
            case constants.actionTypes.remove:
                let currentObject = this;
                let confirmDialog = async function () {
                    let promises = [];
                    for (let i = 0; i < ids.length; ++i) {
                        promises.push(DocumentHelpers.deleteDocument(ids[i]));
                    }
                    await Promise.all(promises);
                    ModalDialog.hideModalDialog('modalDialog');
                    currentObject.handleReloadData();
                }
                ModalDialog.showModalDialog("modalDialog", i18next.t("removing"), "",
                    <div className="info-message">{i18next.t("removeDocumentsMessage")}</div>,
                    "feedPopup", true,
                    [
                        { title: i18next.t("yes"), clickHandler: confirmDialog, class: "btn-primary" },
                        { title: i18next.t("no"), clickHandler: () => ModalDialog.hideModalDialog('modalDialog'), class: "btn-secondary" }
                    ]);

                return;
        }
    }
    await this.actionHandlerCallbackDefault(actionType, ids);
}
export function renderContent() {
    const { type, withTabs, actionsForAll, title } = this.props;
    const { content, page, pageSize, pageCount, sortBy, isDesk, menuItems, filterValues, selectedInboxNumber } = this.state;
    let index = 0;
    let inBoxIndex = 0;
    let selectedMenuItem = this.getSelectedMenuItem();
    let allowedEnumValues = this.getAllowedEnumValues();
    let pageTitle = title;
    if (selectedMenuItem?.title)
        pageTitle = selectedMenuItem.title;
    return (
        <>
            {this.props.title && <h1>{pageTitle}{type === "" && <span className="title-counter">{content?.allCount ? content?.allCount : 0}</span>}</h1>}
            {withTabs && <div className="tabsMobile">
                    <ul className="tabs">
                            {menuItems?.filter(item => !item.hiddenForTabs)?.map(item =>
                                <li className={item === selectedMenuItem ? "active" : ""} key={item.name ? item.name + '_' + index++ : index++} onClick={() => this.inboxNavigate(0)}>
                                    {item === selectedMenuItem ?
                                        <div className="nav-link">{item.link?.text}</div>
                                        :
                                        <NavLink tag={Link} to={item.link?.url}>{item.link?.text}</NavLink>
                                    }
                                </li>
                            )}
                    </ul>
                </div>}
            <div className="docs-page-block">
                {selectedMenuItem && selectedMenuItem.inBoxes && selectedMenuItem.inBoxes.length > 0 &&
                    <div className="inboxes">
                        <ul>
                            {selectedMenuItem.inBoxes.map((item, index) =>
                                <li className={inBoxIndex === selectedInboxNumber ? "active" : ""} key={item.name ? item.name + '_' + inBoxIndex++ : inBoxIndex++}
                                    onClick={() => this.inboxNavigate(index)}>
                                    {item.title} {item.counter && !item.counter.hidden && false ? <span className={"left-menu-counter" + (index === selectedInboxNumber ? " selected" : "")}>{item.counter?.count}</span> : ""}
                                </li>
                            )}
                        </ul>
                    </div>
                }
                {getCurrentUrlPath() !== "/" &&
                    <TableFilterBlock allowedEnumValues={allowedEnumValues} filterValues={filterValues} onColumnsUpdateHandler={this.onColumnsUpdateHandler} exportFileName="documents" onSearch={this.onSearch} onSearchClear={this.onSearchClear} columnsSettings={{ columns: (content && content.allColumns) ? content.allColumns : [], selected: (content && content.columns) ? getArrayIds(content.columns) : [] }} items={content ? content.rows : []} getItems={this.getItemsForExport} handleUpdate={this.handleReloadData} />
                }
                {content && content.columns &&
                    <Table allowedEnumValues={allowedEnumValues} onColumnFilter={this.onColumnFilter} allCount={content.allCount} allowDoubleClickAction={true} handleSortChange={this.handleSortChange} actionHandlerCallback={this.actionHandlerCallback} sortBy={sortBy} isDesk={isDesk} columns={content.columns} items={content.rows} showMoreLink={getCurrentUrlPath() === "/"} showPagination={getCurrentUrlPath() !== "/"} page={page} pageSize={pageSize} pageCount={pageCount} handlePageChange={this.handlePageChange} handleChangePageSize={this.handleChangePageSize} actionsForAll={actionsForAll ? actionsForAll.concat(content.actionsForAll) : content.actionsForAll} />
                }
            </div>
        </>
    );
}
export function getSettingsValue(menuItems, name) {
    if ((!menuItems ? menuItems = this.state.menuItems : menuItems).length > 0) {
        var index = menuItems.findIndex(item => item.link?.url?.toLocaleLowerCase() === getCurrentUrlPath()?.toLocaleLowerCase());
        if (index >= 0 && menuItems[index]?.counter?.settings[name]?.length > 0)
            return menuItems[index].counter.settings[name];
    }
    return [];
}
export async function loadDocuments(sortBy = '', isDesk = false, filterValues = [], menuItems = [], page = null, pageSize = null, inbox = -1) {
    const { type, withAction } = this.props;
    inbox = inbox ?? this.state.selectedInboxNumber;
    var allowedValues = this.getAllowedEnumValues();
    let getUpdatedFilterValues = (values = [], key = "") => {
        if (allowedValues[key]?.length > 0) {
            if (values.length === 1 && values[0] === "*")
                return allowedValues[key];
            else
                return values.filter(item => allowedValues[key].includes(item));
        }
    }

    let requestFilterValues = Object.assign({}, filterValues);
    requestFilterValues = this.getFilterValuesFromMenu(requestFilterValues, menuItems);

    const statusFilters = [{ name: "Status" }]
        .map(item => ({ name: item.name, values: (requestFilterValues[item.name]?.length > 0 ? requestFilterValues[item.name] : this.getSettingsValue(menuItems, item.name)) }))
        .map(item => {
            if (item.values?.length === 0)
                item.values = ["*"];
            return item;
        })
        .map(item => ({ name: item.name, values: getUpdatedFilterValues(item.values, item.name) ?? item.values }))
        .map(item => {
            if (item.values?.length > 0)
                item.values = item.values.filter(v => v !== "*");
            return item;
        })
        .filter(item => item.values?.length > 0);

    statusFilters.forEach(item => {
        requestFilterValues[item.name] = item.values;
    });

    if (!requestFilterValues['SearchType'] || requestFilterValues['SearchType'].length === 0)
        requestFilterValues['SearchType'] = ['sx:Contract,sx:universalContract,sx:score'];

    await ListHelpers.loadListData.bind(this)(`Table/Get?searchTableTypes=${type}${withAction !== undefined ? `&withAction=${withAction}` : ""}`,
        page ?? this.state.page, pageSize ?? this.state.pageSize, sortBy, isDesk, requestFilterValues);
    this.setState({ menuItems: menuItems });
}
export async function loadData(page = null, pageSize = null, sortBy = '', isDesk = false, filterValues = []) {
    const { withTabs, additionalFilter } = this.props;
    filterValues = additionalFilter ? Object.assign(JSON.parse(JSON.stringify(additionalFilter)), filterValues) : filterValues;
    const component = this;
    await fetch(`home/navigation?tabUrl=${withTabs ? window.location.pathname : ""}&countInBoxes=${true}`).then(async (response) => {
        if (!response.ok) require('../ModalDialog').unknownErrorMessageDialog();
        else await loadDocuments.bind(component)(sortBy, isDesk, filterValues, (await response.json())?.Menu?.menuItems, page, pageSize);
    });
}