import React from 'react';
import i18next from "../../i18n";
export class TextAreaInput extends React.Component {
    constructor(props) {
        super(props);
        const { value, maxLen } = this.props;
        this.state = {
            value: value,
            maxLen: maxLen,
            leftCount: maxLen ? Math.max(0, maxLen - value?.length) : 0,
            id: crypto.randomUUID()
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidUpdate() {
        if (this.props.value !== this.state.value || this.props.maxLen !== this.state.maxLen) {
            this.setState({
                value: this.props.value,
                maxLen: this.props.maxLen,
                leftCount: this.props.maxLen ? Math.max(0, this.props.maxLen - this.props.value?.length) : 0
            });
        }
    }
    handleChange(e) {
        let { value } = e.target;
        const { maxLen } = this.state;
        if (maxLen >= 0 && value.length > maxLen) {
            value = value.substring(0, maxLen);
            e.target.value = value;
        }

        this.setState({
            value: value,
            leftCount: maxLen ? Math.max(0, maxLen - value?.length) : 0,
        });
        if (this.props.onChange)
            this.props.onChange(e);
    }
    render() {
        const { value, maxLen, leftCount } = this.state;
        const { name, className, disabled, rows } = this.props;
        return (
            <div className="text-area-input">
                <textarea
                    name={name}
                    className={className}
                    value={value}
                    rows={rows}
                    onChange={this.handleChange}
                    disabled={disabled ? "disabled" : ""}
                />
                {!disabled && maxLen >= 0 && <span className="left-counter">{i18next.t("leftSymbolsLabel").replace("{0}", leftCount)}</span>}
            </div>
        );
    }
}