import React,{Component} from 'react';
import i18next from "../../i18n";
import {UserContext} from '../../constants';
export class CommentForm extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = this.props.comment ? Object.assign({
                submitted: false,
                commentBeanList: [],
                edit: this.props.comment ? true : false
        }, ...Object.keys(this.props.comment).map((key) => ({ [key]: this.props.comment[key] }))
        ) : { submitted: false };
    }
    render() {
        const { content, submitted, edit } = this.state;
        return (
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="required">{i18next.t("comment")}</label>
                                    <textarea onChange={(e) => this.setState({ content: e.target.value })} value={content} rows="4" className="form-control"></textarea>
                                    {submitted && !content ? <div className="help-block">{i18next.t("requiredField")}</div> : ""}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary" onClick={this.handleChangeComment.bind(this)}>{edit ? i18next.t("save") : i18next.t("add")}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => require('../ModalDialog').hideModalDialog('modalDialog')}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    handleChangeComment(e) {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem('user'));
        const dateTime = new Date().toUTCString();
        const { id, content, commentBeanList } = this.state;
        if (content) {
            this.props.handleChangeComment({
                id: id ? id : require('uuid').v4(),
                content: content,
                created: dateTime,
                position: user.position,
                commentBeanList: commentBeanList
            });
        }
        else {
            this.setState({ submitted: true });
        }
    }
}