import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "i18next";
require('react-dom').render(
    <BrowserRouter basename={document.getElementsByTagName('base')[0].getAttribute('href')}>
        <App />
    </BrowserRouter>,
    document.getElementById('root'));