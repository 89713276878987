import React from 'react';
import * as  ModalDialog from '../ModalDialog';
import i18next from "../../i18n";
import { leftMenuTypes } from '../../constants';
import { FeedbackForm } from '../FeedbackForm';
export function CopyrightItem(props) {
    const renderClickHeandler = (item) => {
        let { name } = item;
        switch (name) {
            case 'feedbackLink':
                return (() => ModalDialog.showModalDialog("loadingDialog", i18next.t("feedback"), "", <FeedbackForm onCloseHandler={() => { ModalDialog.hideModalDialog("loadingDialog"); }} />, "feedPopup"));
        }
        return (() => { });
    }
    const { link, image, type } = props;
    switch (type) {
        case leftMenuTypes.divider: return (<li className={"menu-line"}></li>);
        case leftMenuTypes.link:
            return (
                <li className={"left-menu-item-link"}>
                    {link?.url ?
                        <a href={link.url} onClick={renderClickHeandler(props)} target={link.target ?? "_blank"}>{link.text}</a> :
                        <a onClick={renderClickHeandler(props)}>{link?.text}</a>
                    }
                    {image?.path && <img className="main-menu-icone" alt="" src={image?.path} />}
                </li>
            );
    }
}