import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { getUrlParam, setUrlParam } from './helpers/HelperFunctions';
export class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.activeTab ? this.props.activeTab : "0",
            renderedIds: []
        };
    }
    componentWillReceiveProps(nextProps) {
        const { name } = this.props;
        if (nextProps.activeTab && nextProps.activeTab !== this.state.activeTab) {
            this.setState({ activeTab: nextProps.activeTab.toLowerCase() });
            if (name) setUrlParam(name, nextProps.activeTab);
        }
    }
    componentWillUpdate() {
        const { name } = this.props;
        const { activeTab } = this.state;
        if (name) {
            let param = getUrlParam(name)?.toLowerCase();
            if (param && param !== activeTab)
                this.setActiveTab.bind(this)(name, param);
            this.state.activeTab = param ? param : "0";
        }
    }
    render() {
        const { tabs, name, isSub } = this.props;
        const { activeTab } = this.state;
        return (
            isSub === false || !isSub ?
                <>
                    <div className="tabsMobile">
                        <ul className="tabs">
                            {tabs.map((item, index) =>
                                <li key={index}
                                    className={(item?.name?.toLowerCase() === activeTab || index.toString() === activeTab) ? "active" : ""}
                                    style={{ display: item?.hidden === true ? "none" : "" }}
                                    onClick={() => this.setActiveTab.bind(this)(name, item?.name?.toLowerCase() ? item.name : index.toString())}>
                                    <NavLink>{item.label}</NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                    {this.props?.children?.map((item, index) =>
                        <div key={index} style={(tabs[index]?.name?.toLowerCase() === activeTab || index.toString() === activeTab) ? { display: "" } : { display: "none" }}>
                            {item.content ? item.content : (item ? item : "")}
                        </div>
                    ) ?? ""}
                </> :
                <>
                    <div className="inboxes">
                        <ul>
                            {tabs.map((item, index) =>
                                <li key={index} className={index.toString() === activeTab ? "active" : ""} onClick={() => this.setActiveTab.bind(this)('', index.toString())}>
                                    {item.label}
                                </li>
                            )}
                        </ul>
                    </div>
                    {this.props.children ? this.props.children.map((item, index) =>
                        <div key={index} style={(index.toString() === activeTab) ? { display: "" } : { display: "none" }}>
                            {item}
                        </div>
                    ) : ""}
                </>
        );
    }
    setActiveTab(name, activeTab) {
        activeTab = activeTab?.toLowerCase();
        this.state.activeTab = activeTab;
        this.setState({
            activeTab: activeTab
        });
        if (name) setUrlParam(name, activeTab);
        if (this.props.tabChangedCallback)
            this.props.tabChangedCallback(activeTab);
    }
}