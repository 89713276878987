import React from 'react';
import { statutoryDocTypes } from '../../constants';
import i18next from "../../i18n";
import { StatutoryDocsForm } from '../forms/StatutoryDocsForm';
export function AnnouncementDocumentsPage(props) {
    return (
        <div className="content">
            <h1>{i18next.t("announcementDocuments")}</h1>
            <StatutoryDocsForm baseUrl="/announcements" key={statutoryDocTypes.announcement} docType={statutoryDocTypes.announcement} />
        </div>
    );
}