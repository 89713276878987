import React,{Component} from 'react';
import i18next from "../../../i18n";
import { DateTimeInput } from '../../forms/DateTimeInput';
import { padWithZeroes } from '../../form/Form';
import * as ModalDialog from '../../ModalDialog';
import * as HelperFunctions from '../../helpers/HelperFunctions';
import { attachmentStatuses } from '../../../constants';
export class AttachmentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false
        }
        let keys = Object.keys(this.props);
        for (let i = 0; i < keys.length; ++i) this.state[keys[i]] = this.props[keys[i]];
        this.state.documentDate = this.props.documentDate ? this.getDate(this.props.documentDate) : undefined;
        this.state.documentType = this.state.documentType ?? (this.props.typesOfDocumens[0]?.value ?? "");
        this.changeInputValue = this.changeInputValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onSubmit() {
        const { documentType, title } = this.state;
        if (documentType && title) require('../AdditionalDocument').handleSubmit.bind(this)();
        else this.setState({ submitted: true });
    }
    render() {
        const { documentType, title, documentNumber, documentDate, comment, submitted, state, nodeRef, typesOfDocumens } = this.state;
        return (
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="required">{i18next.t("documentType")}</label>
                                    <select name="documentType" className="form-control" value={documentType} onChange={this.changeInputValue}>
                                        {typesOfDocumens.map(item =>
                                            <option key={item.value} value={item.value}>{item.title}</option>
                                        )}
                                    </select>
                                    {submitted&&!documentType? <div className="help-block">{i18next.t("requiredField")}</div>:""}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{i18next.t("documentNumber")}</label>
                                    <input name="documentNumber" className="form-control" value={documentNumber} onChange={this.changeInputValue} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{i18next.t("documentDate")}</label>
                                    <DateTimeInput className="form-control" onlyDate={true} name="documentDate" value={documentDate} onChange={this.changeInputValue} />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>{i18next.t("comment")}</label>
                                    <textarea name="comment" rows="4" className="form-control" onChange={this.changeInputValue} value={comment}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    {title?
                                        <label className="upload-file-label">{title}<span className="close-btn" onClick={(e) => this.setState({file: null,title: ""})}></span></label>
                                        :
                                        <>
                                            <label className={"upload-file-label upload-file-btn"} onClick={() => document.getElementById("file-iput-controll").click()}>{i18next.t("addFile")}</label>
                                            <input className="file-select" name="file" id="file-iput-controll" onChange={this.changeInputValue} type="file" style={{display: "none"}} />
                                        </>
                                    }
                                    {submitted&&!title? <div className="help-block">{i18next.t("requiredField")}</div>:""}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        <button type="button" className="btn btn-primary" onClick={this.onSubmit}>{state === attachmentStatuses.noDoc || !nodeRef ? i18next.t("add") : i18next.t("save")}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => ModalDialog.hideModalDialog('modalDialog')}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    changeInputValue(e) {
        const {files}=e.target;
        if(files) {
            this.setState({
                badExtension: false,
                file: e.target.files[0],
                title: e.target.files[0].name
            });
        }
        else HelperFunctions.changeInputValue.bind(this)(e);
    }
    getDate(value) {
        const date = new Date(value);
        return `${date.getFullYear()}-${padWithZeroes(date.getMonth() + 1, 2)}-${padWithZeroes(date.getDate(), 2)}`;
    }
}