import React, { Component } from 'react';
import { actionTypes, attachmentStatuses, alfrescoTypes } from '../../../constants';
import { CommentForm } from '../../comments/CommentForm';
import * as DocumentHelpers from '../../documents/DocumentHelpers';
import { dispatcherActionTypes, globalEventDispatcher } from '../../helpers/GlobalEventDispatcher';
import { showModalDialog, hideModalDialog } from '../../ModalDialog';
import { Table } from '../../table/Table';
import { TableFilterBlock } from '../../table/TableFilterBlock';
import { AttachmentForm } from './AttachmentForm';
import { clientSettings } from '../../../settings/clientSettings';
import { dynamicFormHandler } from '../../documents/DynamicForm';
import * as AdditionalDocument from '../AdditionalDocument';
import i18next from "../../../i18n"
const settings = clientSettings.table.AdditionalDocument;
for (let i = 0; i < settings.actions.length; ++i)
    settings.actions[i].sortIndex = i;
for (let i = 0; i < settings.columns.length; ++i)
    settings.columns[i].id = i.toString();
export class AttachmentsForm extends Component {
    constructor(props) {
        super(props);
        const { attachments, typesOfDocumens } = this.props;
        this.state = {
            attachments: attachments,
            typesOfDocumens: typesOfDocumens,
            statuses: [],
            filtersValues: {},
            rows: [],
            type: alfrescoTypes.additionalDocument,
            columns: JSON.parse(JSON.stringify(settings.columns))
        };
        this.actionHandlerCallback = this.actionHandlerCallback.bind(this);
        this.onColumnFilter = this.onColumnFilter.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.attachments !== this.state.attachments) {
            this.setState({ attachments: nextProps.attachments });
        }
        if (nextProps.typesOfDocumens !== this.state.typesOfDocumens) {
            this.setState({ typesOfDocumens: nextProps.typesOfDocumens });
        }
    }
    async actionHandlerCallback(type, ids = []) {
        let { attachments } = this.state;
        let finded = this.state.attachments.find(a => a.nodeRef === ids[0] || a.Id === ids[0]);
        const { typesOfDocumens } = this.state;
        switch (type) {
            case actionTypes.remove: {
                let closeDialog = function () {
                    hideModalDialog('modalDialog');
                }
                let confirmDialog = function () {
                    closeDialog();
                    dynamicFormHandler('additionalFiles', type, ids);
                }
                showModalDialog('modalDialog', i18next.t("removing"), "", <div className="info-message">{i18next.t("deleteFilesMessage")}</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: confirmDialog, class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                break;
            }
            case actionTypes.preview: return require('../../ModalDialog').viewPdfDialog(ids[0]);
            case actionTypes.download: return ids.map(item => DocumentHelpers.downloadFile(item));
            case actionTypes.add:
                if (finded.title)
                    finded.title = '';
                return showModalDialog('modalDialog', i18next.t("addDocument"), "", <AttachmentForm {...finded} typesOfDocumens={typesOfDocumens} />, "feedPopup middle-form");
            case actionTypes.edit: return showModalDialog('modalDialog', i18next.t("editDocument"), "", <AttachmentForm {...finded} typesOfDocumens={typesOfDocumens} />, "feedPopup middle-form");
            case actionTypes.notAdd:
                let handleChangeComment = function (comment) {
                    let item = Object.assign(attachments.find((item) => item.Id === ids[0]));
                    item.comment = comment.content;
                    item.state = attachmentStatuses.noDoc;
                    AdditionalDocument.handleSubmit.bind({ state: item })();
                }
                showModalDialog('modalDialog', i18next.t("addComment"), "", <CommentForm handleChangeComment={handleChangeComment} />, "feedPopup middle-form");
                break;
        }
    }
    onColumnFilter(colName, filterValues) {
        const { filtersValues, columns } = this.state;
        filtersValues[colName] = filterValues;
        columns.find((item) => item.name === colName).filteredValues = filterValues;
        this.setState({
            filtersValues: filtersValues
        });
        globalEventDispatcher.dispatch(dispatcherActionTypes.onUnselectTableAllRows);
    }
    onSearchClear() {
        const { columns } = this.state;
        for (let i = 0; i < columns?.length; ++i) columns[i].filteredValues = [];
        this.setState({
            filtersValues: {}
        });
    }
    render() {
        const { edite } = this.props;
        const { attachments, columns, filtersValues } = this.state;
        let rows = [];
        const { actions } = settings;
        if (attachments) {
            for (let i = 0; i < attachments.length; ++i) {
                let currActions = actions.filter(item =>
                    (
                        edite &&
                        (
                            (item.type === actionTypes.edit && attachments[i]?.nodeRef && attachments[i].state !== attachmentStatuses.noDoc) ||
                            (item.type === actionTypes.remove && (attachments[i]?.nodeRef || attachments[i].state === attachmentStatuses.noDoc)) ||
                            (
                                (
                                    attachments[i].state === attachmentStatuses.noDoc ||
                                    (attachments[i].state === attachmentStatuses.NotAdded && !attachments[i]?.nodeRef)
                                ) &&
                                item.type === actionTypes.add
                            ) ||
                            (attachments[i].state === attachmentStatuses.NotAdded && !attachments[i]?.nodeRef && item.type === actionTypes.notAdd)
                        )
                    ) ||
                    (
                        attachments[i].state === attachmentStatuses.Signed &&
                        item.type === actionTypes.loadSigns
                    ) ||
                    (
                        attachments[i]?.nodeRef &&
                        (
                            (item.type === actionTypes.preview && attachments[i]?.title?.endsWith('.pdf')) ||
                            (item.type === actionTypes.download && attachments[i].state !== attachmentStatuses.noDoc)
                        )
                    )
                );
                let item = { actions: currActions, columns: attachments[i] };
                item.columns.Id = attachments[i]?.nodeRef ?? i.toString();
                item.columns.Indicator = this.props.getAttachmentIndicatorHandler(attachments[i]);
                rows = this.props.getAttachmentIndicatorHandler(attachments[i]) ? [item].concat(rows) : rows.concat(item);
            }
        }
        rows.sort((a, b) => {
            if (a.columns.nodeRef && !b.columns.nodeRef)
                return -1;
            else if (!a.columns.nodeRef && b.columns.nodeRef)
                return 1;
            else if (a.columns.prop_sx_addDocumentType_label && b.columns.prop_sx_addDocumentType_label)
                return a.columns.prop_sx_addDocumentType_label.localeCompare(b.columns.prop_sx_addDocumentType_label);
            else
                return 0;
        });
        return (
            <>
                <TableFilterBlock hideActions={true} filterValues={filtersValues} columnsSettings={{ columns: columns }} items={rows} onSearchClear={this.onSearchClear} />
                <Table onColumnFilter={this.onColumnFilter} allowDoubleClickAction={true} actionHandlerCallback={this.actionHandlerCallback} columns={columns} items={rows} sortBy="Name" isDesk={false} />
            </>
        );
    }
}