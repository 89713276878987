import React from 'react';
export class AccountFormHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentCompanyName: '' } 
    }
    componentDidMount() {
        localStorage.removeItem('user');
        require('../helpers/HelperFunctions').getCurrentCompanyInfo().then((info) => { this.setState({ currentCompanyName: info?.name }); })
    }
    render() {
        const { currentCompanyName } = this.state;
        const { className } = this.props;
        return (<div className="form-header">
            <div className={className}>{currentCompanyName}</div>
        </div>);
    }
}