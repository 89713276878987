import { actionTypes, formModes } from '../constants';
import i18n from "../i18n";
export default {
    "columns": [
        {
            "name": "Id",
            "type": "string",
            "hidden": true
        },
        {
            "name": "FileName",
            "headerName": i18n.t("documentName"),
            "type": "string"
        }
    ],
    actions: [
        {
            "title": i18n.t("view"),
            "btnClass": "btn-primary",
            "type": actionTypes.previewMainDocument,
            "sortIndex": 0,
            "isMultiaction": false,
            "modes": [formModes.all]
        },
        {
            "title": i18n.t("download"),
            "btnClass": "btn-secondary",
            type: actionTypes.download,
            "sortIndex": 1,
            "isMultiaction": true,
            "modes": [formModes.all]
        }
    ]
}