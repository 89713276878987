import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { UserContext } from '../../constants';
import i18next from "../../i18n";
import { checkPassword, checkPhone } from '../helpers/CheckFunctions';
import { dispatcherActionTypes, globalEventDispatcher } from '../helpers/GlobalEventDispatcher';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
import { PasswordInput } from './PasswordInput';
export class EditUserForm extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            password: '',
            passwordRepeat: '',
            position: '',
            sending: false,
            submitted: false,
            canEditRole: false,
            canEditStatus: false,
            role: '',
            statusValue: '',
            rolesList: [],
            statusList: [],
            incorrectLogin: false,
            showPassword: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const { role, sending, firstName, lastName, statusValue, position, phoneNumber, incorrectLogin, password, passwordRepeat, showPassword } = this.state;
        this.setState({ submitted: true });
        if (!sending && !incorrectLogin && role && statusValue && firstName && lastName && phoneNumber && checkPhone(phoneNumber)) {
            if (!showPassword || !password || (password === passwordRepeat && checkPassword(password))) {
                this.setState({ sending: true });
                const id = this.props.userId;
                this.editUser({
                    firstName,
                    lastName,
                    phoneNumber,
                    statusValue,
                    role,
                    password,
                    position,
                    id
                });
            }
        }
    }
    async editUser(data) {
        const currentObject = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch('/account/editUser', requestOptions)
            .then((response) => {
                if (response.ok) {
                    function onClose() {
                        hideModalDialog("loadingDialog");
                        if (currentObject.props.onSubmitedHandler)
                            currentObject.props.onSubmitedHandler();
                    }
                    if (JSON.parse(localStorage.getItem('user')).id === this.props.userId) {
                        fetch('account/getCurrentUser')
                            .then(async (response) => {
                                let data = response.ok ? await response.json() : { errorCode: response.status };
                                if (data.errorCode) {
                                    console.log('error during getting current user: code - ' + data.errorCode);
                                    showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("unknownErrorMessage")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }]);
                                }
                                else {
                                    let user = JSON.parse(localStorage.getItem('user'));
                                    user.userName = data.fullName;
                                    localStorage.setItem('user', JSON.stringify(user));
                                    globalEventDispatcher.dispatch(dispatcherActionTypes.onCurrentUserUpdated);
                                    currentObject.setState({ sending: false });
                                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">Профіль користувача успішно оновлено</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                                }
                            });
                    }
                    else {
                        currentObject.setState({ sending: false });
                        showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">Профіль користувача успішно оновлено</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                    }
                }
                else {
                    return response.text().then((errorText) => {
                        console.log(errorText);
                        require('../ModalDialog').unknownErrorMessageDialog();
                    });
                }
            });
    }
    componentDidMount() {
        this.fillForm();
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    render() {
        const { email, sending, submitted, role, canEditRole, canEditStatus, rolesList, firstName, lastName, phoneNumber, position, statusValue, statusList, incorrectLogin, showPassword, password, passwordRepeat } = this.state;
        let isCurrent = JSON.parse(localStorage.getItem('user')).id === this.props.userId;
        return (<form name="form" className="popupSimple user-invite-form" onSubmit={this.handleSubmit}>
            <div className="row">
                <div className={'form-group col-sm-6 col-xs-6' + (submitted && !lastName ? ' has-error' : '')}>
                    <label htmlFor="lastName" className="required">{i18next.t("lastName")}</label>
                    <input className="form-control" name="lastName" value={lastName} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                    {submitted && !lastName && <div className="help-block">{i18next.t("requiredField")}</div>}
                </div>
                <div className={'form-group col-sm-6 col-xs-6' + (submitted && !firstName ? ' has-error' : '')}>
                    <label htmlFor="firstName" className="required">{i18next.t("firstNameAndMiddleName")}</label>
                    <input className="form-control" name="firstName" value={firstName} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                    {submitted && !firstName && <div className="help-block">{i18next.t("requiredField")}</div>}
                </div>
                <div className="form-group col-sm-12 col-xs-12">
                    <label htmlFor="position">{i18next.t("positionHeld")}</label>
                    <input className="form-control" name="position" value={position} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                </div>
                <div className={'form-group col-sm-6 col-xs-6' + (submitted && !phoneNumber ? ' has-error' : '')}>
                    <label htmlFor="phoneNumber" className="required">{i18next.t("phone")}</label>
                    <InputMask type="tel" className="form-control" name="phoneNumber" mask='+380999999999' maskChar={null} value={phoneNumber} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                    {submitted && !phoneNumber &&
                        <div className="help-block">{i18next.t("requiredField")}</div>
                    }
                    {submitted && phoneNumber && !checkPhone(phoneNumber) &&
                        <div className="help-block">{i18next.t("badFieldFormat")}</div>
                    }
                </div>
                <div className={'form-group col-sm-6 col-xs-6' + (submitted && !email ? ' has-error' : '')}>
                    <label htmlFor="email">{i18next.t("email")}</label>
                    <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} disabled="disabled" />
                </div>
                <div className={"form-group col-sm-6 col-xs-6" + (submitted && !statusValue ? ' has-error' : '')}>
                    <label htmlFor="status" className="required">Статус</label>
                    <select name="statusValue" className="form-control" value={statusValue} onChange={this.handleChange} disabled={(sending || isCurrent || !canEditStatus ? 'disabled' : '')}>
                    {statusList && statusList.length > 0 && statusList.map(status =>
                                <option key={status.name} value={status.name} > {status.title}</option>
                            )}
                    </select>
                    {submitted && !statusValue &&
                        <div className="help-block">{i18next.t("requiredField")}</div>
                    }
                </div>
                <div className={"form-group col-sm-6 col-xs-6" + (submitted && !role ? ' has-error' : '')}>
                    <label htmlFor="role" className="required">{i18next.t("role")}</label>
                    <select name="role" className="form-control" value={role} onChange={this.handleChange} disabled={(sending || isCurrent || !canEditRole ? 'disabled' : '')}>
                    {rolesList && rolesList.length > 0 && rolesList.map(role =>
                                <option key={role.name} value={role.name} > {role.title}</option>
                            )}
                    </select>
                    {submitted && !role &&
                        <div className="help-block">{i18next.t("requiredField")}</div>
                    }
                </div>
                {showPassword ?
                    [
                        <div className={'form-group col-lg-6' + (submitted && !password ? ' has-error' : '')}>
                            <label htmlFor="password">{i18next.t("password")}</label>
                            <PasswordInput autocomplete="off" className="form-control" name="password" value={password} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                            {submitted && password && !checkPassword(password) &&
                                <div className="help-block">{i18next.t("incorrectPasswordErrorText")}</div>
                            }
                        </div>,
                        <div className={'form-group col-lg-6' + (submitted && !passwordRepeat ? ' has-error' : '')}>
                            <label htmlFor="passwordRepeat">{i18next.t("confirmPassword")}</label>
                            <PasswordInput autocomplete="off" className="form-control" name="passwordRepeat" value={passwordRepeat} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                            {submitted && password && passwordRepeat !== password &&
                                <div className="help-block">{i18next.t("passwordsDoNotMatch")}</div>
                            }
                        </div>] :
                    ""
                }
                <div className='form-group'>
                </div>
            </div>,
            <div className="form-group form-group__last">
                <button className="btn btn-primary">{i18next.t("save")}</button>
                <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                <div className="required-label">
                    <label className="required" />
                    <span className="btnDesk__info">{i18next.t("requiredFields")}</span>
                </div>
            </div>
        </form>);
    }
    async fillForm() {
        const requestOptions = { method: 'GET', headers: { 'Content-Language': 'en-US' } };
        const response = await fetch(`account/getUserForEdit?userId=${this.props.userId}`, requestOptions);
        if (response.ok) {
            const data = await response.json();
            let roleValue = data.user.role;
            if (!roleValue && data.userRoles && data.userRoles.length > 0)
                roleValue = data.userRoles[0].name;
            let statusValue = data.user.statusValue;
            if (!statusValue && data.userStatuses && data.userStatuses.length > 0)
                statusValue = data.userStatuses[0].name;
            let phoneValue = data.user.phoneNumber;
            if (phoneValue)
                phoneValue = phoneValue.replace(/[^+\d]/g, '');
            this.setState({ rolesList: data.userRoles, showPassword: data.allowPasswordEditing, canEditRole: data.allowRoleEditing, canEditStatus: data.allowStatusEditing, statusList: data.userStatuses, role: roleValue, statusValue: statusValue, firstName: data.user.firstName, lastName: data.user.lastName, position: data.user.position, phoneNumber: phoneValue, email: data.user.email });
        }
        else require('../ModalDialog').unknownErrorMessageDialog();
    }
}
