import React, { Component } from 'react';
import { acceptPricesInputFileString, allowedPricesFileExtensions, resources, urls } from '../../constants';
import i18next from "../../i18n";
import { hideModalDialog, showModalDialog, unknownErrorMessageDialog } from '../ModalDialog';
import { Svg } from '../Svg';
import { DateTimeInput } from './DateTimeInput';
export class PricesSettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: true,
            submitted: false,
            wasChanged: false,
            date: null,
            condition: '',
            file: null,
            badExtension: false,
            wrongFileFormat: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    handleFileRemove(event) {
        document.getElementById("file-iput-controll").value = "";
        this.setState({ file: null, badExtension: false, wrongFileFormat: false });
    }
    handleFileChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            var parts = event.target.files[0].name.split('.');
            if (parts.length > 1 && allowedPricesFileExtensions.findIndex(ex => ex === parts[parts.length - 1].toLowerCase()) > -1) {
                this.setState({
                    badExtension: false,
                    wrongFileFormat: false,
                    wasChanged: true,
                    file: event.target.files[0]
                });
            }
            else {
                this.setState({
                    badExtension: true,
                    wrongFileFormat: false,
                    file: event.target.files[0]
                });
            }
        }
        else {
            event.target.value = "";
            this.setState({
                badExtension: false,
                wrongFileFormat: false,
                file: null
            });
        }
    }
    async loadData() {
        document.getElementById("file-iput-controll").value = "";
        const response = await fetch('/home/getPricesInfo?onlyDate=true');
        if (!response.ok) {
            console.log(response.status);
            unknownErrorMessageDialog();
            return;
        }
        const data = await response.json();
        let dateValue = '';
        const date = data.date ? new Date(data.date) : null;
        if (date) {
            const month = date.getMonth() + 1 <= 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
            const day = date.getDate() + 1 <= 9 ? '0' + date.getDate() : date.getDate();
            dateValue = date ? date.getFullYear() + '-' + month + '-' + day : null;
        }
        this.setState({ wasChanged: false, sending: false, date: dateValue, condition: data.condition, submitted: false, file: null });
    }
    onCancel() {
        this.loadData();
    }
    componentDidMount() {
        this.loadData();
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            wasChanged: true
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const { sending, date, file, badExtension, wrongFileFormat, condition } = this.state;
        this.setState({ submitted: true });
        if (!sending && !badExtension && !wrongFileFormat && date && condition && file) {
            this.setState({ sending: true });
            this.submit();
        }
    }
    async submit() {
        const currentObject = this;
        const formData = new FormData();
        formData.append('date', this.state.date);
        formData.append('condition', this.state.condition);
        formData.append('files', this.state.file, this.state.file.name);
        const requestOptions = {
            method: 'POST',
            headers: { "Contetnt-Type": "multipart/form-data" },
            body: formData
        };
        function onClose() {
            hideModalDialog("loadingDialog");
            if (currentObject.props.onSubmitedHandler)
                currentObject.props.onSubmitedHandler();
            currentObject.loadData();
        }
        fetch('/admin/uploadPricesTable', requestOptions)
            .then((response) => {
                if (response.status === 403) {
                    window.location.href = urls.accessDeniedUrl;
                    return;
                }
                return response.ok ? response.text().then(() => {
                    currentObject.setState({ sending: false });
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">{i18next.t("pricesSuccessfullyUpdated")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: onClose, class: "btn-primary" }], onClose);
                }) : response.text().then((errorText) => {
                    console.log(errorText);
                    if (errorText === "BadExtension") {
                        this.setState({ badExtension: true, sending: false });
                    }
                    else if (errorText === "WrongFileFormat") {
                        this.setState({ wrongFileFormat: true, sending: false });
                    }
                    else {
                        showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("unknownErrorMessage")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: onClose, class: "btn-primary" }], onClose);
                    }
                });
            });
    }
    loadTemplate() {
        const requestOptions = {
            method: 'GET'
        };
        let downloadFunction = function (fileName) {
            fetch(`${resources.pricesTemplateFileName}`, requestOptions)
                .then(response => response.ok ? response.blob() : null)
                .then(blobby => {
                    if (!blobby) {
                        showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("fileNotFound")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
                    }
                    else {
                        let objectUrl = window.URL.createObjectURL(blobby);
                        let anchor = document.createElement("a");
                        anchor.href = objectUrl;
                        anchor.download = fileName;
                        anchor.click();
                        window.URL.revokeObjectURL(objectUrl);
                    }
                });
        }
        downloadFunction(resources.pricesTemplateFileName);
    }
    render() {
        const { file, sending, submitted, date, badExtension, condition, wrongFileFormat, wasChanged } = this.state;
        return (
            [<div className="filter-block-header" key="prices_settings_header">
                <div id="columnSettingsDialog"></div>
                <ul className="filter-header-buttons one-icons">
                    <li>
                        <button type="button" className="btn no-bg-btn" onClick={this.loadTemplate}>
                            <Svg src="/img/download.svg" />
                            <span>{i18next.t("downloadTemplate")}</span>
                        </button>
                    </li>
                </ul>
            </div>,
                <div className="form-block large-form settings-form" key="prices_settings_form">
                <form name="form" className="popupSimple file-upload-form" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className={'form-group col-sm-6 col-xs-6' + (submitted && !date ? ' has-error' : '')}>
                            <label htmlFor="date" className="required">{i18next.t("form.pricesSettings.pricesAsOf")}</label>
                            <DateTimeInput onlyDate={true} className="form-control" name="date" value={date} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                            {submitted && !date &&
                                <div className="help-block">{i18next.t("requiredField")}</div>
                            }
                        </div>
                        <div className={'form-group col-sm-12 col-xs-12' + (submitted && !condition ? ' has-error' : '')}>
                            <label htmlFor="condition" className="required">{i18next.t("form.pricesSettings.purchaseTerms")}</label>
                            <input className="form-control" name="condition" value={condition} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                            {submitted && !condition &&
                                <div className="help-block">{i18next.t("requiredField")}</div>
                            }
                        </div>
                        <div className="form-group col-sm-6 col-xs-6">
                            <label htmlFor="file" className="required">{i18next.t("importFile")}</label>
                            {file ?
                                <label className={"upload-file-label" + (sending ? " disabled" : "")}>{file.name} <span className="close-btn" onClick={this.handleFileRemove}></span></label>
                                :
                                <label className={"upload-file-label upload-file-btn" + (sending ? " disabled" : "")} onClick={() => document.getElementById("file-iput-controll").click()}>{i18next.t("addFile")}</label>
                            }
                            {submitted && !file && !badExtension &&
                                <div className="help-block">{i18next.t("requiredField")}</div>
                            }
                            {submitted && badExtension &&
                                <div className="help-block">{i18next.t("invalidFileFormatMessage", { extensions: allowedPricesFileExtensions.join(', ') })}</div>
                            }
                            {submitted && file && !badExtension && wrongFileFormat &&
                                <div className="help-block">{i18next.t("form.pricesSettings.wrongFileFormatMessage")}</div>
                            }
                            <input className="file-select" accept={acceptPricesInputFileString} name="file" id="file-iput-controll" style={{ display: "none" }} onChange={this.handleFileChange} type="file" />
                        </div>
                        {((submitted && badExtension) || (submitted && !file) || (submitted && wrongFileFormat)) &&
                            <div className='form-group col-sm-12 col-xs-12'>
                            </div>
                        }
                    </div>
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary">{i18next.t("save")}</button>
                        {wasChanged &&
                            <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        }
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                    </div>
                    </div>
                </form>
            </div>]
        );
    }
}
