import { TableColumnType } from '../components/table/Table'
import { actionTypes } from '../constants';
import i18next from "../i18n";
export default {
    "columns": [
        {
            "name": "Id",
            "headerName": "",
            "type": TableColumnType.String,
            "hidden": true
        },
        {
            "name": "Text",
            "headerName": i18next.t("linkTitle"),
            "type": TableColumnType.String
        },
        {
            "name": "Url",
            "headerName": i18next.t("linkUrl"),
            "type": TableColumnType.String
        },
        {
            "name": "OpenInNewTab",
            "headerName": i18next.t("openInNewTab"),
            "type": TableColumnType.Bool
        },
        {
            "name": "Icon",
            "headerName": i18next.t("icon"),
            "type": TableColumnType.Image
        },
        {
            "name": "Reorder",
            "headerName": i18next.t("reorderColumnHeader"),
            "type": TableColumnType.Reorder
        }
    ],
    actions: [
        {
            "title": i18next.t("edit"),
            "btnClass": "btn-secondary",
            "type": actionTypes.edit
        },
        {
            "title": i18next.t("remove"),
            "btnClass": "btn-no-fill",
            "type": actionTypes.remove,
            "isMultiaction": true
        }
    ]
}