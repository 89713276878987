export const prop_sx_cpSPresentDocs = {
    caProxy_not: 'caProxy_not',
    caProxy: 'caProxy'
}
export function buildFormItem(item) {
    const { prop_sx_cpSPresentDoc } = this.state;
    if (['prop_sx_cpSPresentDocDate', 'prop_sx_cpSPresentDocExpire'].indexOf(item.Name) !== -1) item.Required = prop_sx_cpSPresentDoc === prop_sx_cpSPresentDocs.caProxy;
    return item;
}
export function checkInputPage() {
    const { prop_sx_cpLastName, prop_sx_cpFirstName, prop_sx_cpSecondName, prop_sx_cpPosition, prop_sx_cpEmail, prop_sx_cpPhone, prop_sx_cpSPresentDoc, prop_sx_cpSPresentDocDate, prop_sx_cpSPresentDocExpire } = this.state;
    if (!(prop_sx_cpLastName && prop_sx_cpFirstName && prop_sx_cpSecondName && prop_sx_cpPosition && prop_sx_cpEmail && prop_sx_cpPhone
        && (prop_sx_cpSPresentDoc !== prop_sx_cpSPresentDocs.caProxy || (prop_sx_cpSPresentDocDate && prop_sx_cpSPresentDocExpire)))) {
        return false;
    }
    return true;
}