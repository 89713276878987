import { documentTabs } from './Document';
import i18n from '../../i18n';

export function buildFormItem(item) {
	const { prop_sx_dfState } = this.state;
	if (item.DisableWhile) {
		item.Disabled = item.DisableWhile.indexOf(prop_sx_dfState) !== -1;
	}
	return item;
}
export function checkInputPage() {
	const { prop_sx_AgreementNumber, prop_sx_InvoiceSum, associations, file } = this.state;
	const checkResult = { error: '', incorrectTab: documentTabs.information };
	if (!(prop_sx_AgreementNumber && prop_sx_InvoiceSum)) {
		checkResult.error = i18n.t("notAllRequiredFildsAreSpecifiedMessage");
	}
	else if (!(associations?.mainContent || file)) {
		checkResult.error = i18n.t("3");
	}
	return checkResult;
}