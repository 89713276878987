import React, { Component } from 'react';
import { Svg } from './Svg';
export class InfoBtnControl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id ?? crypto.randomUUID(),
			infoText: props.infoText,
			show: props.show
		};
		this.onClick = this.onClick.bind(this);
		this.onDocumentClick = this.onDocumentClick.bind(this);
		document.addEventListener('click', this.onDocumentClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onDocumentClick);
	}

	onDocumentClick(e) {
		if (this.state.show) {
			const info = document.getElementById(this.state.id);
			if (e.target !== info && !info.contains(e.target)) {
				this.setState({ show: false });
			}
		}
	}

	onClick(e) {
		const rootElement = document.getElementById(this.state.id);
		const close = rootElement.querySelector('.close');
		if (e.target === close || close?.contains(e.target)) {
			if (this.state.show) {
				this.setState({ show: false });
			}
		}
		else {
			const infoBlock = rootElement.querySelector('.info-block');
			if (!infoBlock || (e.target !== infoBlock && !infoBlock.contains(e.target))) {
				this.setState({ show: !this.state.show });
			}
		}
	}

	render() {
		const { show, infoText, id } = this.state;
		return <div className="info-btn" key={id} id={id} >
			<div className="info-btn-icon" onClick={this.onClick}>
				<Svg src="/img/info.svg" />
				{show &&
					<div className="info-block">
						<div className="arrow"></div>
						<div className="close">
							<svg width="8" height="8" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
							</svg>
						</div>
						{infoText}
					</div>}
			</div>
		</div>
	}
}