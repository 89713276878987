import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import i18next from "../../i18n";
import { checkEmail } from '../helpers/CheckFunctions';
import * as HelperFunctions from '../helpers/HelperFunctions';
import { AccountFormHeader } from './AccountFormHeader';
import * as AccountHelper from "./AccountHelper"
export class PasswordRecoveryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            submitted: false,
            passwordWasReset: false
        };
        this.resetPasswordRequest = this.resetPasswordRequest.bind(this);
        this.changeInputValue = HelperFunctions.changeInputValue.bind(this);
    }
    async resetPasswordRequest(e) {
        e.preventDefault();
        const { email } = this.state;
        this.setState({
            submitted: true
        });
        if (email/* && checkEmail(email)*/) {
            await fetch(`/account/resetPasswordByLogin`, { method: 'Post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ login: email }) })
                .then(res => {
                    if (!res.ok) return require('../ModalDialog').unknownErrorMessageDialog();
                    this.setState({
                        passwordWasReset: true
                    });
                });
        }
    }
    render() {
        const { submitted, email, passwordWasReset } = this.state;
        if (passwordWasReset) {
            return (<div className="form-page">
                <div className="form-block large-form">
                    <AccountFormHeader className="form-header-subtitle-right form-subtitle" />
                    <div className="form-content">
                        <div className="info-message-title">{i18next.t("done")}</div>
                        <div className="info-message">{i18next.t("passwordResetMessage")}</div>
                    </div>
                    <div className="form-group form-group__last">
                        <NavLink className="bottom-form-link message-form-link" tag={Link} to="/login" onClick={this.resetForm}>
                            {i18next.t("logInToYourAccount")} <div className="collapsed-menu-icone"></div>
                        </NavLink>
                    </div>
                </div>
            </div>);
        }
        return (<div className="form-page login-page">
            <div className="form-block login-form">
                <AccountFormHeader className="form-header-subtitle form-subtitle" />
                <div className="form-content">
                    <div className="form-title">{i18next.t("passwordRecovery")}</div>
                    <form name="form" onSubmit={this.resetPasswordRequest}>
                        <div className={'form-group required'}>
                            <label htmlFor="email" className="required">{i18next.t("21")}</label>
                            <input className="form-control" name="email" value={email} onChange={this.changeInputValue} />
                            {AccountHelper.isRequiredMessage(submitted && !email)}
                        </div>
                        <div className="form-group form-group__last">
                            <button className="btn btn-primary">{i18next.t("recoverPassword")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
    }
}