import React, { Component } from 'react';
import { allowedIconFileExtensions, acceptIconFileString } from '../../constants';
import i18next from "../../i18n";
export class EditLinkForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: props.linkId ? true : false,
            submitted: false,
            iconFile: null,
            badExtension: false,
            openInNewTab: false,
            linkId: props.linkId,
            iconFileName: '',
            linkTitle: '',
            linkUrl: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleIconFileChange = this.handleIconFileChange.bind(this);
        this.handleIconFileRemove = this.handleIconFileRemove.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    handleIconFileRemove() {
        document.getElementById("file-iput-controll").value = "";
        this.setState({ iconFile: null, iconFileName: '' });
    }
    handleIconFileChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            var parts = event.target.files[0].name.split('.');
            if (parts.length > 1 && allowedIconFileExtensions.findIndex(ex => ex === parts[parts.length - 1].toLowerCase()) > -1) {
                this.setState({
                    badExtension: false,
                    iconFile: event.target.files[0]
                });
            }
            else {
                this.setState({
                    badExtension: true,
                    iconFile: null
                });
            }
        }
        else {
            event.target.value = "";
            this.setState({
                badExtension: false,
                iconFile: null
            });
        }
    }
    async componentDidMount() {
        if (this.props.linkId) {
            await this.loadLink();
        }
    }
    async loadLink() {
        const response = await fetch(`/SiteSettings/GetMenuLinks`);
        if (response.ok) {
            const result = await response.json();
            if (result && result.data) {
                const { linkId } = this.state;
                let current = result.data.find(l => l.id.toString() === linkId);
                if (current) {
                    this.setState({
                        openInNewTab: current.target === "_blank",
                        iconFileName: i18next.t("iconEditText").replace("{0}", current.icon?.split('.').slice(-1)),
                        linkTitle: current.text,
                        linkUrl: current.url,
                        sending: false
                    });
                }
            }
        }
        else require('../ModalDialog').unknownErrorMessageDialog();
    }
    handleChange(event) {
        const { name, value, type, checked } = event.target;
        this.setState({
            [name]: type === "checkbox" ? checked : value
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const { sending, linkTitle, linkUrl, iconFile, badExtension, iconFileName } = this.state;
        if (!sending)
            this.setState({ submitted: true });
        if (!sending && !badExtension && linkTitle && linkUrl && (iconFile || iconFileName)) {
            this.setState({ sending: true });
            this.submit();
        }
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    async submit() {
        const { onSucces } = this.props;
        const { linkTitle, linkUrl, iconFile, openInNewTab, linkId } = this.state;
        const formData = new FormData();
        formData.append('Text', linkTitle);
        formData.append('Url', linkUrl);
        formData.append('Target', openInNewTab ? '_blank' : '_self');
        if (iconFile)
            formData.append('file', iconFile, iconFile.name);
        if (linkId)
            formData.append('Id', linkId);

        const requestOptions = {
            method: 'POST',
            headers: { "Contetnt-Type": "multipart/form-data" },
            body: formData
        };
        const url = linkId ? '/SiteSettings/EditMenuLink' : '/SiteSettings/AddMenuLink';
        fetch(url, requestOptions)
            .then((response) => {
                if (response.ok) {
                    if (onSucces)
                        onSucces();
                }
                else {
                    require('../ModalDialog').unknownErrorMessageDialog();
                    this.setState({ sending: true });
                }
            });
    }
    render() {
        const { linkTitle, linkUrl, iconFile, sending, submitted, badExtension, iconFileName, openInNewTab } = this.state;
        return (
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple link-edit-form">
                        <div className="row">
                            <div className={'form-group col-sm-12 col-xs-12' + (submitted && !linkTitle ? ' has-error' : '')}>
                                <label htmlFor="name" className="required">{i18next.t("linkTitle")}</label>
                                <input className="form-control" name="linkTitle" value={linkTitle} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                                {submitted && !linkTitle &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                            </div>
                            <div className={'form-group col-sm-12 col-xs-12' + (submitted && !linkUrl ? ' has-error' : '')}>
                                <label htmlFor="name" className="required">{i18next.t("linkUrl")}</label>
                                <input className="form-control" name="linkUrl" value={linkUrl} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                                {submitted && !linkUrl &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                            </div>
                            <div className="form-group col-sm-12 col-xs-12">
                                <input id="newTabInput" name="openInNewTab" className="form-control" type="checkbox" disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} checked={openInNewTab} />
                                <label htmlFor="newTabInput">{i18next.t("openInNewTab")}</label>
                            </div>
                            <div className="form-group col-sm-12 col-xs-12">
                                {iconFile ?
                                    <label className={"upload-file-label" + (sending ? " disabled" : "")}>{iconFile.name} <span className="close-btn" onClick={this.handleIconFileRemove}></span></label>
                                    :
                                    (
                                        iconFileName ?
                                            <label className={"upload-file-label" + (sending ? " disabled" : "")}>{iconFileName} <span className="close-btn" onClick={this.handleIconFileRemove}></span></label>
                                            :
                                            <label className={"upload-file-label upload-file-btn" + (sending ? " disabled" : "")} onClick={() => document.getElementById("file-iput-controll").click()}>{i18next.t("addIcon")}</label>
                                    )
                                }
                                {submitted && !iconFile && !iconFileName && !badExtension &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                                {submitted && badExtension &&
                                    <div className="help-block">{i18next.t("invalidFileFormatMessage", { extensions: allowedIconFileExtensions.join(' ') })}</div>
                                }
                                <input className="file-select" name="file" id="file-iput-controll" accept={acceptIconFileString} style={{ display: "none" }} onChange={this.handleIconFileChange} type="file" />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary" onClick={this.handleSubmit}>{i18next.t("save")}</button>
                        <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
