import React, { Component } from 'react';
import i18next from "../../i18n";
import { uint8ToBase64 } from '../helpers/HelperFunctions';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
const SIGN_WIDGET_PARENT_ID = "sign-widget-parent";
const SIGN_WIDGET_ID = "sign-widget";
const SIGN_WIDGET_URI = "https://eu.iit.com.ua/sign-widget/v20240301/";
export async function checkSertificates(docsIds = [], sertificates) {
    const { withoutStamp, withoutSign } = this.props;
    let { subjEDRPOUCode, subjDRFOCode, subjCN } = sertificates[0].infoEx;
    console.log("--- sertificates ---", sertificates);
    //for (let i = 0; i < docsIds.length; ++i) {
    //    let error = await fetch(`File/GetSignatures/${docsIds[i].split("/").pop()}`)
    //        .then(async (response) => {
    //            if (!response.ok) require('../ModalDialog').unknownErrorMessageDialog();
    //            else {
    //                let data = await response.json();
    //                let signatures = data.signatures.filter((signature) => signature.state === signatureStates.signed);
    //                if (!subjDRFOCode && signatures.length === 0) {
    //                    return { error: i18next.t("2"), keyLoaded: false };
    //                }
    //                else if (withoutStamp) {
    //                    if (!subjDRFOCode) return { error: i18next.t("1"), keyLoaded: false };
    //                }
    //                else if (withoutSign) {
    //                    if (!subjEDRPOUCode || subjEDRPOUCode && subjDRFOCode) return { error: i18next.t("0"), keyLoaded: false };
    //                }
    //            }
    //        });
    //    if (error) return error;
    //}
    return { keyLoaded: true, error: "" };
}
export class SignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            badExtension: false,
            sending: false,
            submitted: false,
            keyLoaded: false,
            files: this.props.files,
            euSign: null
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSign = this.onSign.bind(this);
        this.signFile = this.signFile.bind(this);
    }
    componentDidMount() {
        const { files } = this.state;
        let euSign = new window.EndUser(
            SIGN_WIDGET_PARENT_ID,
            SIGN_WIDGET_ID,
            SIGN_WIDGET_URI,
            window.EndUser.FormType.ReadPKey
        );
        let currentObject = this;
        euSign.ReadPrivateKey()
            .then(async (sertificates) => {
                await checkSertificates.bind(currentObject)(files.map((item) => item.id), sertificates).then((data) => {
                    if (data?.error) {
                        let handlerClose = () => {
                            if (currentObject.props.handlerRestart)
                                currentObject.props.handlerRestart(false);
                            hideModalDialog('modalDialog')
                        };
                        showModalDialog('modalDialog', i18next.t("error"), "", <div className="info-message">{data.error}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: handlerClose, class: "btn-primary" }], handlerClose);
                    }
                    else {
                        currentObject.setState({ error: data?.error, keyLoaded: data?.keyLoaded, sending: data?.sending });
                    }
                });
                currentObject.setState({ euSign: euSign });
            })
        this.setState({ euSign: euSign });
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    async signFile(value) {
        let currentObject = this;
        let result = this.state.euSign.SignHash(value, false,
            window.EndUser.SignAlgo.DSTU4145WithGOST34311, window.EndUser.SignType.CAdES_X_Long,null)
            .then(function (sign) {
                currentObject.setState({ error: '', keyLoaded: true, sending: false });
                let result = uint8ToBase64(sign);
                console.log('--- sign hash ---', result);
                return result;
            });
        return result;
    }
    async onSign(e) {
        e.preventDefault();
        if (!this.state.sending) {
            this.setState({ sending: true });
            let { files } = this.state;
            let signs = [];
            for (var i = 0; i < files.length; i++) {
                let file = await this.signFile(files[i].value);
                signs.push({ id: files[i].id, value: file });
            }
            let result = this.props?.onSignHandler(signs);
        }
    }
    render() {
        const { keyLoaded, error } = this.state;
        return (
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple file-sign-form">
                        <div className="keyErrorDialog"></div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-xs-12">
                                {/* Батківський елемент для відображення iframe, який завантажує сторінку SignWidget */}
                                <div id={SIGN_WIDGET_PARENT_ID} style={{ width: "100%", height: "500px" }}>
                                </div>
                                {error &&
                                    <div className="file-sign-form-error">
                                        {i18next.t("Виникла помилка при підписі даних. Опис помилки: ") + error}
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        {keyLoaded &&
                            <button className="btn btn-primary" onClick={this.onSign}>{i18next.t("sign")}</button>
                        }
                        <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}