import React from 'react';
import { Table } from '../table/Table';
import { clientSettings } from '../../settings/clientSettings';
import { getLocalDateString, getLocalTimeString } from '../helpers/HelperFunctions';
export class ExternalSigners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statuses: []
        }
    }
    render() {
        const { signers } = this.props;
        const { statuses } = this.state;
        let table = clientSettings.table.mainContentDigitalSignatures;
        let rows = [];
        if (signers) {
            for (let i = 0; i < signers.length; ++i) {
                let signingDate = signers[i].properties?.signingDate ? new Date(signers[i].properties.signingDate) : "";
                rows.push({
                    actions: [],
                    columns: {
                        Id: signers[i].properties?.id,
                        companyName: signers[i].properties?.sx_signerCompany,
                        creatorFullName: signers[i].properties?.signerName,
                        creatorPosition: signers[i].properties?.sx_signerPosition,
                        signingState: statuses?.find(obj => obj?.value === signers[i]?.properties?.signingState)?.label,
                        signingDate: signingDate ? `${getLocalDateString(signingDate)} ${getLocalTimeString(signingDate).substring(0, 5)}` : signingDate,
                        signingCommentsPreview: signers[i].properties?.signingCommentsPreview,
                    }
                });
            }
        }
        return (<div className="large-form doc-form"><Table columns={table.columns} items={rows} /></div>);
    }
}