import React, { Component } from 'react';
import i18next from "../../i18n";
import { hideModalDialog, showModalDialog, unknownErrorMessageDialog, errorMessageDialog } from '../ModalDialog';
import * as HelperFunctions from '../helpers/HelperFunctions';
export class EditFolderForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            title: '',
            sending: !!props.oldName,
            folderType: this.props.folderType,
            submitted: false,
            titleExists: false,
            incorrectName: false,
            incorrectTitle: false
        };
        this.changeInputValue = HelperFunctions.changeInputValue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    componentDidMount() {
        this.fillForm();
    }
    async fillForm() {
        const { baseDir } = this.props;
        if (!!this.props.oldName) {
            let url = `home/getFolder?baseDir=${baseDir}&name=${this.props.oldName}`;
            const response = await fetch(url);
            const data = await response.json();
            if (!response.ok) {
                unknownErrorMessageDialog();
            }
            else {
                this.setState({ name: data.name, title: data.title, sending: false });
            }
        }
    }
    handleSubmit(event) {
        event.preventDefault();
        const { sending, name, incorrectName, incorrectTitle } = this.state;
        this.setState({ submitted: true });
        if (!sending && !incorrectName && !incorrectTitle && name) {
            this.setState({ sending: true });
            let oldName = this.props.oldName;
            this.submit({
                name,
                oldName
            });
        }
    }
    async submit(data) {
        const { baseDir } = this.props;
        const currentObject = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        var url = !!data.oldName ? `/home/editFolder?baseDir=${baseDir}` : `/home/addFolder?baseDir=${baseDir}`;
        fetch(url, requestOptions)
            .then((response) => {
                return response.ok ? response.text().then(() => {
                    currentObject.setState({ sending: false });
                    function onClose() {
                        hideModalDialog("loadingDialog");
                        window.location.replace(`${window.location.pathname.split('/').slice(0, 3).join('/')}/${data.name ? data.name : data.oldName}`);
                    }
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">{data.oldName ? i18next.t("folderSuccessfullySaved") : i18next.t("folderSuccessfullyAdded")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                }) : response.text().then((errorText) => {
                    switch (errorText) {
                        case "Forbidden":
                            hideModalDialog("loadingDialog");
                            errorMessageDialog(i18next.t("12"));
                            break;
                        case "IncorrectName":
                            this.setState({ [errorText]: true, sending: false });
                            break;
                        case "AlreadyExists": {
                            function onClose() {
                                hideModalDialog("loadingDialog");
                                window.location.reload();
                            }
                            currentObject.setState({ sending: false });
                            showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("folderAlreadyExists")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                            break;
                        }
                        case "NotFound": {
                            function onClose() {
                                hideModalDialog("loadingDialog");
                                window.location.replace(`${window.location.pathname.split('/').slice(0, 3).join('/')}`);
                            }
                            currentObject.setState({ sending: false });
                            showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("folderNotFound")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                            break;
                        }
                        default:
                            console.log(errorText);
                            unknownErrorMessageDialog();
                            break;
                    }
                });
            });
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    render() {
        const { name, sending, submitted, incorrectName } = this.state;
        return (
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple user-invite-form">
                        <div className="row">
                            <div className={'form-group col-sm-12 col-xs-12' + (submitted && !name ? ' has-error' : '')}>
                                <label htmlFor={Object.keys({ name })[0]} className="required">{i18next.t("folderName")}</label>
                                <input className="form-control" name={Object.keys({ name })[0]} value={name} disabled={(sending ? 'disabled' : '')} onChange={this.changeInputValue} />
                                {submitted && !name &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                                {submitted && name && incorrectName &&
                                    <div className="help-block">{i18next.t("badFieldFormat")}</div>
                                }
                            </div>
                            <div className='form-group'>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary" onClick={this.handleSubmit}>{i18next.t("save")}</button>
                        <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
