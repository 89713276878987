import React from 'react';
import { sortArray } from '../helpers/HelperFunctions';
export class ActionsPanel extends React.Component {
    constructor(props) {
        super(props);
    }
    getCorrectWorld(count) {
        if (count >= 10 && count <= 20) return "елементів";
        const str = count.toString();
        switch (str[str.length - 1]) {
            case '1': return "елемент";
            case '2':
            case '3':
            case '4': return "елемента";
            default: return "елементів";
        }
    }
    render() {
        const { actions, actionHandlerCallback, checkedCount, handlerUncheckAll } = this.props;
        if (actions && actions.length > 0)
            sortArray(actions, "sortIndex");
        return (
            <div className="actions-panel">
                {actions && actions.length > 0 &&
                    [
                        <div key={0} className="action-list-counter">
                            <input id="check-all-checkbox-actions-panel" type="checkbox" onChange={() => handlerUncheckAll()} checked={true} />
                            <label htmlFor="check-all-checkbox-actions-panel"></label>
                            <div className="attachments-control-label attachment-item-title">
                                {checkedCount >= 0 ?
                                    "Обрано " + checkedCount + " " + this.getCorrectWorld(checkedCount) :
                                    ""
                                }
                            </div>
                        </div>,
                        <ul key={1} className="action-list">
                            {actions.map(action =>
                                    <li key={action.type.toString() + (action.sortIndex ? action.sortIndex.toString() : "")} onClick={(e) => { e.preventDefault(); if (actionHandlerCallback) actionHandlerCallback(action.type) }}>
                                        <button className={"btn " + (action.btnClass ? action.btnClass : "btn-primary")}>{action.title}</button>
                                    </li>
                                )}
                        </ul>]
                }
                <button className="actions-panel-close" onClick={handlerUncheckAll}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
                    </svg>
                </button>
            </div>
        );
    }
}