import React from 'react';
export class PasswordInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            value: ''
        };
        this.showPassword = this.showPassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        if (this.props.value)
            this.setState({ value: this.props.value });
    }
    showPassword(show) {
        this.setState({ showPassword: show });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== undefined && nextProps.value !== this.state.value)
            this.setState({ value: nextProps.value });
    }
    handleChange(e) {
        const { value } = e.target;
        this.setState({ value: value });
        if (this.props.onChange)
            this.props.onChange(e);
    }
    render() {
        const { value, showPassword } = this.state;
        const { name, className, disabled } = this.props;
        let { autocomplete } = this.props;
        let readonly = false;
        if (!autocomplete) autocomplete = "on";
        if (autocomplete === "off") readonly = true;
        return (
            <div className="password-input-control-block">
                {readonly ?
                    <input type={showPassword ? "text" : "password"} readOnly={true} onFocus={(e) => e.target.removeAttribute('readonly')} autoComplete={autocomplete} className={'password-input-control ' + (className ? className : '')} name={name} value={value} onChange={this.handleChange} disabled={disabled} />
                    :
                    <input type={showPassword ? "text" : "password"} onFocus={(e) => e.target.removeAttribute('readonly')} autoComplete={autocomplete} className={'password-input-control ' + (className ? className : '')} name={name} value={value} onChange={this.handleChange} disabled={disabled} />
                }
                <img className={"password-input-control-icon" + (showPassword ? " hidden" : "")} src="/img/password-show-icon.svg" onClick={() => { if (!disabled) this.showPassword(true) }} />
                <img className={"password-input-control-icon" + (showPassword ? "" : " hidden")} src="/img/password-hide-icon.svg" onClick={() => { if (!disabled) this.showPassword(false) }} />
            </div>
        );
    }
}