import React from 'react';
import InputMask from 'react-input-mask';
import { actionTypes, docsForRepresentationDictionary, docsForRepresentationTypes, maxFileSize, UserContext } from '../../constants';
import i18next from "../../i18n";
import { PasswordInput } from '../forms/PasswordInput';
import { checkPassword, checkPhone } from '../helpers/CheckFunctions';
import { dispatcherActionTypes, globalEventDispatcher } from '../helpers/GlobalEventDispatcher';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
const mime = require('mime-types');
export class ProfileSettingsForm extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '',
            position: '',
            passwordRepeat: '',
            submitted: false,
            incorrectLogin: false,
            wasChanged: false,
            loading: true,
            emailConfirmed: true,
            titleDocumentFilename: '',
            titleDocumentNodeRef: '',
            titleDocumentFileRemoved: null,
            docForRepresentation: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeProfile = this.changeProfile.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.actionHandlerCallback = this.actionHandlerCallback.bind(this);
    }
    componentDidMount() {
        this.loadUserInfo();
    }
    handleChange(e) {
        const { firstName, lastName, titleDocumentNodeRef } = this.state;
        const { name, value } = e.target;
        let wasChanged = true;
        if (this.state[name] === value)
            wasChanged = false;
        if ((name === Object.keys({ firstName })[0] || name === Object.keys({ lastName })[0]) && titleDocumentNodeRef) {
            import('../ModalDialog').then(templates => { templates.messageDialog(i18next.t("message"), i18next.t("changeProfileFullNameMessage")) });
            this.actionHandlerCallback(actionTypes.remove, [titleDocumentNodeRef])
        }
        this.setState({ [name]: value, wasChanged: wasChanged });
    }
    async loadUserInfo(data = null) {
        if (!data) {
            const response = await fetch('account/getCurrentUser');
            data = await response.json();
        }
        if (data) {
            this.setState(
                {
                    email: data.email ? data.email : '',
                    firstName: data.firstName ? data.firstName : '',
                    lastName: data.lastName ? data.lastName : '',
                    phone: data.phoneNumber ? data.phoneNumber : '',
                    position: data.position ? data.position : '',
                    emailConfirmed: data.emailConfirmed,
                    docForRepresentationType: data.titleDocumentValue ? data.titleDocumentValue : docsForRepresentationDictionary[docsForRepresentationTypes.extraction],
                    titleDocumentFilename: data.titleDocumentFilename ? data.titleDocumentFilename.replace("<br/>", "") : '',
                    titleDocumentNodeRef: data.titleDocumentNodeRef ? data.titleDocumentNodeRef : '',
                    titleDocumentFileRemoved: null,
                    submitted: false,
                    incorrectLogin: false,
                    wasChanged: false,
                    loading: false,
                    docForRepresentation: null,
                    titleDocumentIssueDate: data.titleDocumentIssueDate ? data.titleDocumentIssueDate.split("T")[0] : '',
                    titleDocumentValidity: data.titleDocumentValidity ? data.titleDocumentValidity.split("T")[0] : ''
                }
            )
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const { incorrectLogin, firstName, lastName, phone, password, passwordRepeat, position, loading, docForRepresentationType, docForRepresentation,
            titleDocumentFileRemoved, titleDocumentFilename, titleDocumentIssueDate, titleDocumentValidity } = this.state;
        if (loading)
            return;
        this.setState({ submitted: true });
        if ((password || passwordRepeat) && (password !== passwordRepeat || !checkPassword(password)))
            return;
        if (!incorrectLogin && firstName && lastName && phone && checkPhone(phone) &&
            (docForRepresentation ? docForRepresentation.file.size <= maxFileSize : true)) {
            //if (!titleDocumentIssueDate && [docsForRepresentationTypes.statute].indexOf(docForRepresentationType)) return;
            //if (!titleDocumentValidity && [docsForRepresentationTypes.protocol, docsForRepresentationTypes.order, docsForRepresentationTypes.statute].indexOf(docForRepresentationType)) return;
            this.setState({ loading: true });
            const formData = new FormData();
            formData.append("password", password);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("phone", phone);
            formData.append("position", position);
            formData.append("docForRepresentationType", docForRepresentationType);
            formData.append("titleDocumentIssueDate", titleDocumentIssueDate);
            formData.append("titleDocumentValidity", titleDocumentValidity);
            if (docForRepresentation) {
                formData.append("docForRepresentation", docForRepresentation.file, docForRepresentation.file.name);
            }
            if (titleDocumentFileRemoved) {
                formData.append("titleDocumentFileRemoved", titleDocumentFileRemoved);
            }
            this.changeProfile(formData);
        }
    }
    changeProfile(data) {
        const currentObject = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Contetnt-Type': 'multipart/form-data' },
            body: data
        };
        fetch('/account/changeProfile', requestOptions)
            .then(async (response) => {
                let result = response.ok ? { isError: false } : ({ status: response.status, errorText: await response.text(), isError: true });
                if (!result.isError) {
                    this.setState({ password: '', passwordRepeat: '' });
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message" dangerouslySetInnerHTML={{ __html: i18next.t("profileChangedMessage") }}></div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
                    fetch('account/getCurrentUser')
                        .then(async (response) => {
                            let data = response.ok ? await response.json() : { errorCode: response.status };
                            if (data.errorCode) {
                                console.log('error during getting current user: code - ', data.errorCode);
                            }
                            else {
                                let user = this.context;
                                user.email = data.email;
                                user.userName = data.fullName;
                                localStorage.setItem('user', JSON.stringify(user));
                                this.loadUserInfo(data);
                                globalEventDispatcher.dispatch(dispatcherActionTypes.onCurrentUserUpdated);
                            }
                        });
                    return '';
                }
                else
                    return result.errorText;
            })
            .then((errorText) => {
                if (errorText) {
                    if (errorText === "EmailExists") currentObject.setState({ incorrectLogin: true, loading: false });
                    else {
                        console.log(errorText);
                        currentObject.setState({ loading: false });
                        require('../ModalDialog').unknownErrorMessageDialog();
                    }
                }
            });
    }
    handleChangeFile(fileInfo) {
        this.setState({ docForRepresentation: fileInfo })
    }
    async actionHandlerCallback(type, ids) {
        switch (type) {
            case actionTypes.remove:
                {
                    this.setState({
                        docForRepresentation: null,
                        titleDocumentFilename: null,
                        titleDocumentNodeRef: null,
                        titleDocumentFileRemoved: ids[0]
                    });
                }
                break;
            case actionTypes.preview:
                import('../ModalDialog').then(templates => { templates.viewPdfDialog(ids[0]) });
                break;
        }
    }
    render() {
        const { email, password, passwordRepeat, firstName, lastName, submitted, phone, position, wasChanged, loading,
            docForRepresentationType, titleDocumentFilename, titleDocumentNodeRef, titleDocumentIssueDate, titleDocumentValidity } = this.state;
        const viewAction = {
            "title": i18next.t("view"),
            "btnClass": "btn-primary",
            "type": actionTypes.preview,
            "sortIndex": "1"
        };
        const removeAction = {
            "title": i18next.t("remove"),
            "btnClass": "btn-secondary",
            "type": actionTypes.remove,
            "sortIndex": "2"
        };
        const docForRepresentationTable = {
            columns: [
                {
                    "id": "0",
                    "name": "Id",
                    "headerName": "",
                    "type": "string",
                    "hidden": true
                },
                {
                    "id": "1",
                    "name": "FileName",
                    "headerName": i18next.t("documentName"),
                    "type": "string"
                }
            ]
        }
        let rows = [];
        if (titleDocumentNodeRef) {
            rows.push({
                actions: [],
                columns: {
                    Id: titleDocumentNodeRef,
                    FileName: titleDocumentFilename
                }
            });
            if (mime.lookup(titleDocumentFilename) === "application/pdf") {
                rows[0].actions.push(viewAction);
            }
            rows[0].actions.push(removeAction);
        }
        return (
            <div className="form-block large-form settings-form">
                <div className="form-content">
                    <form name="form" onSubmit={this.handleSubmit} >
                        <div className="row">
                            <div className="col-lg-6">
                                <div className={'form-group' + (submitted && !lastName ? ' has-error' : '')}>
                                    <label htmlFor="lastName" className="required">{i18next.t("lastName")}</label>
                                    <input className="form-control" name="lastName" value={lastName} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />
                                    {submitted && !lastName &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={'form-group' + (submitted && !firstName ? ' has-error' : '')}>
                                    <label htmlFor="firstName" className="required">{i18next.t("firstNameAndMiddleName")}</label>
                                    <input className="form-control" name="firstName" value={firstName} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />
                                    {submitted && !firstName &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className='form-group'>
                                    <label htmlFor="position">{i18next.t("positionHeld")}</label>
                                    <input className="form-control" name="position" value={position} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={'form-group' + (submitted && !phone ? ' has-error' : '')}>
                                    <label htmlFor="phone" className="required">{i18next.t("phone")}</label>
                                    <InputMask type="tel" className="form-control" name="phone" mask='+380999999999' maskChar={null} value={phone} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />
                                    {submitted && !phone &&
                                        <div className="help-block">{i18next.t("requiredField")}</div>
                                    }
                                    {submitted && phone && !checkPhone(phone) &&
                                        <div className="help-block">{i18next.t("badFieldFormat")}</div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                    <label htmlFor="email">{i18next.t("email")}</label>
                                    <input type="email" className="form-control" name="email" autoComplete="off" value={email} onChange={this.handleChange} disabled="disabled" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <label htmlFor="password">Новий пароль</label>
                                    <PasswordInput className="form-control" name="password" value={password} autocomplete="off" onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />
                                    {submitted && password && !checkPassword(password) &&
                                        <div className="help-block">{i18next.t("incorrectPasswordErrorText")}</div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='form-group'>
                                    <label htmlFor="passwordRepeat">Повторіть новий пароль</label>
                                    <PasswordInput className="form-control" name="passwordRepeat" value={passwordRepeat} autocomplete="off" onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />
                                    {submitted && (passwordRepeat || password) && passwordRepeat !== password &&
                                        <div className="help-block">{i18next.t("passwordsDoNotMatch")}</div>
                                    }
                                </div>
                            </div>
                            {/*<div className="col-lg-6">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label className="required">{i18next.t("docForRepresentation")}</label>*/}
                            {/*        <select className="form-control" name="docForRepresentationType" value={docForRepresentationType} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')}>*/}
                            {/*            {loading && <option value=""></option>}*/}
                            {/*            {Object.entries(docsForRepresentationDictionary).map(([key, value]) => <option key={key} value={key}>{value}</option>)}*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*{rows.length === 0 ?*/}
                            {/*    <FileField col={6} check={submitted} required={false}*/}
                            {/*        label={docForRepresentationType === docsForRepresentationTypes.statute ? "Прикріпіть, будь ласка, наказ" : ""}*/}
                            {/*        allowedExtensions={["RTF", "DOC", "DOCX", "XLS", "XLSX", "PDF", "ODT", "TXT", "BMP", "TIF", "TIFF", "JPG", "JPEG", "PNG"]}*/}
                            {/*        handleChange={this.handleChangeFile} maxSize={maxFileSize} />*/}
                            {/*    : ""}*/}
                            {/*<div className="col-lg-12" style={{ display: rows.length < 1 ? "none" : "" }}>*/}
                            {/*    <label className={docForRepresentationType === docsForRepresentationTypes.statute ? "required" : ""}>{i18next.t("docForRepresentation")}</label>*/}
                            {/*    <Table columns={docForRepresentationTable.columns} items={rows} actionHandlerCallback={this.actionHandlerCallback} />*/}
                            {/*    <br />*/}
                            {/*</div>*/}
                            {/*{[docsForRepresentationTypes.statute].indexOf(docForRepresentationType) === -1 &&*/}
                            {/*    <div className={'form-group col-sm-6 col-xs-6' + (submitted && !titleDocumentIssueDate ? ' has-error' : '')}>*/}
                            {/*        <label htmlFor="titleDocumentIssueDate">{i18next.t("form.editSigner.documentIssueDate")}</label>*/}
                            {/*        <DateTimeInput onlyDate={true} className="form-control" name="titleDocumentIssueDate" value={titleDocumentIssueDate} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />*/}
                            {/*        {submitted && !titleDocumentIssueDate &&*/}
                            {/*            <div className="help-block">{i18next.t("requiredField")}</div>*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*{[docsForRepresentationTypes.protocol, docsForRepresentationTypes.order, docsForRepresentationTypes.statute].indexOf(docForRepresentationType) === -1 &&*/}
                            {/*    <div className={'form-group col-sm-6 col-xs-6' + (submitted && !titleDocumentValidity ? ' has-error' : '')}>*/}
                            {/*        <label htmlFor="titleDocumentValidity">{i18next.t("form.editSigner.documentValidity")}</label>*/}
                            {/*        <DateTimeInput onlyDate={true} className="form-control" name="titleDocumentValidity" value={titleDocumentValidity} onChange={this.handleChange} disabled={(loading ? 'disabled' : '')} />*/}
                            {/*        {submitted && !titleDocumentValidity &&*/}
                            {/*            <div className="help-block">{i18next.t("requiredField")}</div>*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        <div className="form-group form-group__last">
                            <button className="btn btn-primary">{i18next.t("save")}</button>
                            {wasChanged &&
                                <button type="button" className="btn btn-secondary" onClick={this.componentDidMount.bind(this)}>{i18next.t("cancel")}</button>
                            }
                            <div className="required-label">
                                <label className="required" />
                                <span className="btnDesk__info">{i18next.t("requiredFields")}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}