import i18n from "i18next";
let settings = require('./locales/settings.json');
i18n
    .use(require('react-i18next').initReactI18next)
    .init({
        resources: Object.assign({}, ...settings.languages.map(item => ({ [item]: { translation: require(`./locales/${item}.json`) } }))),
        fallbackLng: settings.language,
        interpolation: {
            escapeValue: false
        },
        debug: true
    });
export default i18n;