import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { constants } from '../constants'
import { getCurrentCompanyInfo } from './helpers/HelperFunctions'
import { Svg } from './Svg'
import { History } from '../constants'
import i18next from "../i18n"
import { base64toBlob } from './helpers/HelperFunctions'
import { PdfViewer } from './PdfViewer'
const mime = require("mime-types");
export const showModalDialog = showModal;
export const hideModalDialog = hideModal;
class ModalDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentCompanyName: ''
		};
		this.closeDialog = this.closeDialog.bind(this);
	}
	componentDidMount() {
		if (!this.props.header) {
			const currentObject = this;
            getCurrentCompanyInfo().then((info) => { currentObject.setState({ currentCompanyName: info?.name }); })
		}
	}
	static getDerivedStateFromProps(props, state) {
		return state;
	}
	closeDialog() {
		const { dialogId, canClose, closeCalback } = this.props;
		if (canClose) {
			hideModal(dialogId);
			if (closeCalback)
				closeCalback();
		}
	}
	render() {
		const { dialogId, children, header, icone, additionalClasses, buttons, canClose, headerIconButtons } = this.props;
		const { currentCompanyName } = this.state;
		const closeDialogFunction = this.closeDialog;
		let dialogFormId = "form_" + dialogId;
		let index = 0;
		return (
			<div className={"modal fade show " + (additionalClasses ? additionalClasses : "") + (canClose ? " can-close" : "") + (header ? " with-title" : "")} id={dialogFormId} onMouseDown={(e) => {
				e = e || window.event;
				var target = e.target || e.srcElement;
				var mouseUpEvent = function (e) {
					e = e || window.event;
					var upTarget = e.target || e.srcElement;
					if (upTarget.id === dialogFormId) {
						closeDialogFunction();
						target.removeEventListener("mouseup", mouseUpEvent);
					}
					else target.removeEventListener("mouseup", mouseUpEvent);
				}
				if (target.id === dialogFormId) target.addEventListener("mouseup", mouseUpEvent);
			}}>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className={"modal-header" + (icone || header ? " with-icon" : "") + (headerIconButtons && headerIconButtons.length > 0 ? " with-header-btns" : "")}>
							{icone && <img className="modal-icone" src={icone} alt="" />}
							{
								!header &&
								<div className="form-header-subtitle-right form-subtitle">{currentCompanyName}</div>
							}
							{header && <div className="modal-header-title">{header}</div>}
							{headerIconButtons && 
								<div className="header-btns">
								    {headerIconButtons.map(btn =>
									    <div className="header-icon-btn" onClick={btn.onClickCallback}>
										    <Svg src={btn.src} />
									    </div>
                                    )}
                                </div>
                            }
							{canClose && <button type="button" className="close m-0 btn" onClick={closeDialogFunction}>
								<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
								</svg>
							</button>}
						</div>
						<div className="modal-body">
							{children}
						</div>
						{buttons && buttons.length > 0 &&
							<div className="modal-footer">
							{buttons.map(btn =>
								<button type="button" className={"btn " + btn.class} key={"btn_" + index++} onClick={btn.clickHandler && btn.clickHandler}>{btn.title}</button>
								)}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}
function showModal(dialogId, header, icone, children, additionalClasses, canClose = true, buttons = [], closeCalback = null, headerIconButtons = []) {
	if (constants.placeholderDialogId !== dialogId)
		hideModal(constants.placeholderDialogId);
	var dialog = document.getElementById(dialogId);
	if (dialog) {
		ReactDOM.render(
			<ModalDialog header={header} icone={icone} dialogId={dialogId} additionalClasses={additionalClasses} canClose={canClose} buttons={buttons} closeCalback={closeCalback} headerIconButtons={headerIconButtons} >
				{children}
			</ModalDialog>,
			dialog);
		if (document.body.style.position !== "fixed") {
			document.body.style.top = "-" + getBodyScrollTop() + "px";
			document.body.style.overflowY = "scroll";
			document.body.style.width = "100%";
			document.body.style.position = "fixed";
		}
	}
}
function hideModal(dialogId) {
	var dialog = document.getElementById(dialogId);
	ReactDOM.render(
		"",
		dialog);
	var scrollValue = document.body.style.top;
	if (scrollValue.startsWith('-'))
		scrollValue = scrollValue.substr(1, scrollValue.length - 1);
	if (scrollValue.endsWith('px'))
		scrollValue = scrollValue.substr(0, scrollValue.length - 2);
	let style = document.body.style; 
	style.overflowY = style.width = style.position = style.top = "";
	if (scrollValue)
		window.scrollTo(0, parseInt(scrollValue));
}
function getBodyScrollTop() {
	return window.pageYOffset ? window.pageYOffset : (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);
}
export function unknownErrorMessageDialog(redirectUrl) {
	let closeDialog = () => {
		hideModalDialog("loadingDialog")
		if (redirectUrl) History.push(redirectUrl)
	}
	showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("unknownErrorMessage")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: closeDialog, class: "btn-primary" }], closeDialog)
}
export function messageDialog(title, message, handleCloseDialog) {
	let closeDialog = () => {
		hideModalDialog('modalDialog')
		if (handleCloseDialog) handleCloseDialog()
	}
	showModalDialog('modalDialog', title, "", <div className="info-message">{message}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: closeDialog, class: "btn-primary" }], closeDialog)
}
export function errorMessageDialog(message) {
	messageDialog(i18next.t("error"), message)
}
export function viewPdfDialog(nodeRef) {
	let view = function (path) {
		fetch(path, { method: 'POST' })
			.then(async (response) => {
				if (response.ok) {
					let data = await response.json()
					if (data.errorCode) unknownErrorMessageDialog()
					else {
						let path = `file/get?nodeRefs=${nodeRef}`
						if (!data[0].content && !response.url.toLowerCase().includes(path.toLowerCase())) view(path)
						else if (data[0].content) {
							let objectUrl = window.URL.createObjectURL(base64toBlob(data[0].content, "application/pdf"))
							viewFile(objectUrl, data[0].fileName)
						}
						else {
							showModalDialog("loadingDialog", i18next.t("viewingDocument"), "",
								<div className="info-message">{i18next.t("viewedDocumentNotFound")}</div>,
								"feedPopup", true,
								[{ title: i18next.t("ok"), clickHandler: () => hideModalDialog('loadingDialog'), class: "btn-primary" }]);
						}
					}
				}
			})
	}
	view(`file/get?nodeRefs=${nodeRef}&withSign=${false}`)
}
export async function viewFileFolderSystemFile(urlPath, fileName) {
	const response = await fetch(urlPath, { method: 'POST' });
	if (response.ok) {
		response.blob().then(blobby => {
			var file = new Blob([blobby], { type: 'application/pdf' });
			var objectUrl = window.URL.createObjectURL(file);
			viewFile(objectUrl, fileName);
		});
	}
	else
		messageDialog(i18next.t("error"), response.status === 404 ? i18next.t("documentNotFound") : i18next.t("unknownErrorMessage"));
}
export function viewFile(objectUrl, fileName) {
	showModalDialog("loadingDialog", i18next.t("viewingDocument"), "",
		<><div className="filter-block-header">
			<ul className="filter-header-buttons one-icons">
				<li>
					<button type="button" className="btn no-bg-btn" onClick={() => {
						let anchor = document.createElement("a")
						anchor.href = objectUrl
						anchor.download = fileName
						anchor.click()
					}}>
						<div>
							<Svg src="/img/icn-download.svg" />
						</div>
						<span>{i18next.t("download")}</span>
					</button>
				</li>
			</ul>
		</div>,
			<PdfViewer src={objectUrl} /></>
		, "feedPopup max-size with-header-btns", true, [], () => window.URL.revokeObjectURL(objectUrl))
}
export function isValidFileSizeDialog(size) {
	if (!Number.isSafeInteger(size) || size > 47190000) {
		errorMessageDialog(i18next.t("11"));
		return false;
	}
	return true
}
export function isAllowedFileTypeDialog(file, allowedExtensions) {
	let fileName = file?.name;
	if (!allowedExtensions.map(item => mime.lookup(item)).includes(mime.lookup(fileName))) {
		let errorMessage = i18next.t("invalidFileFormatMessage", { extensions: allowedExtensions.join(', ') });
		errorMessageDialog(errorMessage);
		return false;
	}
	return true;
}