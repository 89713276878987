import * as constants from '../constants';
import i18next from "../i18n";
export default {
    actions: [
        {
            "title": i18next.t("edit"),
            "btnClass": "btn-primary",
            "type": constants.actionTypes.edit
        },
        {
            "title": i18next.t("remove"),
            "btnClass": "btn-no-fill",
            "type": constants.actionTypes.remove
        }
    ],
    "Columns": [
        {
            "name": "Id",
            "type": "string",
            "hidden": true
        },
        {
            "name": "prop_sx_cpLastName",
            "headerName": "Прізвище",
            "type": "string"
        },
        {
            "name": "prop_sx_cpFirstName",
            "headerName": "Ім’я",
            "type": "string"
        },
        {
            "name": "prop_sx_cpSecondName",
            "headerName": "По батькові",
            "type": "string"
        },
        {
            "name": "prop_sx_cpPosition",
            "headerName": "Посада",
            "type": "string"
        },
        {
            "name": "prop_sx_cpEmail",
            "headerName": "Ел. пошта",
            "type": "string"
        },
        {
            "name": "prop_sx_cpPhone",
            "headerName": "Телефон",
            "type": "string"
        },
        {
            "name": "prop_sx_cpSMain",
            "headerName": "Основний підписант",
            "type": "bool"
        },
        {
            "name": "prop_sx_cpSPresentDoc_label",
            "headerName": "Документ підстава для представництва",
            "type": "string"
        },
        {
            "name": "prop_sx_cpSPresentDocDate",
            "headerName": "Дата видачі документа підстави",
            "type": "date"
        },
        {
            "name": "prop_sx_cpSPresentDocExpire",
            "headerName": "Термін дії документа підстави",
            "type": "date"
        }
    ]
}