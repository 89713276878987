import React,{Component} from 'react';
import i18next from "../../i18n";
import {hideModalDialog,showModalDialog} from '../ModalDialog';
import {Svg} from '../Svg';
import { CommentForm } from './CommentForm';
import { getLocalDateString, getLocalTimeString } from '../helpers/HelperFunctions';
import * as constants from '../../constants';
export class Comment extends Component {
    static contextType = constants.UserContext;
    constructor(props) {
        super(props);
        this.state = this.props.comment ? Object.assign({ show: false }, ...Object.keys(this.props.comment).map((key) => ({ [key]: this.props.comment[key] }))) : {};
        this.handleChangeComents = this.handleChangeComents.bind(this);
        this.handleChangeComent = this.handleChangeComent.bind(this);
    }
    render() {
        const { documentId, parentCommentId, statuses } = this.props;
        const { id, userFullName, position, created, content, userName, commentBeanList, initiatorSystem, show } = this.state;
        const { registerCode } = this.context;
        let btns = [
            {
                onClick: () => showModalDialog('modalDialog', i18next.t("message"), "", "Ви впевнені, що бажаєте видалити коментар?", "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: this.props.handleDeliteComent, class: "btn-primary" }, { title: i18next.t("no"), clickHandler: () => hideModalDialog('modalDialog'), class: "btn-secondary" }]),
                svg: { src: "/img/comment-icn-trash.svg" }, forAuthor: true
            },
            {
                onClick: () => showModalDialog('modalDialog', i18next.t("editComment"), "", <CommentForm handleChangeComment={this.editComent.bind(this)} comment={this.state} />, "feedPopup"),
                svg: { src: "/img/comment-icn-edit.svg" }, forAuthor: true
            },
            {
                onClick: () => showModalDialog('modalDialog', i18next.t("addComment"), "", <CommentForm handleChangeComment={this.replyComent.bind(this)} />, "feedPopup"),
                svg: { src: "/img/comment-icn-reply.svg" }, className: "btn btn-primary"
            },
            {
                onClick: () => { this.setState({ show: !show }) },
                text: commentBeanList?.length ? i18next.t("18", { value: commentBeanList.length }) : '', className: "btn btn-secondary"
            }
        ];
        return (
            <div id={id} className="comment-block">
                <div className="comment">
                    <div className="comment-header">
                        <div className="comment-header-info">{initiatorSystem !== constants.initiatorSystem.PORTAL && initiatorSystem ? `${statuses?.find((item) => item?.value === initiatorSystem)?.label},` : ""} {userFullName}, {position ? position + ", " : ""}{documentId ? created : this.getComentDate.bind(this)(created)}</div>
                        <div className="action">
                            {btns.map((item, index) => <button key={index} type="button" style={item.forAuthor ? { display: ((registerCode === userName || !documentId) ? "" : "none") } : {}}
                                className={!item.className ? "btn btn-secondary" : item.className} onClick={item.onClick}>
                                {item.svg ? < Svg src={item.svg.src ?? ''} /> : ''}{item.text ? item.text : ''}
                            </button>)}
                        </div>
                    </div>
                    <div className="comment-text">{content}</div>
                </div>
                {show === true ? <div className="comments">
                    {commentBeanList?.map((item) =>
                        <Comment key={item.id} getComents={this.props.getComents} statuses={statuses} parentCommentId={`${(parentCommentId ? `${parentCommentId}|` : "")}${id}`} handleDeliteComent={() => this.deliteComent.bind(this)(item)} handleChangeComents={this.handleChangeComents} handleChangeComent={this.handleChangeComent} documentId={documentId} comment={item} />
                    )}
                </div> : ''}
            </div>
        );
    }
    async deliteComent(coment) {
        const { documentId, parentCommentId } = this.props;
        const { id } = this.state;
        if (documentId) {
            const data = await removeCommentAction(coment, documentId, parentCommentId ? `${parentCommentId}|${id}` : id);
            if (data) {
                this.setState({ comments: data });
                this.handleChangeComents(data);
            }
        }
        else {
            let index = this.state.commentBeanList.indexOf(coment);
            if (index !== -1) {
                this.state.commentBeanList.splice(index, 1);
                this.setState({ commentBeanList: this.state.commentBeanList });
            }
            this.handleChangeComents();
        }
        hideModalDialog('modalDialog');
    }
    async editComent(comment) {
        const { documentId, parentCommentId } = this.props;
        const { id, content, userName } = comment;
        if (documentId)
            this.buildCommentAction.bind(this)('POST', 'comments/crud', {
                action: 'editComment',
                parentCommentPath: `${(parentCommentId ? `${parentCommentId}|` : "")}${id}`,
                content: content,
                documentNodeRef: documentId,
                userName: userName
            });
        else {
            this.setState({
                content: content
            }, () => this.handleChangeComent(comment));
        }
        hideModalDialog('modalDialog');
    }
    async replyComent(subComment) {
        const { documentId, parentCommentId } = this.props;
        const { id } = this.state;
        if (documentId)
            this.buildCommentAction.bind(this)('POST', 'comments/crud', {
                action: 'addSubComment',
                parentCommentPath: `${(parentCommentId ? `${parentCommentId}|` : "")}${id}`,
                content: subComment.content,
                documentNodeRef: documentId,
            });
        else {
            this.state.commentBeanList = [...this.state.commentBeanList, subComment];
            this.setState({ commentBeanList: this.state.commentBeanList }, () => this.handleChangeComent(this.state));
        }
        hideModalDialog('modalDialog');
    }
    async buildCommentAction(method, url, body) {
        const response = await fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        });
        if (!response.ok) return require('../ModalDialog').unknownErrorMessageDialog();
        this.props.getComents();
    }
    handleChangeComents(comnets) {
        this.props.handleChangeComents(comnets);
        hideModalDialog('modalDialog');
    }
    handleChangeComent(comnet) {
        const { commentBeanList } = this.state;
        const index = commentBeanList.findIndex((item) => item.id === comnet.id);
        if (index !== -1) commentBeanList[index] = comnet;
        if (this.props.handleChangeComent)
            this.props.handleChangeComent(this.state);
    }
    getComentDate() {
        const date = new Date(this.state.created);
        return `${getLocalDateString(date)} ${getLocalTimeString(date).substring(0, 5)}`;
    }
}
export async function removeCommentAction(comment, documentId, parentCommentId) {
    return await buildComentAction('POST', 'comments/crud', {
        action: "removeComment",
        parentCommentPath: `${(parentCommentId ? `${parentCommentId}|` : "")}${comment?.id}`,
        documentNodeRef: documentId,
        userName: comment?.userName
    });
}
export async function buildComentAction(method, url, body) {
    const response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: body ? JSON.stringify(body) : null
    });
    if (!response.ok) require('../ModalDialog').unknownErrorMessageDialog();
    else if (url.indexOf('get') !== -1) {
        const result = await response.json();
        return Array.isArray(result) ? result : undefined;
    }
}