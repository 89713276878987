import React from 'react';
import i18next from "../../i18n";
import { PasswordInput } from '../forms/PasswordInput';
import { History, urls } from '../../constants';
import { checkPassword } from '../helpers/CheckFunctions';
import { AccountFormHeader } from './AccountFormHeader';
import * as AccountHelper from "./AccountHelper"
export class RestorePasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            repassword: "",
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.restorePassword = this.restorePassword.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, incorrectLogin: false });
    }
    async restorePassword(e) {
        e.preventDefault();
        const { password, repassword } = this.state;
        this.setState({
            submitted: true
        });
        if (password && repassword && password === repassword && checkPassword(password)) {
            await fetch(`/account/passwordRecovery`, {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    changePasswordKey: window.location.pathname.split("/")[2],
                    password: password
                })
            })
                .then(res => {
                    if (!res.ok) require('../ModalDialog').errorMessageDialog(i18next.t("notValidRecoveryUrl"));
                    else History.push(urls.loginPage);
                });
        }
    }
    render() {
        const { password, submitted, repassword } = this.state;
        return (
            <div className="form-page login-page">
                <div className="form-block login-form">
                    <AccountFormHeader className="form-header-subtitle form-subtitle" />
                    <div className="form-content">
                        <div className="form-title">{i18next.t("changePassword")}</div>
                        <form name="form" onSubmit={this.restorePassword} >
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <label htmlFor="password" className="required">{i18next.t("newPassword")}</label>
                                <PasswordInput className="form-control" name="password" value={password} onChange={this.handleChange} />
                                {AccountHelper.isRequiredMessage(submitted && !password)}
                                {AccountHelper.errorMessage(submitted && password && !checkPassword(password), 'incorrectPasswordErrorText')}
                            </div>
                            <div className={'form-group' + (submitted && !repassword ? ' has-error' : '')}>
                                <label htmlFor="repassword" className="required">{i18next.t("repeatNewPassword")}</label>
                                <PasswordInput className="form-control" name="repassword" value={repassword} onChange={this.handleChange} />
                                {AccountHelper.isRequiredMessage(submitted && !repassword)}
                                {AccountHelper.errorMessage(submitted && password && repassword && (password !== repassword), 'passwordsDoNotMatch')}
                            </div>
                            <div className="form-group form-group__last">
                                <button className="btn btn-primary">{i18next.t("changeYourPassword")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}