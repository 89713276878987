import i18next from "../i18n";
export default {
    actions: [
        {
            "title": i18next.t("edit"),
            "btnClass": "btn-primary",
            "type": 2
        },
        {
            "title": i18next.t("remove"),
            "btnClass": "btn-no-fill",
            "type": 3
        }
    ],
    "Columns": [
        {
            "name": "Id",
            "type": "string",
            "hidden": true
        },
        {
            "name": "prop_sx_bankName",
            "headerName": "Повне найменування банку",
            "type": "string"
        },
        {
            "name": "prop_sx_bankAddress",
            "headerName": "Адреса банку",
            "type": "string"
        },
        {
            "name": "prop_sx_bankMFO",
            "headerName": "МФО",
            "type": "string"
        },
        {
            "name": "prop_sx_bankAccount",
            "headerName": "Розрахунковий рахунок",
            "type": "string"
        },
        {
            "name": "prop_sx_bankIBAN",
            "headerName": "IBAN",
            "type": "string"
        },
        {
            "name": "prop_sx_bankCurrency_label",
            "headerName": "Валюта розрахунків",
            "type": "string"
        },
        {
            "name": "prop_sx_bankPriorityMain",
            "headerName": "Основний/другорядний",
            "type": "bool"
        },
        {
            "name": "prop_sx_bankAccountOwner",
            "headerName": "Власник рахунку",
            "type": "string"
        },
        {
            "name": "prop_sx_bankSingleCurrency",
            "headerName": "Одновалютний рахунок",
            "type": "bool"
        }
    ]
}