import React from 'react';
import i18next from "../../i18n";
import { Svg } from '../Svg';
export class TransferListInput extends React.Component {
    constructor(props) {
        super(props);
        let cols = [];
        if (props.columns)
            cols = props.columns;
        let selectedCols = [];
        if (props.selected)
            selectedCols = props.selected;
        let handleChange = null;
        if (props.handleChange)
            handleChange = props.handleChange;
        this.state = {
            columns: cols,
            selected: selectedCols,
            selectedId: "",
            handleChange: handleChange
        };
        this.select = this.select.bind(this);
        this.toLeft = this.toLeft.bind(this);
        this.toRight = this.toRight.bind(this);
        this.up = this.up.bind(this);
        this.down = this.down.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.selected !== this.state.selected)
            this.setState({ columnsSettings: nextProps.selected, changedColumnsSettings: nextProps.selected });
    }
    select(id) {
        this.setState({ selectedId: id });
    }
    toLeft() {
        let { columns, selected, selectedId, handleChange } = this.state;
        const index = selected.indexOf(selectedId);
        if (index > -1) {
            selected.splice(index, 1);
            if (selected.length > index)
                selectedId = selected[index];
            else if (selected.length > index - 1 && index > 0)
                selectedId = selected[index - 1];
            else
                selectedId = "";
        }
        this.setState({ selected: selected, selectedId: selectedId });
        if (handleChange)
            handleChange(columns, selected);
    }
    toRight() {
        let { columns, selected, selectedId, handleChange } = this.state;
        let leftItems = columns ? columns.filter(col => !selected.includes(col.id)) : [];
        let index = leftItems.findIndex(col => col.id === selectedId);
        selected.push(selectedId);
        if (index > -1) {
            leftItems = columns ? columns.filter(col => !selected.includes(col.id)) : [];
            if (leftItems.length > index)
                selectedId = leftItems[index].id;
            else if (leftItems.length > index - 1 && index > 0)
                selectedId = leftItems[index - 1].id;
            else
                selectedId = "";
        }
        this.setState({ selected: selected, selectedId: selectedId });
        if (handleChange)
            handleChange(columns, selected);
    }
    up() {
        let { columns, selected, selectedId, handleChange } = this.state;
        if (selected.includes(selectedId)) {
            let index = selected.indexOf(selectedId);
            if (index > 0) {
                let tmp = selected[index];
                selected[index] = selected[index - 1];
                selected[index - 1] = tmp;
                for (var i = 0; i < selected.length; i++) {
                    let num = columns.findIndex(col => col.id === selected[i]);
                    let tempCol = columns[num];
                    columns.splice(num, 1);
                    columns.push(tempCol);
                }
                this.setState({ columns: columns, selected: selected });
                if (handleChange)
                    handleChange(columns, selected);
            }
        }
    }
    down() {
        let { columns, selected, selectedId, handleChange } = this.state;
        if (selected.includes(selectedId)) {
            let index = selected.indexOf(selectedId);
            if (index > -1 && index < selected.length - 1) {
                let tmp = selected[index];
                selected[index] = selected[index + 1];
                selected[index + 1] = tmp;
                for (var i = 0; i < selected.length; i++) {
                    let num = columns.findIndex(col => col.id === selected[i]);
                    let tempCol = columns[num];
                    columns.splice(num, 1);
                    columns.push(tempCol);
                }
                this.setState({ columns: columns, selected: selected });
                if (handleChange)
                    handleChange(columns, selected);
            }
        }
    }
    render() {
        const { columns, selected, selectedId } = this.state;
        let leftItems = columns? columns.filter(col => !selected.includes(col.id)) : [];
        let rightItems = [];
        if (selected) {
            for (var i = 0; i < selected.length; i++) {
                let index = columns.findIndex(col => col.id === selected[i]);
                if (index > -1) {
                    rightItems.push(columns[index]);
                }
            }
        }
        let fromLeftToRightBtnEnabled = selectedId ? leftItems.filter(col => col.id === selectedId).length > 0 : false;
        let fromRightToLeftBtnEnabled = selectedId ? rightItems.filter(col => col.id === selectedId).length > 0 : false;
        let upEnabled = fromRightToLeftBtnEnabled;
        let downEnabled = upEnabled;
        if (fromRightToLeftBtnEnabled) {
            let index = rightItems.findIndex(col => col.id === selectedId);
            if (index === 0)
                upEnabled = false;
            if (index === rightItems.length - 1)
                downEnabled = false;
        }
        return (
            <div className="modal-body transfer-list-input">
                <div className="row transfer-list-body">
                    <div className="col first-label">
                        <label>{i18next.t("allColumns")}</label>
                    </div>
                    <div className="col last-label">
                        <label>{i18next.t("selectedColumns")}</label>
                    </div>
                    <div className="col list-col">
                        <ul>
                            {leftItems.map((i, k) =>
                                <li key={k} className={selectedId === i.id ? "selected" : ""} onClick={() => { this.select(i.id) }}>{i.headerName}</li> 
                            )}
                        </ul>
                    </div>
                    <div className="col buttons-col">
                        <ul>
                            <li>
                                <button disabled={(fromLeftToRightBtnEnabled || fromRightToLeftBtnEnabled) ? "" : "disabled"} onClick={() => fromLeftToRightBtnEnabled ? this.toRight() : this.toLeft()} >
                                    <span className="icon-op icon-op-plus">
                                        <Svg src="/img/refresh2.svg" />
                                    </span>
                                </button>
                            </li>
                            <li>
                                <button disabled={upEnabled ? "" : "disabled"} onClick={this.up}>
                                    <span className="icon-op icon-op-up">
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.70703 0.29294L11.707 5.29294L10.2928 6.70715L5.99993 2.41426L1.70703 6.70715L0.292818 5.29294L5.29282 0.29294C5.68334 -0.0975848 6.31651 -0.0975847 6.70703 0.29294Z" fill="#fff" />
                                        </svg>
                                    </span>
                                </button>
                            </li>
                            <li>
                                <button disabled={downEnabled ? "" : "disabled"} onClick={this.down}>
                                    <span className="icon-op icon-op-down">
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.29297 6.70706L0.292969 1.70706L1.70718 0.292847L6.00008 4.58574L10.293 0.292847L11.7072 1.70706L6.70718 6.70706C6.31666 7.09758 5.68349 7.09758 5.29297 6.70706Z" fill="#fff" />
                                        </svg>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="col list-col">
                        <ul>
                            {rightItems.map((item, i) =>
                                <li key={i} className={selectedId === item.id ? "selected" : ""} onClick={() => { this.select(item.id) }}>{item.headerName}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}