import React, { Component } from 'react'
import i18next from "../../i18n";
import { ActionsPanel } from '../forms/ActionsPanel'
import { dispatcherActionTypes, globalEventDispatcher } from '../helpers/GlobalEventDispatcher'
import { Pagination } from '../Pagination'
import { ColumnFilter } from './ColumnFilter'
import { TableRow } from './TableRow'
import { History } from '../../constants'
import { v4 } from 'uuid'
export const TableColumnType = {
    Default: "default",
    Bool: "bool",
    String: "string",
    Double: "double",
    Date: "date",
    Status: "status",
    Phone: "phone",
    Email: "email",
    DateTime: "datetime",
    Enum: "enum",
    Button: "button",
    Comment: "comment",
    DocLink: "docLink",
    Image: "image",
    Reorder: "reorder",
}
export async function saveColumnSettings(url, columnSettings, handleSuccess) {
    await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(columnSettings)
    })
        .then((response) => {
            if (!response.ok) require('../ModalDialog').unknownErrorMessageDialog();
            else if (handleSuccess) {
                handleSuccess();
            }
        });
}
export class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: props.sortBy,
            isDesk: props.isDesk,
            singleSelect: props.singleSelect,
            checkedList: [],
            allChecked: false,
            actions: [],
            name: v4(),
            showActionsPanel: false
        }
        this.sort = this.sort.bind(this);
        this.handlerCheck = this.handlerCheck.bind(this);
        this.handlerCheckAll = this.handlerCheckAll.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.handlerUncheckAll = this.handlerUncheckAll.bind(this);
        this.actionHandlerCallback = this.actionHandlerCallback.bind(this);
        this.globalEventListerner = this.globalEventListerner.bind(this);
        globalEventDispatcher.subscribe(this.globalEventListerner);
    }
    globalEventListerner(actionType, params) {
        if (actionType === dispatcherActionTypes.onUnselectTableAllRows) {
            this.handlerUncheckAll();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.sortBy !== this.state.sortBy || nextProps.isDesk !== this.state.isDesk || nextProps.checked !== this.state.checked || nextProps.items !== this.props.items) {
            let checked = nextProps.checked ? nextProps.checked : [];
            let actions = this.getActions(this.state.checkedList, nextProps.items);
            this.setState({ sortBy: nextProps.sortBy, isDesk: nextProps.isDesk, checked: checked, actions: actions });
        }
    }
    getActions(checkedList = null, items = null) {
        if (!items)
            items = this.props.items;
        let result = [];
        if (checkedList.length <= 0)
            return [];
        if (checkedList.length === 1) {
            result = items?.find(it => it.columns["Id"] === checkedList[0])?.actions?.slice(0) ?? [];
        }
        else {
            let checkedItems = items.filter(it => checkedList.includes(it.columns["Id"]));
            for (let i = 0; i < checkedList.length; i++) {
                try {
                    let item = items.find(it => it.columns["Id"] === checkedList[i]);
                    if (item && item.actions) {
                        let actions = item.actions.filter(it => it.isMultiaction);
                        for (let j = 0; j < actions.length; j++) {
                            let action = result.find(it => it.type === actions[j].type);
                            if (!action) {
                                let hasNowAction = checkedItems.find(it => !it.actions.find(act => act.type === actions[j].type));
                                if (!hasNowAction)
                                    result.push(actions[j]);
                            }
                        }
                    }
                }
                catch { }
            }
        }
        if (this.props.actionsForAll) {
            for (let i = 0; i < this.props.actionsForAll.length; i++) {
                if (!((!this.props.actionsForAll[i]?.isMultiaction) && checkedList.length > 1))
                    result.push(this.props.actionsForAll[i]);
            }
        }
        return result;
    }
    handlerCheckAll(event) {
        if (event.target.checked) {
            let checkedList = [];
            let items = this.props.items;
            for (var i = 0; i < items.length; i++) {
                checkedList.push(items[i].columns.Id);
            }
            this.setState({ allChecked: true, checkedList: checkedList, showActionsPanel: true, actions: this.getActions(checkedList) });
            if (this.props.onCheckChangeHandler)
                this.props.onCheckChangeHandler(checkedList);
            globalEventDispatcher.dispatch(dispatcherActionTypes.onRowSelected, { tableName: this.state.name });
        }
        else {
            this.setState({ allChecked: false, checkedList: [], showActionsPanel: false, actions: [] });
            if (this.props.onCheckChangeHandler)
                this.props.onCheckChangeHandler([]);
        }
    }
    handlerCheck(event) {
        let isChecked = event.target.checked;
        let { checkedList, singleSelect } = this.state;
        let isAll = false;
        let showActionsPanel = true;
        let index = checkedList.indexOf(event.target.id);
        if (isChecked) {
            if (index < 0) {
                if (singleSelect) {
                    checkedList = [event.target.id];
                }
                else {
                    checkedList.push(event.target.id);
                }
                if (this.props.items && checkedList.length === this.props.items.length)
                    isAll = true;
            }
            else {
                isAll = this.state.allChecked;
            }
            globalEventDispatcher.dispatch(dispatcherActionTypes.onRowSelected, { tableName: this.state.name });
        }
        else {
            if (index >= 0)
                checkedList.splice(index, 1);
            if (checkedList.length <= 0)
                showActionsPanel = false;
        }
        this.setState(
            {
                checkedList: checkedList,
                allChecked: isAll,
                showActionsPanel: showActionsPanel,
                actions: showActionsPanel ? this.getActions(checkedList) : []
            }
        );
        if (this.props.onCheckChangeHandler)
            this.props.onCheckChangeHandler(checkedList);
    }
    sort(name) {
        let { sortBy, isDesk } = this.state;
        if (name === sortBy)
            isDesk = !isDesk;
        else
            isDesk = false;
        this.setState({ sortBy: name, isDesk: isDesk });
        if (this.props.handleSortChange)
            this.props.handleSortChange(name, isDesk);
    }
    handlePageChange(page) {
        this.setState({ allChecked: false, checkedList: [], showActionsPanel: false, actions: [] });
        if (this.props.handlePageChange)
            this.props.handlePageChange(page);
        if (this.props.onCheckChangeHandler)
            this.props.onCheckChangeHandler([]);
    }
    handleChangePageSize(pageSize) {
        this.setState({ allChecked: false, checkedList: [], showActionsPanel: false, actions: [] });
        if (this.props.handleChangePageSize)
            this.props.handleChangePageSize(pageSize);
        if (this.props.onCheckChangeHandler)
            this.props.onCheckChangeHandler([]);
    }
    handlerUncheckAll() {
        this.setState({ allChecked: false, checkedList: [], showActionsPanel: false, actions: [] });
        if (this.props.onCheckChangeHandler)
            this.props.onCheckChangeHandler([]);
    }
    actionHandlerCallback(type) {
        if (this.props.actionHandlerCallback) {
            this.props.actionHandlerCallback(type, this.state.checkedList);
        }
    }
    render() {
        let { columns, items, allCount, showPagination, page, pageSize, allowedEnumValues, pageCount, showMoreLink, actionHandlerCallback } = this.props;
        const { sortBy, isDesk, allChecked, checkedList, actions, showActionsPanel, name, singleSelect } = this.state;
        let index = 0;
        if (!page)
            page = 1;
        if (!pageSize)
            pageSize = 10;
        if (!pageCount)
            pageCount = 1;
        if (!allowedEnumValues)
            allowedEnumValues = {};
        return (
            <div className="table-block">
                <div className="table-response">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    {!singleSelect && [
                                        <input id={name + "s1"} type="checkbox" onChange={this.handlerCheckAll} checked={!!allChecked} />,
                                        <label htmlFor={name + "s1"}></label>
                                    ]}
                                </th>
                                {columns.map(column =>
                                    <th key={column.id ?? v4()} className={column.canSort ? "sorting-col" : ""} name={column.name} style={{ display: (column.hidden === true ? "none" : "table-cell") }}>
                                        {sortBy === column.name && (!isDesk ?
                                            <svg onClick={column.canSort ? () => this.sort(column.name) : () => { }} className="order" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 8L1.41 9.41L7 3.83V16H9V3.83L14.58 9.42L16 8L8 0L0 8Z" fill="black" />
                                            </svg>
                                            :
                                            <svg onClick={column.canSort ? () => this.sort(column.name) : () => { }} className="order" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z" fill="black" />
                                            </svg>
                                        )
                                        }
                                        <span onClick={column.canSort ? () => this.sort(column.name) : () => { }}>
                                            {column.headerName}
                                        </span>
                                        {column.showFilterInColumn !== null && column.showFilterInColumn && column.enums && column.enums.length > 0 &&
                                            <ColumnFilter allowedValues={allowedEnumValues[column.name]} onColumnFilter={this.props.onColumnFilter} column={column} />
                                        }
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <TableRow key={index++} handlerCheck={this.handlerCheck} allowDoubleClickAction={this.props.allowDoubleClickAction} handlerAction={actionHandlerCallback} cols={columns} checked={item && item.columns && item.columns.Id && checkedList.includes(item.columns.Id)} item={item}></TableRow >
                            ))}
                        </tbody>
                    </table>
                </div>
                {showPagination &&
                    <Pagination
                        page={page}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        allCount={allCount}
                        handlePageChange={this.handlePageChange}
                        handleChangePageSize={this.handleChangePageSize} />
                }
                {showMoreLink &&
                    <a className="more" onClick={() => History.push("/documents")}>
                        <span>{i18next.t("allDocuments")}</span>
                        <span className="collapsed-menu-icone"></span>
                    </a>
                }
                {showActionsPanel && actions && actions.length > 0 &&
                    <ActionsPanel actions={actions} handlerUncheckAll={this.handlerUncheckAll} actionHandlerCallback={this.actionHandlerCallback} checkedCount={checkedList.length} />
                }
            </div>
        );
    }
}
export function getEnumsTitle(collumnsSettings, collumnName, value) {
    return collumnsSettings?.find((item) => item?.name === collumnName)?.enums?.find((item) => item?.value === value)?.title;
}
