import React from 'react';
import { Svg } from '../Svg';
export function FoldersListBlock(props) {
    const onChangeHandler = (name) => {
        if (props.onChangeHandler)
            props.onChangeHandler(name);
    }
    const { folders, baseUrl, current } = props;
    let pathArray = window.location.href.split(baseUrl + "/");
    let currentName = current;
    if (!currentName) {
        currentName = (folders && folders.length > 0) ? folders[0].name : "";
        if (pathArray.length > 1)
            currentName = pathArray[pathArray.length - 1].split('?')[0];
        currentName = decodeURI(currentName);
    }
    return (folders && folders.length > 0 ?
        <div className="folders-block-header">
            <ul>
                {folders.map((folder) => (
                    <li key={folder.name} className={currentName.toLowerCase() === folder.name.toLowerCase() ? "selected" : ""}>
                        {baseUrl ?
                            <a href={baseUrl + "/" + folder.name} onClick={() => onChangeHandler(folder.name)}>
                                <Svg src="/img/folder.svg" />
                                <span>{folder.title}</span>
                            </a> :
                            <a onClick={() => onChangeHandler(folder.name)}>
                                <Svg src="/img/folder.svg" />
                                <span>{folder.title}</span>
                            </a>
                        }
                    </li>
                ))}
            </ul>
        </div> :
        ""
    );
}