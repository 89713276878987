import i18next from "../i18n";
export default {
    "Columns": [
        {
            "name": "Id",
            "headerName": "",
            "type": "string",
            "hidden": true
        },
        {
            "name": "FileName",
            "CanSort": true,
            "headerName": i18next.t("documentName"),
            "type": "string"
        },
        {
            "name": "CreatorFullName",
            "CanSort": true,
            "headerName": i18next.t("author"),
            "type": "string"
        },
        {
            "name": "Created",
            "headerName": i18next.t("dateAddition"),
            "CanSort": true,
            "type": "date"
        }
    ]
}