import { filterDividers, History, urls, formStringFieldPatterns } from '../../constants';
const mime = require('mime-types');
export function getArrayIds(columns) {
    let result = [];
    if (columns) {
        for (var i = 0; i < columns.length; i++)
            result.push(columns[i].id);
    }
    return result;
}
export function getFilterString(filterValues) {
    let filter = '';
    let addedCount = 0;
    let keys = filterValues ? Object.keys(filterValues) : [];
    for (var i = 0; i < keys.length; i++) {
        let values = filterValues[keys[i]];
        if (values.length > 0) {
            let needAdd = false;
            let addedPart = '';
            if (addedCount > 0)
                addedPart += filterDividers.FilterPartsDivider;
            addedPart += keys[i] + filterDividers.FilterNameValueDivider;
            for (var j = 0; j < values.length; j++) {
                if (values[j]) {
                    if (j > 0)
                        addedPart += filterDividers.FilterValuesDivider;
                    addedPart += encodeURIComponent(values[j]);
                    needAdd = true;
                }
            }
            if (needAdd) {
                addedCount++;
                filter += addedPart;
            }
        }
    }
    return filter;
}
export function parseFilterString(filter) {
    let filterValues = {};
    if (!filter) return filterValues;
    filter = decodeURIComponent(filter);
    let parts = filter.split(filterDividers.FilterPartsDivider);
    for (var i = 0; i < parts.length; i++) {
        let parts2 = parts[i].split(filterDividers.FilterNameValueDivider);
        if (parts2.length > 1 && parts2[0] && parts2[1]) {
            let key = parts2[0];
            let value = parts2[1];
            var valueParts = value.split(filterDividers.FilterValuesDivider);
            filterValues[key] = valueParts;
        }
    }
    return filterValues;
}
export async function getBase64(file, witMimType = false) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
    if (file.size === 0)
        return { mimeType: file.type, content: witMimType === true ? `data:${mime.lookup(file.name)};base64,0` : "0" };
    if (!witMimType) {
        let index = result_base64.lastIndexOf(",");
        if (index !== -1) {
            result_base64 = result_base64.slice(index + 1)
        }
    }
    return { mimeType: file.type, content: witMimType ? result_base64 : result_base64.split(";").pop() };
}
export function readAsUint8Array(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
        reader.onloadend = ({ target: { result } }) => resolve(new Uint8Array(result));
        reader.onerror = reject;
    });
}
export function sortArray(items, fildName, isAsk = true) {
    items.sort(function (a, b) {
        var index1 = a[fildName];
        var index2 = b[fildName];
        if (index1 === null || index1 === undefined)
            index1 = 0;
        if (index2 === null || index2 === undefined)
            index2 = 0;
        let result = 0;
        if (index1 < index2) result = -1;
        if (index1 > index2) result = 1;
        if (!isAsk)
            result *= -1;
        return result;
    });
}
export function ExportToCSV(columns, items, fileName = "") {
    var csvContent = "Номер";
    if (columns && columns.length > 0) {
        for (var column of columns) {
            if (!(column.hidden === true))
                csvContent += ';"' + column.headerName.replaceAll('"', '""') + '"';
        }
    }
    csvContent += "\r\n";
    let index = 1;
    if (items && items.length > 0) {
        for (var item of items) {
            csvContent += index;
            for (var column of columns) {
                try {
                    if (!(column.hidden === true)) {
                        csvContent += ';"' + item.columns[column.name].replaceAll('"', '""') + '"';;
                    }
                }
                catch { csvContent += ";"; }
            }
            csvContent += "\r\n";
            index++;
        }
    }
    if (!fileName) fileName = "report.csv";
    if (!fileName.endsWith(".csv")) fileName += ".csv";
    var encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
    if (window.navigator.msSaveBlob) { // IE
        csvContent = '\ufeff' + csvContent;
        var blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8,\uFEFF" });
        window.navigator.msSaveOrOpenBlob(blob, "report.csv");
    }
    else if (window.saveAs) {
        var blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8,\uFEFF" });
        window.saveAs(blob, "report.csv");
    }
    else {
        var link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8,\uFEFF" + encodedUri);
        link.setAttribute("download", fileName);
        link.dispatchEvent(new MouseEvent('click'));
    }
}
export const transliterationOfFirstLettersTable = {
    'Є': 'Ye', 'є': 'ye', 'Ї': 'Yi', 'ї': 'yi', 'Й': 'Y', 'й': 'y', 'Ю': 'Yu', 'ю': 'yu', 'Я': 'Ya', 'я': 'ya'
}
export const transliterationTable = {
    'А': 'A', 'а': 'a', 'Б': 'B', 'б': 'b', 'В': 'V', 'в': 'v', 'Г': 'H', 'г': 'h', 'Ґ': 'G', 'ґ': 'g', 'Д': 'D', 'д': 'd', 'Е': 'E', 'е': 'e', 'Є': 'Ie', 'є': 'ie', 'Ж': 'Zh', 'ж': 'zh',
    'З': 'Z', 'з': 'z', 'И': 'Y', 'и': 'y', 'І': 'I', 'і': 'i', 'Ї': 'I', 'ї': 'i', 'Й': 'I', 'й': 'i', 'К': 'K', 'к': 'k', 'Л': 'L', 'л': 'l', 'М': 'M', 'м': 'm', 'Н': 'N', 'н': 'n', 'О': 'O', 'о': 'o',
    'П': 'P', 'п': 'p', 'Р': 'R', 'р': 'r', 'С': 'S', 'с': 's', 'Т': 'T', 'т': 't', 'У': 'U', 'у': 'u', 'Ф': 'F', 'ф': 'f', 'Х': 'Kh', 'х': 'kh', 'Ц': 'Ts', 'ц': 'ts', 'Ч': 'Ch', 'ч': 'ch', 'Ш': 'Sh', 'ш': 'sh',
    'Щ': 'Shch', 'щ': 'shch', 'Ю': 'Iu', 'ю': 'iu', 'Я': 'Ia', 'я': 'ia', 'Ь': '', 'ь': ''
}
export function transliterateString(str, ownName = true) {
    if (!str)
        return str;
    var re = /^.{0,}[а-яa-z].{0,}$/;
    var isUpper = !re.test(str);
    re = /([A-Za-zА-Яа-я]{1,})['`]{1,}([A-Za-zА-Яа-я]{1,})/g;
    str = str.replace(re, '$1$2');
    if (!ownName) {
        str = str.replaceAll('зг', 'zgh');
        str = str.replaceAll('Зг', 'Zgh');
        str = str.replaceAll('зГ', 'zGH');
        str = str.replaceAll('ЗГ', 'ZGH');
    }
    for (var key in transliterationOfFirstLettersTable) {
        re = new RegExp(`(\b)${key}`, 'g');
        str = str.replace(re, '$1' + transliterationOfFirstLettersTable[key]);
    }
    for (var key in transliterationTable) {
        str = str.replaceAll(key, transliterationTable[key]);
    }
    if (isUpper) str = str.toUpperCase();
    return str;
}
export function urltoFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}
export function getCurrentCompanyInfo() {
    return fetch('/home/getCurrentCompanyInfo')
        .then((response) => {
            return response.json();
        });
}
export function setUrlParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);
    var kvp = document.location.search.substr(1).split('&');
    let i = 0;
    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }
    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join('=');
    }
    let params = kvp.join('&');
    if (params.length > 0) {
        if (params[0] === "&") {
            params = params.replace("&", "?");
        }
        else {
            params = "?" + params;
        }
    }
    History.push(document.location.pathname + params);
}
export function getUrlParam(param) {
    let result = '';
    let parts = window.location.search.substring(1).split('&');
    if (parts.length > 0) {
        for (var i in parts) {
            var parts2 = parts[i].split('=');
            if (parts2.length > 1 && parts2[0].toLocaleLowerCase() === param.toLocaleLowerCase()) {
                result = parts2[1].toLocaleLowerCase();
                break;
            }
        }
    }
    return result;
}
export function getCurrentUrlPath() {
    let curUrl = window.location.pathname;
    if (curUrl && curUrl.length > 1 && curUrl[curUrl.length - 1] === '/')
        curUrl = curUrl.substring(0, curUrl.length - 1);
    return curUrl;
}
export function getWordWithCorrectEnding(number, word) {
    switch (word) {
        case 'спроба':
            {
                let lastTwoNumbers = Math.round(((number / 100) % 1) * 100);
                if (number % 0 === 0 || number % 5 === 0 || number % 6 === 0 || number % 7 === 0 || number % 8 === 0 || number % 9 === 0 || (lastTwoNumbers >= 11 && lastTwoNumbers <= 19))
                    return 'спроб';
                if (number % 2 === 0 || number % 3 === 0 || number % 4 === 0)
                    return 'спроби';
                if (number % 1)
                    return 'спроба';
            }
            break;
    }
    return word;
}
export function base64toBlob(data, mimeType) {
    const prefix = `data:${mimeType};base64,`;
    if (data.startsWith(prefix)) {
        data = data.substr('data:application/pdf;base64,'.length);
    }
    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: mimeType });
};
export async function logout() {
    const response = await fetch('/Account/LogOut', { method: "POST" });
    if (response.ok || response.status === 401 || !localStorage.getItem('user')) {
        localStorage.removeItem('user');
        document.location.replace(urls.loginPage);
    }
    else require('../ModalDialog').unknownErrorMessageDialog();
};
export function changeInputValue(e) {
    let { name, type, value, checked } = e.target;
    if (e.target) {
        switch (e.target.getAttribute('pattern')) {
            case formStringFieldPatterns.integer:
                value = value.replace(/\D/g, "");
                break;
            default: break;
        }
    }
	this.setState({ [name]: type === "checkbox" ? checked : value });
}
export const uint8ToBase64 = (arr) => btoa(Array(arr.length).fill('').map((_, i) => String.fromCharCode(arr[i])).join(''));
export const objToLowercase = obj => Object.keys(obj).reduce((acc, key) => { acc[key.toLowerCase()] = obj[key]; return acc; }, {});
export function setOnPageCount(count) {
    localStorage.setItem('onPageCount', count);
}
export function getOnPageCount() {
    let count = parseInt(localStorage.getItem('onPageCount') ?? 10);
    if (count > 100)
        count = 100;
    return count;
}
export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
export function parseUtcDateString(date) {
    let result = '';
    try {
        if (date) {
            date = date.split('T')[0];
            let dateParts = date.split('-');
            if (dateParts.length === 3)
                result = date;
        }
    }
    catch (ex) {
        console.log('parseUtcDateString error');
        console.log(ex);
    }
    return result;
}
export function getLocalDateString(date) {
    try {
        return date.toLocaleDateString("uk-UA", { timeZone: 'Europe/Kiev' });
    }
    catch (ex) {
        console.log('getLocalDateString error');
        console.log(ex);
        return date.toLocaleDateString("uk-UA");
    }
}
export function getLocalTimeString(date) {
    try {
        return date.toLocaleTimeString("uk-UA", { timeZone: 'Europe/Kiev' });
    }
    catch (ex) {
        console.log('getLocalTimeString error');
        console.log(ex);
        return date.toLocaleTimeString("uk-UA");
    }    
}
export function capitalizeObjectKeys(obj) {
    Object.keys(obj).forEach(key => {
        if (key && key.length > 0) {
            let newKey = key[0].toUpperCase() + key.substring(1);
            if (obj.hasOwnProperty(key) && !obj.hasOwnProperty(newKey)) {
                obj[newKey] = obj[key];
                delete obj[key];
            }
        }
    });
    return obj;
}
export function deepObjectEqual(object1, object2) {
    if (!object1 && !object2)
        return true;
    if (!object1 || !object2)
        return false;

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepObjectEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}
export function isObject(object) {
    return object != null && typeof object === 'object';
}
export function getCurrentCompany() {
    let user = JSON.parse(localStorage?.getItem('user'));
    return user?.companies[0]
}
export function onKeyDown(e) {
    if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
    }
}