import React from 'react';
import i18next from "../../i18n";
import * as constants from '../../constants';
import { getArrayIds } from '../helpers/HelperFunctions';
import { Table } from '../table/Table';
import { TableFilterBlock } from '../table/TableFilterBlock';
import { loadListData } from './ListHelpers';
export async function loadData(page, pageSize, sortBy = '', isDesk = false, filterValues = []) {
    await loadListData.bind(this)(`Table/Get?searchTableTypes=${constants.searchTableTypes.Users}&withAction=${false}`, page, pageSize, sortBy, isDesk, filterValues);
}
export async function actionHandlerCallback(actionType, ids) {
    const { content } = this.state;
    const { prop_sys_node_uuid } = this.props;
    if (ids.length > 0) {
        switch (actionType) {
            case constants.actionTypes.add:
                let rows = content.rows.filter(row => ids.indexOf(row.columns['Id']) !== -1);
                return await fetch(`document/changeTaslExecuter?email=${rows[0].columns.Email}&id=${prop_sys_node_uuid}`)
                    .then(async (response) => {
                        if (!response.ok) require('../ModalDialog').unknownErrorMessageDialog();
                        else window.location.reload()
                    });
            default: await this.actionHandlerCallbackDefault(actionType, ids);
        }
    }
}
export function renderContent() {
    const { content, page, pageSize, pageCount, sortBy, isDesk, filterValues } = this.state;
    if (content) content.actionsForAll = [
        { "title": i18next.t("15"), "type": constants.actionTypes.add, "isMultiaction": false, "btnClass": "btn-primary", "sortIndex": -1 }
    ];
    return (<div>
        <TableFilterBlock filterValues={filterValues} exportFileName="users" onSearch={this.onSearch} onSearchClear={this.onSearchClear} onColumnsUpdateHandler={this.onColumnsUpdateHandler} columnsSettings={{ columns: (content && content.allColumns) ? content.allColumns : [], selected: (content && content.columns) ? getArrayIds(content.columns) : [] }} items={content ? content.rows : []} getItems={this.getItemsForExport} />
        {content && content.columns &&
            <Table onColumnFilter={this.onColumnFilter} allowDoubleClickAction={true} allCount={content.allCount} handleSortChange={this.handleSortChange} actionHandlerCallback={this.actionHandlerCallback} actionsForAll={content.actionsForAll} columns={content.columns} sortBy={sortBy} isDesk={isDesk} items={content.rows} showPagination={true} page={page} pageSize={pageSize} pageCount={pageCount} handlePageChange={this.handlePageChange} handleChangePageSize={this.handleChangePageSize} actionsForAll={content.actionsForAll} />
        }
    </div>);
}