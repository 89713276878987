import { actionTypes, } from '../constants';
import i18next from "../i18n";
export default {
    "columns": [
        {
            "name": "Id",
            "headerName": "",
            "type": "string",
            "hidden": true
        },
        {
            "name": "prop_sx_addDocumentType_label",
            "CanSort": true,
            "headerName": i18next.t("documentType"),
            "type": "string",
            "showFilterInColumn": true
        },
        {
            "name": "documentNumber",
            "headerName": i18next.t("number"),
            "type": "string"
        },
        {
            "name": "documentDate",
            "headerName": i18next.t("date"),
            "type": "date"
        },
        {
            "name": "stateLabel",
            "headerName": i18next.t("status"),
            "type": "status",
            "showFilterInColumn": true
        },
        {
            "name": "comment",
            "headerName": i18next.t("comment"),
            "type": "comment"
        },
        {
            "name": "creatorFullName",
            "headerName": i18next.t("author"),
            "type": "string"
        },
        {
            "name": "Indicator",
            "headerName": "",
            "type": "default"
        }
    ],
    actions: [
        {
            "title": i18next.t("add"),
            "btnClass": "",
            "type": actionTypes.add
        },
        {
            "title": i18next.t("view"),
            "btnClass": "btn-primary",
            "type": actionTypes.preview
        },
        {
            "title": "Немає документа",
            "btnClass": "btn-secondary",
            "type": actionTypes.notAdd
        },
        {
            "title": i18next.t("sign"),
            "btnClass": "",
            "type": actionTypes.sign,
            "isMultiaction": true
        },
        {
            "title": i18next.t("edit"),
            "btnClass": "btn-secondary",
            "type": actionTypes.edit
        },
        {
            "title": i18next.t("download"),
            "btnClass": "btn-secondary",
            "type": actionTypes.download,
            "isMultiaction": true
        },
        {
            "title": i18next.t("remove"),
            "btnClass": "btn-no-fill",
            "type": actionTypes.remove
        }
    ]
}