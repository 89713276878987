import React, { Component } from 'react';
import { Svg } from '../Svg';
export class ColumnFilter extends Component {
    constructor(props) {
        super(props);
        let column = this.props.column;
        let allowedValues = this.props.allowedValues;
        var checked = column.enums.filter(it => column.filteredValues && column.filteredValues.includes(it.value));
        checked = checked.filter(it => !allowedValues || allowedValues.includes(it.value));
        if (checked.length === 0)
            checked = column.enums.slice(0).filter(it => !allowedValues || allowedValues.includes(it.value));
        this.state = {
            showFilter: false,
            checkedList: checked,
            column: column
        }
        this.showFilterInColumn = this.showFilterInColumn.bind(this);
        this.handlerCheckAll = this.handlerCheckAll.bind(this);
        this.handlerCheck = this.handlerCheck.bind(this);
        this.clickListener = this.clickListener.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        let allowedValues = this.props.allowedValues;
        let enums = nextProps.column.enums.filter(it => !allowedValues || allowedValues.includes(it.value));
        var checked = enums.filter(it => nextProps.column.filteredValues && nextProps.column.filteredValues.includes(it.value));
        if (!nextProps.column.filteredValues)
            nextProps.column.filteredValues = [];
        if (checked.length === 0 && !(nextProps.column.filteredValues.length === 1 && nextProps.column.filteredValues[0] === 'hideAllValue'))
            checked = enums.slice(0);
        this.setState({ column: nextProps.column, checkedList: checked });
    }
    showFilterInColumn(e) {
        e.preventDefault();
        this.setState({ showFilter: !this.state.showFilter });
    }
    clickListener(e) {
        let targetId = e.target.id;
        const currentObject = this;
        if (targetId !== 'column-filter-btn' && !currentObject.hasParent(e.target, 'column-filter-btn') && targetId !== 'column-filter-list' && !currentObject.hasParent(e.target, 'column-filter-list'))
            currentObject.setState({ showFilter: false });
    }
    componentDidMount() {
        document.addEventListener('click', this.clickListener);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.clickListener, false);
    }
    hasParent(element, id) {
        while (element.parentElement) {
            if (element.parentElement.id === id) return true;
            element = element.parentElement;
        }
        return false;
    }
    handlerCheckAll(checked) {
        if (checked) {
            const { allowedValues } = this.props;
            let enums = this.props.column.enums.filter(it => !allowedValues || allowedValues.includes(it.value));
            if (this.state.checkedList.length < enums.length) {
                this.setState({ checkedList: enums.slice(0) });
                if (this.props.onColumnFilter)
                    this.props.onColumnFilter(this.props.column.name, []);
            }
        }
        else {
            if (this.state.checkedList.length > 0) {
                this.setState({ checkedList: [] });
                if (this.props.onColumnFilter)
                    this.props.onColumnFilter(this.props.column.name, ['hideAllValue']);
            }
        }
    }
    handlerCheck(item) {
        let { checkedList } = this.state;
        const { allowedValues } = this.props;
        const index = checkedList.indexOf(item);
        if (index >= 0) {
            checkedList = checkedList.filter(current => current !== item);
            this.setState({ checkedList: checkedList });
        }
        else {
            checkedList.push(item);
            this.setState({ checkedList: checkedList });
        }
        if (this.props.onColumnFilter) {
            let enums = this.props.column.enums.filter(it => !allowedValues || allowedValues.includes(it.value));
            if (checkedList.length === enums.length)
                this.props.onColumnFilter(this.props.column.name, []);
            else if (checkedList.length === 0)
                this.props.onColumnFilter(this.props.column.name, ['hideAllValue']);
            else {
                let values = [];
                checkedList.map(item => values.push(item.value));
                this.props.onColumnFilter(this.props.column.name, values);
            }
        }
    }
    render() {
        const { column, showFilter, checkedList } = this.state;
        const { allowedValues } = this.props;
        let allChecked = checkedList.length === column.enums.filter(it => !allowedValues || allowedValues.includes(it.value)).length;
        let index = 0;
        return (
            <div className="column-filter">
                <Svg src="/img/filter.svg" onClick={this.showFilterInColumn} id="column-filter-btn" />
                {showFilter &&
                    <ul id="column-filter-list">
                        <li onClick={(e) => { e.preventDefault(); this.handlerCheckAll(!allChecked) }} key={"column-filter-list-li" + index++}>
                            <input id="column-filter-all-checkbox" type="checkbox" onChange={(e) => { e.preventDefault(); this.handlerCheckAll(!allChecked) }} checked={allChecked} />
                                <label htmlFor="column-filter-all-checkbox" className="select-row-check-label"></label>
                                <span>Обрати все</span>
                        </li>
                    {column.enums.filter(item => !allowedValues || allowedValues.length <= 0 || allowedValues.includes(item.value)).map(item =>
                            <li onClick={(e) => { e.preventDefault(); this.handlerCheck(item) }} key={"column-filter-list-li" + index++}>
                                <input id={"column-filter-checkbox" + item.value} onChange={(e) => { e.preventDefault(); this.handlerCheck(item) }}  type="checkbox" checked={checkedList.includes(item)} />
                                <label htmlFor={"column-filter-checkbox" + item.value} className="select-row-check-label"></label>
                                <span>{item.title}</span>
                            </li>
                        )
                        }
                    </ul>
                }
            </div>
        );
    }
}
