import { documentTabs } from './Document';
import { formModes } from '../../constants';
import i18next from "../../i18n";
export function checkInputPage() {
    const checkResult = { error: '', incorrectTab: documentTabs.information };
    const { prop_sx_caDocDate, prop_sx_caDocKind, prop_sx_caDocNumber, formMode, prop_sx_cacontents, associations, addedContractId, file } = this.state;
    if (!(prop_sx_caDocDate && prop_sx_caDocKind && prop_sx_caDocNumber && prop_sx_cacontents)) {
        checkResult.error = i18next.t('notAllRequiredFildsAreSpecifiedMessage');
    }
    else if (prop_sx_caDocKind === 'Doc_C33' && (formMode === formModes.create ? !addedContractId : associations?.universalContractDoc?.length <= 0)) {
        checkResult.error = i18next.t('contractNotAdded');
    }
    else if (!file && !associations?.mainContent?.length > 0) {
        checkResult.error = i18next.t('mainFileNotAdded');
    }
    return checkResult;
}