import React, { Component } from 'react';
import { UserContext, History } from '../../constants';
import { ListOfSearchResults } from '../listsOfAlfresco/ListOfSearchResults';
import i18next from "../../i18n";
import { MenuItem } from './MenuItem';
import { CopyrightItem } from './CopyrightItem';
export class LeftMenu extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            sections: props.data,
            loading: true,
            isMenuOpen: this.props.isOpen,
            search: ListOfSearchResults.getSearchStr(),
            expandedLink: window.location.pathname
        };
        this.onSearch = this.onSearch.bind(this);
    }
    setExpandedLink(link) {
        this.setState({
            expandedLink: link
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.isOpen !== this.state.isMenuOpen || prevProps.data !== this.props.data) {
            this.setState({
                isMenuOpen: this.props.isOpen,
                sections: this.props.data
            });
        }
    }
    hideOverley() {
        this.setState({ isMenuOpen: false })
        if (this.props.handleOpenLeftMenu) {
            this.props.handleOpenLeftMenu(false);
        }
    }
    onSearch() {
        var searchInput = document.getElementById("main-search-input");
        if (searchInput.value) {
            this.setState({ search: searchInput.value });
            History.push('/search?search=' + searchInput.value);
        }
    }
    keypressSearchInput(e) {
        if ((e.keyCode ? e.keyCode : e.which) === 13) { //Enter keycode                        
            e.preventDefault();
            this.onSearch();
        }
    }
    render() {
        const { isMenuOpen } = this.state;
        const menuItems = this.state.sections?.Menu?.menuItems;
        const copyright = this.state.sections?.Copyright;
        const { title } = copyright;
        const user = this.context;
        return (
            <>
                <div className={"left-menu menuBar" + (isMenuOpen === true ? ' open' : ' ')}>
                    {menuItems ? <div>
                        <div className="search-menu-block">
                            <img className="search-menu-icone" src="/img/menu-search.svg" onClick={this.onSearch} alt="" />
                            <input id="main-search-input" type="search" placeholder={i18next.t("searchInSections")} value={this.state.search} className="left-menu-search" onChange={(e) => this.setState({ search: e.target.value })} onKeyPress={this.keypressSearchInput.bind(this)} />
                        </div>
                        <div className="menu-line" />
                        <ul className="left-menu-items">
                            {menuItems && menuItems.map((item, index) =>
                                <MenuItem handlerGetExpandedLink={() => this.state.expandedLink} handlerSetExpandedLink={this.setExpandedLink.bind(this)}
                                    key={item.name ? item.name : index++} name={item.name} link={item.link} collapsed={!item.expanded} image={item.image} counter={item.counter} isRoot={true}
                                    type={item.type} menuItems={item.menuItems} />
                            )}
                            {title ? <li className={"left-menu-item-title "}>{title}</li> : ""}
                            {copyright?.menuItems?.map((item, index) =>
                                <CopyrightItem key={item.name ? item.name : index++} name={item.name} link={item.link} image={item.image} isRoot={true} type={item.type} />
                            )}
                        </ul>
                        {user.companyShortName && <span className="text">© {user.companyShortName}</span>}
                    </div> : ""}
                </div>
                <div className={"overly" + (isMenuOpen === true ? ' open' : ' ')} onClick={this.hideOverley.bind(this)}></div>
                <div id="feedbackDialog"></div>
                <div id="userInstructionDialog"></div>
            </>
        );
    }
}