import { History } from '../../constants';
class Dispatcher {
    constructor() {
        this.callBacks = [];
        this.sessionTime = 0;
        this.timerIds = [];
        this.setSesionTimeout();
    }
    logoutUser() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            clearTimeout(this.timerId);
            localStorage.removeItem('user');
            History.push('/session-ended');
        }
    }
    subscribe(callback) {
        if (this.callBacks.findIndex(f => f === callback) < 0)
            this.callBacks.push(callback);
    }
    unsubscribe(callback) {
        let index = this.callBacks.findIndex(f => f === callback);
        if (index >= 0) this.callBacks.splice(index, 1);
    }
    dispatch(eventType, params) {
        for (var func of this.callBacks) {
            func(eventType, params);
        }
        let id = this.timerIds.pop();
        while (id) {
            clearTimeout(id);
            id = this.timerIds.pop();
        }
        this.setSesionTimeout();
    }
    setSesionTimeout() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.sessionTime > 0) {
            this.timerIds.push(setTimeout(this.logoutUser, user.sessionTime * 60000));
        }
    }
}
export const globalEventDispatcher = new Dispatcher();
export const dispatcherActionTypes = {
    onCompanyUpdated: 0,
    onCurrentUserUpdated: 1,
    onUnselectTableAllRows: 2,
    onRowSelected: 3,
    onUserAction: 4,
    onTabChanged: 5,
    onMenuChanged: 6,
    onDocInfoClicked: 7,
}