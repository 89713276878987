import React from 'react';
import { actionTypes, settingsFormTypes } from '../../constants';
import { getArrayIds } from '../helpers/HelperFunctions';
import { Table } from '../table/Table';
import { TableFilterBlock } from '../table/TableFilterBlock';
export async function actionHandlerCallback(actionType, ids = []) {
    const { userId } = this.props;
    await this.actionHandlerCallbackDefault(actionType, ids);
}
export function renderContent() {
    const { content, sortBy, isDesk, page, pageSize, pageCount, filterValues } = this.state;
    const { type, hideFilter } = this.props;
    if (content) content.actionsForAll = [{
        "title": "Додати",
        "type": actionTypes.add,
        "isMultiaction": false,
        "btnClass": "btn-primary",
        "sortIndex": -1
    }];
    return (
        <div>
            {type !== settingsFormTypes.settingsType && !hideFilter &&
                <TableFilterBlock filterValues={filterValues} onSearch={this.onSearch} exportFileName="companies" onSearchClear={this.onSearchClear} onColumnsUpdateHandler={this.onColumnsUpdateHandler} columnsSettings={{ columns: (content && content.allColumns) ? content.allColumns : [], selected: (content && content.columns) ? getArrayIds(content.columns) : [] }} items={content ? content.rows : []} getItems={this.getItemsForExport} handleUpdate={this.handleReloadData} />
            }
            {content && content.columns &&
                <Table onColumnFilter={this.onColumnFilter} allowDoubleClickAction={true} allCount={content.allCount} handleSortChange={this.handleSortChange} actionHandlerCallback={this.actionHandlerCallback} actionsForAll={content.actionsForAll} columns={content.columns} items={content.rows} showPagination={type !== settingsFormTypes.settingsType} sortBy={sortBy} isDesk={isDesk} page={page} pageSize={pageSize} pageCount={pageCount} handlePageChange={this.handlePageChange} handleChangePageSize={this.handleChangePageSize} />
            }
        </div>
    );
}