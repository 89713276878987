import React from 'react';
import {Route} from 'react-router-dom';
import { ListOfAlfresco } from '../components/listsOfAlfresco/ListOfAlfresco';
import * as ListOfDocuments from '../components/listsOfAlfresco/ListOfDocuments';
import { documentsRegisterRoutes } from '../constants';
import { Content } from './Content';
import { AccessContent } from './AccessContent';

export function DocumentsRegisterRoutes(props) {
    return (documentsRegisterRoutes.map((item, index) =>
        <Route key={index} exact path={item.path} >
            <Content>
                <AccessContent>
                    <div className="content">
                        <ListOfAlfresco type={item.type} withTabs={item.withTabs} saveApi={item.saveApi} parentComponent={ListOfDocuments} title={item.lable}>
                            {props.children}
                        </ListOfAlfresco>
                    </div>
                </AccessContent>
            </Content>
        </Route>
    ));
}