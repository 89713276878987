import { checkBankScore } from '../helpers/CheckFunctions';
import { documentTabs } from './Document';
import i18n from '../../i18n';
export function checkInputPage() {
	const { prop_sx_AgreementNumber, prop_sx_scoreSum, prop_sx_bankScore, associations, file } = this.state;
	const checkResult = { error: '', incorrectTab: documentTabs.information };
	if (!(prop_sx_AgreementNumber && prop_sx_scoreSum && checkBankScore(prop_sx_bankScore))) {
		checkResult.error = i18n.t("notAllRequiredFildsAreSpecifiedMessage");
	}
	else if (!(associations?.mainContent || file)) {
		checkResult.error = i18n.t("3");
	}
	return checkResult;
}