import React from 'react';
import moment from 'moment';
export class DateTimeInput extends React.Component {
    constructor(props) {
        super(props);
        const { value } = this.props;
        this.state = {
            valueToShow: value ? moment(value, "YYYY-MM-DD").format("DD.MM.YYYY") : '',
            value: value ? value.split('T')[0] : '',
            id: crypto.randomUUID()
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onFireFoxLabelClick = this.onFireFoxLabelClick.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
    }
    componentDidUpdate() {
        if (this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value,
                valueToShow: this.props.value ? moment(this.props.value, "YYYY-MM-DD").format("DD.MM.YYYY") : ''
            });
        }
    }
    onFocus(e) {
        const { onlyDate } = this.props;
        const { id } = this.state;
        e.target.type = onlyDate ? 'date' : 'datetime-local';

        let element = document.getElementById(id);
        if (element) {
            let firefoxLabel = element.querySelector('.fire-fox-date-label');
            if (firefoxLabel)
                firefoxLabel.classList.add('focus');
        }
    }
    onFocusOut(e) {
        const { id } = this.state;

        let element = document.getElementById(id);
        if (element) {
            let firefoxLabel = element.querySelector('.fire-fox-date-label');
            if (firefoxLabel)
                firefoxLabel.classList.remove('focus');
        }
    }
    handleChange(e) {
        const { value } = e.target;
        this.setState({
            value: value,
            valueToShow: value ? moment(value.includes('T') ? value.split('T')[0] : value, "YYYY-MM-DD").format("DD.MM.YYYY") : ''
        });
        if (this.props.onChange)
            this.props.onChange(e);
    }
    render() {
        const { value, valueToShow, id } = this.state;
        const { name, className, disabled, placeholder } = this.props;
        return (
            <div className="datetime-input-control-block date-input-contaner" id={id} key={id}>
                <input
                    type="date"
                    placeholder={placeholder}
                    onFocus={this.onFocus}
                    onBlur={this.onFocusOut}
                    className={'datetime-input-control ' + (className ? className : '')}
                    name={name}
                    value={value}
                    valuetoshow={valueToShow}
                    onChange={this.handleChange} disabled={disabled}
                />
                <div className={"fire-fox-date-label" + (disabled ? " disabled" : "")} onClick={this.onFireFoxLabelClick}>
                    {valueToShow}
                </div>
                <div className={"fire-fox-date-icon" + (disabled ? " disabled" : "")}>
                    <img src="/img/calendar.svg" alt="" />
                </div>
            </div>
        );
    }

    onFireFoxLabelClick(e) {
        const { id } = this.state;

        let element = document.getElementById(id);
        if (element) {
            let input = element.querySelector('input');
            if (input)
                input.focus();
        }
    }
}