import React, { Component } from 'react';
import { urls } from '../constants';
import { NavMenu } from './menu/NavMenu';
import { LeftMenu } from './menu/LeftMenu';
import { globalEventDispatcher, dispatcherActionTypes } from './helpers/GlobalEventDispatcher';
export class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenLeftMenu: false
        };
        this.globalEventListerner = this.globalEventListerner.bind(this);
        globalEventDispatcher.subscribe(this.globalEventListerner);
    }
    componentDidMount() {
        fetch('home/navigation').then(async (response) => {
            if (!response.ok) return window.location.replace(urls.loginPage);
            const data = await response.json();
            this.setState({ data: data });
        });
    }
    globalEventListerner(actionType) {
        if (actionType === dispatcherActionTypes.onMenuChanged) {
            this.componentDidMount();
        }
    }
    handleOpenLeftMenu(open) {
        this.setState({
            isOpenLeftMenu: open
        })
    }
    render() {
        const { isOpenLeftMenu, data } = this.state;
        return (
            <>
                {data ? <NavMenu {...data} handleOpenLeftMenu={this.handleOpenLeftMenu.bind(this)} isOpenLeftMenu={isOpenLeftMenu} /> : ""}
                {data ? <div className="main-block">
                    <LeftMenu data={data} isOpen={isOpenLeftMenu} handleOpenLeftMenu={this.handleOpenLeftMenu.bind(this)} />
                    {this.props.children}
                </div> : ""}
            </>
        );
    }
}



