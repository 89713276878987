import React, { Component } from 'react';
import { settingsFormTypes } from '../../constants';
import i18next from "../../i18n";
import { checkEmail } from '../helpers/CheckFunctions';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
export class UserInvitationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            sending: false,
            submitted: false,
            codeNotExists: false,
            role: '',
            formType: this.props.formType,
            companyRegisterCode: '',
            selectedRegisterCode: '',
            companies: this.props.companies,
            rolesList: [],
            incorrectLogin: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    handleChange(event) {
        const { name, value } = event.target;
        if (name === 'email')
            this.setState({ [name]: value, incorrectLogin: false });
        else if (name === 'companyRegisterCode' || name === 'personeRegisterCode' || name === 'role')
            this.setState({ [name]: value, codeNotExists: false });
        else
            this.setState({
                [name]: value
            });
    }
    handleSubmit(event) {
        event.preventDefault();
        const { email, role, sending, incorrectLogin, companyRegisterCode, codeNotExists, formType, selectedRegisterCode } = this.state;
        let registerCode = formType === settingsFormTypes.settingsType ? selectedRegisterCode : companyRegisterCode;
        this.setState({ submitted: true });
        if (
            !sending &&
            !incorrectLogin &&
            !codeNotExists &&
            email &&
            checkEmail(email) &&
            role &&
            registerCode
        ) {
            this.setState({ sending: true });
            this.inviteUser(email, role, registerCode);
        }
    }
    async inviteUser(email, role, registerCode) {
        const currentObject = this;
        fetch('/siteSettings/inviteUser', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, role, registerCode })
        })
            .then((response) => {
                if (response.ok) {
                    currentObject.setState({ sending: false, incorrectLogin: false, codeNotExists: false });
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message" dangerouslySetInnerHTML={{ __html: i18next.t("invitationSentMessage") }}></div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
                    return '';
                }
                else {
                    if (response.status === 404) {
                        showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("invitationCompanyNotFound")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
                    }
                    else {
                        return response.text().then((errorText) => {
                            if (errorText === "EmailExists") {
                                currentObject.setState({ sending: false, incorrectLogin: true, codeNotExists: false });
                            }
                            else if (errorText === "CodeNotExists") {
                                currentObject.setState({ sending: false, incorrectLogin: false, codeNotExists: true });
                            }
                            else {
                                currentObject.setState({ sending: false, incorrectLogin: false, codeNotExists: false });
                                require('../ModalDialog').unknownErrorMessageDialog();
                            }
                        });
                    }
                }
            });
    }
    componentDidMount() {
        this.fillForm();
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    render() {
        const { email, sending, submitted, role, rolesList, companyRegisterCode, formType, codeNotExists, incorrectLogin, companies, selectedRegisterCode } = this.state;
        return (
            <div className="modal-body">
                <form name="form" className="popupSimple user-invite-form" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className={'form-group col-sm-6 col-xs-6' + (submitted && !email ? ' has-error' : '')}>
                            <label htmlFor="email" className="required">{i18next.t("email")}</label>
                            <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')} />
                            {submitted && !email &&
                                <div className="help-block">{i18next.t("requiredField")}</div>
                            }
                            {submitted && email && !checkEmail(email) &&
                                <div className="help-block">{i18next.t("badFieldFormat")}</div>
                            }
                            {submitted && email && incorrectLogin &&
                                <div className="help-block">{i18next.t("mailIsAlreadyInUse")}</div>
                            }
                        </div>
                        <div className={"form-group col-sm-6 col-xs-6" + (submitted && !role ? ' has-error' : '')}>
                            <label htmlFor="role" className="required">{i18next.t("role")}</label>
                            <select name="role" className="form-control" value={role} onChange={this.handleChange} disabled={(sending ? 'disabled' : '')}>
                                {
                                    rolesList.map(role =>
                                        <option key={role.name} value={role.name} > {role.title}</option>
                                    )
                                }
                            </select>
                            {submitted && !role &&
                                <div className="help-block">{i18next.t("requiredField")}</div>
                            }
                        </div>
                        {formType !== settingsFormTypes.settingsType && (role === 'Employee' || role === 'CabinetAdmin') &&
                            <div className='form-group col-sm-6 col-xs-6'>
                                <label htmlFor="companyRegisterCode" className="required">{i18next.t("EDRPOUCode")}</label>
                                <input type="number" className="form-control" name="companyRegisterCode" onKeyPress={(event) => { return parseInt(event.key) >= 0 && parseInt(event.key) <= 9 }} value={companyRegisterCode} onChange={this.handleChange} />
                                {submitted && !companyRegisterCode &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                                {submitted && companyRegisterCode && codeNotExists &&
                                    <div className="help-block">{i18next.t("noCounterpartyFound")}</div>
                                }
                            </div>
                        }
                        {formType === settingsFormTypes.settingsType &&
                            <div className='form-group col-sm-12 col-xs-12'>
                                <label htmlFor="selectedRegisterCode" className="required">{i18next.t("counterparty")}</label>
                                <FormControl className="modern-form-control">
                                    <Select
                                        name="selectedRegisterCode"
                                        className="modern-select"
                                        displayEmpty
                                        value={selectedRegisterCode}
                                        onChange={this.handleChange}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>{i18next.t("choseCounterparty")}</em>;
                                            }
                                            return companies.find(company => company.registerCode === selected).companyShortName;
                                        }}
                                    >
                                        {companies.map((company, index) =>
                                            <MenuItem key={index} value={company.registerCode}>{company.companyShortName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                {submitted && !selectedRegisterCode &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                            </div>
                        }
                        <div className='form-group'>
                        </div>
                    </div>
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary">{i18next.t("send")}</button>
                        <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />
                            <span className="btnDesk__info">{i18next.t("requiredFields")}</span>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    async fillForm() {
        const response = await fetch('siteSettings/getRolesForInvite', { method: 'GET' });
        const data = await response.json();
        let value = '';
        if (data.length > 0)
            value = data[0].name;
        this.setState({ rolesList: data, role: value });
    }
}
