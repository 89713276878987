import React, { Component } from 'react';
import { hideModalDialog } from '../ModalDialog';
import { Comment } from './Comment';
import { removeCommentAction } from './Comment';
export class CommentsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: this.props.comments ?? [],
            statuses: []
        };
        this.handleChangeComents = ((commets) => this.setState({ comments: commets ?? this.state.comments }, () => {
            this.props.handleChangeComents(commets);
            this.props.getComents();
        })).bind(this);
        this.handleChangeComent = this.handleChangeComent.bind(this);
        this.props.getComents();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.comments !== this.props.comments)
            this.setState({ comments: nextProps.comments });
    }
    render() {
        const { documentId } = this.props;
        const { comments, statuses } = this.state;
        return (<div className="form-block large-form doc-form">
            <div className="form-content">
                <div className="comments">
                    {comments.map((item) =>
                        <Comment getComents={this.props.getComents} key={require('uuid').v4()} statuses={statuses} handleChangeComents={this.handleChangeComents} handleChangeComent={this.handleChangeComent} documentId={documentId} handleDeliteComent={() => this.deliteComent.bind(this)(item)} comment={item} />
                    )}
                </div>
            </div>
        </div>);
    }
    async deliteComent(coment) {
        const { documentId } = this.props;
        if (documentId) {
            await removeCommentAction(coment, documentId);
            this.handleChangeComents([]);
        }
        else {
            let index = this.state.comments.indexOf(coment);
            if (index !== -1) {
                this.state.comments.splice(index, 1);
                this.setState({ comments: this.state.comments });
            }
            this.handleChangeComents(this.state.comments);
        }
        hideModalDialog('modalDialog');
    }
    handleChangeComent(comnet) {
        const { comments } = this.state;
        const index = comments.findIndex((item) => item.id === comnet.id);
        if (index !== -1) comments[index] = comnet;
        this.setState({ comments: this.state.comments });
        this.handleChangeComents(comments);
    }
}
