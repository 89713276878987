import React from 'react';
import { statutoryDocTypes } from '../../constants';
import i18next from "../../i18n";
import { StatutoryDocsForm } from '../forms/StatutoryDocsForm';
export function StatutoryDocumentsPage() {
    return (
        <div className="content">
            <h1>{i18next.t("statutoryDocuments")}</h1>
            <StatutoryDocsForm baseUrl="/statutoryDocs" key={statutoryDocTypes.statutory} docType={statutoryDocTypes.statutory} />
        </div>
    );
}