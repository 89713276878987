import React,{Component} from 'react';
import {v4} from 'uuid';
import {companyTypes} from '../../constants';
import i18next from "../../i18n";
import {DateTimeInput} from '../forms/DateTimeInput';
import {TransferListInput} from '../forms/TransferListInput';
import {ExportToCSV,getArrayIds,getFilterString,sortArray} from '../helpers/HelperFunctions';
import {hideModalDialog,showModalDialog} from '../ModalDialog';
import {Svg} from '../Svg';
import {TableColumnType} from './Table';
export class TableFilterBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnsSettings: props.columnsSettings ? props.columnsSettings : null,
            changedColumnsSettings: props.columnsSettings ? props.columnsSettings : null,
            onColumnsUpdateHandler: props.onColumnsUpdateHandler,
            filterValues: this.props.filterValues ? this.props.filterValues : {},
            showFilter: false,
            id: v4()
        };
        this.showColumnSettingsDialog = this.showColumnSettingsDialog.bind(this);
        this.columnsChangedHandler = this.columnsChangedHandler.bind(this);
        this.onColumnsChangedSave = this.onColumnsChangedSave.bind(this);
        this.onShowFilter = this.onShowFilter.bind(this);
        this.onFilterSearch = this.onFilterSearch.bind(this);
        this.onFilterClear = this.onFilterClear.bind(this);
        this.onFilterClose = this.onFilterClose.bind(this);
        this.renderFilterCol = this.renderFilterCol.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onExport = this.onExport.bind(this);
        this.getFilterInfo = this.getFilterInfo.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.columnsSettings !== this.state.columnsSettings) {
            let changed = Object.assign({}, nextProps.columnsSettings);
            this.setState({ columnsSettings: nextProps.columnsSettings, changedColumnsSettings: changed });
        }
        if (nextProps.filterValues && nextProps.filterValues !== this.state.filterValues) {
            this.setState({ filterValues: nextProps.filterValues });
        }
    }
    showColumnSettingsDialog() {
        const { columnsSettings } = this.state;
        let columns = columnsSettings && columnsSettings.columns ? columnsSettings.columns : [];
        let selected = columnsSettings && columnsSettings.selected ? columnsSettings.selected : [];
        columns = columns.filter(col => !col.hidden);
        let filtered = [];
        for (let i = 0; i < selected.length; i++) {
            let index = columnsSettings.columns.findIndex(col => col.id === selected[i] && !col.hidden);
            if (index > -1)
                filtered.push(selected[i]);
        }
        showModalDialog("columnSettingsDialog", i18next.t("columnSettings"), "", <TransferListInput handleChange={this.columnsChangedHandler} columns={columns} selected={filtered} />, "feedPopup middle-form", true, [{ title: i18next.t("save"), clickHandler: this.onColumnsChangedSave, class: "btn-primary" }, { title: i18next.t("cancel"), clickHandler: () => hideModalDialog('columnSettingsDialog'), class: "btn-secondary" }]);
    }
    columnsChangedHandler(columns, selected) {
        let { changedColumnsSettings } = this.state;
        let hidden = changedColumnsSettings.columns.filter(col => col.hidden);
        changedColumnsSettings.columns = columns.concat(hidden);
        let hiddenSelected = getArrayIds(hidden.filter(col => changedColumnsSettings.selected.includes(col.id)));
        changedColumnsSettings.selected = selected.concat(hiddenSelected);
        this.setState({ changedColumnsSettings: changedColumnsSettings });
    }
    onColumnsChangedSave() {
        hideModalDialog("columnSettingsDialog");
        if (this.state.onColumnsUpdateHandler) {
            this.state.onColumnsUpdateHandler(this.state.changedColumnsSettings);
        }
    }
    onShowFilter() {
        this.setState({ showFilter: !this.state.showFilter });
    }
    onFilterSearch(e) {
        if (e)
            e.preventDefault();
        this.setState({ submited: true });
        let hasError = false;
        const { filterValues } = this.state;
        const { columns } = this.props;
        if (columns) {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].type === TableColumnType.DateTime || columns[i].type === TableColumnType.Date) {
                    if (filterValues[columns[i].name] && filterValues[columns[i].name + "_to"]
                        && filterValues[columns[i].name].length > 0 && filterValues[columns[i].name + "_to"].length > 0
                        && filterValues[columns[i].name][0] > filterValues[columns[i].name + "_to"][0]) {
                        hasError = true;
                        break;
                    }
                }
            }
        }
        if (!hasError && this.props.onSearch) {
            this.props.onSearch(filterValues);
        }
        this.setState({ showFilter: false });
    }
    onFilterClear(e) {
        e.preventDefault();
        this.setState({ filterValues: {}, submited: false });
        if (this.props.onSearchClear) {
            this.props.onSearchClear();
        }
    }
    onFilterClose(e) {
        e.preventDefault();
        this.setState({ showFilter: false });
        this.onFilterSearch();
    }
    onExport() {
        let cols = [];
        const { columnsSettings } = this.state;
        if (columnsSettings && columnsSettings.columns) {
            cols = columnsSettings.columns.slice();
            if (columnsSettings.selected)
                cols = cols.filter(col => columnsSettings.selected.filter(selected => selected === col.id).length > 0);
            sortArray(cols, "sortIndex");
        }
        if (this.props.getItems) {
            this.props.getItems().then((items) => {
                ExportToCSV(cols, items, this.props.exportFileName);
            });
        }
        else
            ExportToCSV(cols, this.props.items, this.props.exportFileName);
    }
    renderFilterCol(col) {
        let value = this.state.filterValues[col.name] && this.state.filterValues[col.name].length === 1 ? this.state.filterValues[col.name][0] : "";
        if (!value)
            value = "";
        switch (col.type) {
            case TableColumnType.Date:
            case TableColumnType.DateTime:
                let value2 = this.state.filterValues[col.name + "_to"] ? this.state.filterValues[col.name + "_to"][0] : "";
                let onlyDate = col.type === TableColumnType.Date;
                if (!value2)
                    value2 = "";
                return ([
                    <label htmlFor="" key={'filter_label_' + col.name}>{col.filterTitle ? col.filterTitle : col.headerName}</label>,
                    <div className="row datetime-row" key={this.state.id + col.name}>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <DateTimeInput onlyDate={onlyDate} className="form-control" name={col.name} value={value} placeholder={i18next.t("from")} onChange={this.onHandleChange} />
                            {this.state.submited && value && value2 && value > value2 &&
                                <div className="help-block">{i18next.t("secondDateMustBeLess")}</div>
                            }
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <DateTimeInput onlyDate={onlyDate} className="form-control" name={col.name + "_to"} value={value2} placeholder={i18next.t("to")} onChange={this.onHandleChange} />
                        </div>
                    </div>
                ]);
            case TableColumnType.Status:
            case TableColumnType.Enum:
                let { allowedEnumValues } = this.props;
                let allowedValues = allowedEnumValues && allowedEnumValues[col.name] && allowedEnumValues[col.name].length > 0 ? allowedEnumValues[col.name] : null;
                return ([
                    <label htmlFor="" key={v4()}>{col.filterTitle ? col.filterTitle : col.headerName}</label>,
                    <select key={v4()} name={col.name} className="form-control" value={value} onChange={this.onHandleChange}>
                        <option key={v4()} value="">{i18next.t("all")}</option>
                        {col.enums &&
                            col.enums.filter(item => !allowedValues || allowedValues.includes(item.value)).map(item =>
                                <option key={v4()} value={item.value} > {item.title}</option>
                            )
                        }
                    </select>
                ]);
            default:
                if (value && value.startsWith("*"))
                    value = value.slice(1);
                if (value && value.endsWith("*"))
                    value = value.slice(0, -1);
                let title = col.filterTitle ? col.filterTitle : col.headerName;
                if (col.name === "RegisterCode") {
                    if (this.state.columnsSettings && this.state.columnsSettings.columns) {
                        let registerTypeCol = this.state.columnsSettings.columns.filter(col => col.name === 'CompanyType');
                        if (registerTypeCol) {
                            var typeValues = this.state.filterValues['CompanyType'];
                            var typeValue = typeValues && typeValues.length === 0 ? typeValues[0] : "";
                            if (typeValue === companyTypes.Entity)
                                title = 'Код ЄДРПОУ';
                            else if (typeValue === companyTypes.Individual)
                                title = 'Код ІПН';
                        }
                    }
                }
                return (<>
                    <label htmlFor="">{title}</label>
                    <input className="form-control" name={col.name} type="text" value={value} onChange={this.onHandleChange} />
                </>
                )
        }
    }
    onHandleChange(e) {
        e.preventDefault();
        let { filterValues } = this.state;
        let { name, value } = e.target;
        if (value) {
            if (e.target.getAttribute("type") === "text") {
                if (!value.startsWith("*"))
                    value = "*" + value;
                if (!value.endsWith("*"))
                    value += "*";
            }
                
            filterValues[name] = [value];
        }
        else if (filterValues.hasOwnProperty(name)) {
            delete filterValues[name];
        }
        this.setState({ filterValues: filterValues });
    }
    render() {
        let cols = [];
        const { additionalBtn, hideSettings } = this.props;
        const { showFilter, columnsSettings, filterValues } = this.state;
        if (columnsSettings && columnsSettings.columns) {
            cols = columnsSettings.columns.filter(col => col.canFilter);
            sortArray(cols, "filterIndex");
        }
        return (<>{!this.props.hideActions ? (<div className="filter-block-header">
            <div id="columnSettingsDialog"></div>
            <ul className="filter-header-buttons">
                <li>
                    <button type="button" className="btn no-bg-btn" onClick={this.onShowFilter}>
                        <Svg src="/img/search.svg" />
                        <span>{i18next.t("search")}</span>
                    </button>
                </li>
                <li>
                    <button type="button" className="btn no-bg-btn" onClick={this.onExport}>
                        <Svg src="/img/export.svg" />
                        <span>{i18next.t("export")}</span>
                    </button>
                </li>
                {!hideSettings ? <li>
                    <button type="button" className="btn no-bg-btn" onClick={this.showColumnSettingsDialog}>
                        <Svg src="/img/cols-settings.svg" />
                        <span>{i18next.t("columnSettings")}</span>
                    </button>
                </li> : ""}
                {this.props.handleUpdate && <li>
                    <button type="button" className="btn no-bg-btn" onClick={this.props.handleUpdate}>
                        <Svg src="/img/icn-refresh.svg" />
                        <span>{i18next.t("update")}</span>
                    </button>
                </li>}
                {additionalBtn &&
                    <li className="has-additionnal-btn">
                        <button type="button" className="btn btn-secondary additional-btn" onClick={additionalBtn.onClickHandler}>{additionalBtn.title}</button>
                    </li>
                }
            </ul>
        </div>) : ""}
            <div className="filter-form" style={{ display: (showFilter && cols.length > 0 ? "block" : "none") }}>
                <form name="form">
                    <div className="row">
                        {cols.map((col, i) => (
                            <div key={i} className={"form-group " + (col.width ? "" : "col-sm-3 col-xs-3")} style={{ width: col.width }}>
                                {this.renderFilterCol(col)}
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="form-group form-group__last col-sm-12 col-xs-12">
                            <button className="btn btn-primary" onClick={this.onFilterSearch}>{i18next.t("search")}</button>
                            <button className="btn btn-secondary" onClick={this.onFilterClear}>{i18next.t("cleanUp")}</button>
                            <button className="btn btn-no-fill" onClick={this.onFilterClose}>{i18next.t("close")}</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="filter-block-info" style={{ display: (((showFilter && cols.length) || getFilterString(filterValues).length === 0) ? "none" : "flex") }} key="3">
                <div className="filter-info">
                    <img src="/img/icn-filters.svg" alt=""></img><b>Фільтр: </b>{this.getFilterInfo(true)}
                </div>
                <div className="actions">
                    <button onClick={this.onFilterClear} type="button" className="btn">
                        <Svg src="/img/icn-close.svg" />
                        <span>{i18next.t("cleanUp")}</span>
                    </button>
                </div>
            </div></>
        );
    }
    getFilterInfo(useTitles = false) {
        const { filterValues, columnsSettings } = this.state;
        let result = "";
        Object.keys(filterValues).map(key => {
            if (filterValues[key]) {
                var currentCol = columnsSettings?.columns?.find((element) => {
                    return element?.name?.toLowerCase() === key?.toLowerCase();
                });
                if (!currentCol && key.endsWith('_to'))
                    currentCol = columnsSettings?.columns?.find((element) => {
                        return element?.name?.toLowerCase() === key.substring(0, key.length - 3)?.toLowerCase();
                    });
                if (currentCol) {
                    var value = '';
                    if (currentCol.type === TableColumnType.Date || currentCol.type === TableColumnType.DateTime) {
                        if (key.endsWith('_to')) {
                            var fromKey = Object.keys(filterValues).find(k => k.toLowerCase() === (key.substring(0, key.length - 3)).toLowerCase());
                            if (fromKey) {
                                value += 'з ' + filterValues[fromKey][0] + ' по ' + filterValues[key][0];
                            }
                            else value += 'по ' + filterValues[key][0];
                            result += currentCol.headerName + " = " + value + "; ";
                        }
                        else if (Object.keys(filterValues).find(k => k.toLowerCase() === (key + '_to').toLowerCase()));
                        else {
                            value += 'з ' + filterValues[key][0];
                            result += currentCol.headerName + " = " + value + "; ";
                        }
                    }
                    else {
                        if (currentCol.enums && currentCol.enums.length > 0) {
                            if (filterValues[key].length === 1 && filterValues[key][0] === "hideAllValue")
                                value = i18next.t("noValue");
                            else {
                                let { allowedEnumValues } = this.props;
                                let allowedValues = allowedEnumValues && allowedEnumValues[currentCol.name] && allowedEnumValues[currentCol.name].length > 0 ? allowedEnumValues[currentCol.name] : null;
                                var values = filterValues[key].filter(v => v !== "hideAllValue" && (!allowedValues || allowedValues.includes(v)));
                                for (let i = 0; i < values.length; i++) {
                                    if (i > 0)
                                        value += ', ';
                                    if (useTitles) {
                                        let finded = currentCol.enums.find(it => it.value?.toLowerCase() === values[i].toLowerCase());
                                        if (finded)
                                            value += finded.title;
                                    }
                                    else
                                        value += values[0];
                                }
                            }
                        }
                        else if (filterValues[key] && filterValues[key][0])
                            value = filterValues[key][0];
                        if (value && value !== undefined) {
                            if ([TableColumnType.String, TableColumnType.DocLink, TableColumnType.Default].includes(currentCol.type)) {
                                if (value.startsWith("*"))
                                    value = value.slice(1);
                                if (value.endsWith("*"))
                                    value = value.slice(0, -1);
                            }
                            result += currentCol.headerName + " = " + value + "; ";
                        }
                    }
                }
            }
        });
        return result;
    }
}
