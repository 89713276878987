import { isEmpty } from 'lodash';
import React, { Component } from 'react';
export class Svg extends Component {
    static defaultProps = { alt: '' };
    storageName = '@storage/svg';
    constructor(props) {
        super(props);
        const { src } = props;
        const markup = this.storage(src);
        const isLoaded = !isEmpty(markup);
        this.state = {
            markup, isLoaded
        };
        if (!isLoaded)
            this.loadSvg(src);
    }
    storage = (src, markup) => {
        const storage = JSON.parse(localStorage.getItem(this.storageName)) || {};
        if (isEmpty(markup))
            return storage[src];
        localStorage.setItem(this.storageName, JSON.stringify({
            ...storage,
            [src]: markup
        }))
    };
    loadSvg = src => {
        fetch(src)
            .then(res => res.text())
            .then(markup => {
                this.storage(src, markup);
                this.setState({
                    markup, isLoaded: true
                })
            })
            .catch(err => {
                console.warn(
                    `Can't fetch svg image ${src} because of: \n\n${err.toString()}`
                )
            })
    };
    render() {
        const { src, alt = '', ...props } = this.props;
        const { isLoaded, markup } = this.state;
        if (isLoaded) return (<div className="svg-img-block" {...props} dangerouslySetInnerHTML={{ __html: markup }} />);
        return (<img src={src} alt={alt} {...props} />);
    }
}