import React, { Component } from 'react';
import { allowedStatutoryFileExtensions, statutoryDocTypes } from '../../constants';
import i18next from "../../i18n";
import { checkFileName } from '../helpers/CheckFunctions';
import { showModalDialog } from '../ModalDialog';
import { errorMessageDialog } from '../ModalDialog';
export class EditFileForm extends Component {
    constructor(props) {
        super(props);
        let folderName = '';
        let folderTitle = '';
        if (props.folderName)
            folderName = props.folderName;
        if (props.folderTitle)
            folderTitle = props.folderTitle;
        this.state = {
            name: '',
            sending: props.fileName ? true : false,
            submitted: false,
            nameExists: false,
            incorrectName: false,
            folderType: this.props.folderType,
            file: null,
            badExtension: false,
            folderName: folderName,
            folderTitle: folderTitle,
            fileName: props.fileName
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadDocument = this.loadDocument.bind(this);
    }
    handleFileRemove(event) {
        document.getElementById("file-iput-controll").value = "";
        this.setState({ file: null });
    }
    handleFileChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            var parts = event.target.files[0].name.split('.');
            if (parts.length > 1 /*&& allowedStatutoryFileExtensions.findIndex(ex => ex === parts[parts.length - 1].toLowerCase()) > -1*/) {
                this.setState({
                    badExtension: false,
                    file: event.target.files[0]
                });
            }
            else {
                this.setState({
                    badExtension: false /*true*/,
                    file: null
                });
            }
        }
        else {
            event.target.value = "";
            this.setState({
                badExtension: false,
                file: null
            });
        }
    }
    async componentDidMount() {
        if (this.props.fileName) {
            await this.loadDocument();
        }
    }
    async loadDocument() {
        const { folderName, fileName, baseDir } = this.state;
        const response = await fetch(`/home/getFile?baseDir=${baseDir}&dir=${folderName}&fileName=${fileName}`);
        if (response.ok) {
            const data = await response.json();
            if (!data)
                require('../ModalDialog').unknownErrorMessageDialog();
            else
                this.setState({ name: data.title, id: data.id, sending: false });
        }
        else require('../ModalDialog').unknownErrorMessageDialog();
    }
    handleChange(event) {
        const { name, value } = event.target;
        if (name === "name") {
            this.setState({
                [name]: value,
                nameExists: false,
                incorrectName: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }
    handleSubmit(event) {
        event.preventDefault();
        const { sending, name, nameExists, incorrectName, file, badExtension } = this.state;
        if (!sending)
            this.setState({ submitted: true });
        if (!sending && !badExtension && !nameExists && !incorrectName && name && checkFileName(name) && file || this.props.fileName && name) {
            this.setState({ sending: true });
            this.submit(name);
        }
    }
    onCancel() {
        const { onCloseHandler } = this.props;
        if (!this.state.sending && onCloseHandler)
            onCloseHandler();
    }
    async submit(name) {
        const { baseDir, fileName } = this.props;
        const { folderName, file } = this.state;
        const currentObject = this;
        const formData = new FormData();
        formData.append('name', name);
        let url = "";
        if (!this.props.fileName) {
            url = `/home/addFIle?baseDir=${baseDir}`;
            formData.append('folderName', folderName);
            formData.append('files', file, file.name);
        }
        else {
            url = `/home/editFile?oldPath=${baseDir}/${folderName}/${fileName}&path=${baseDir}/${folderName}/${this.state.name}`;
        }
        const requestOptions = {
            method: 'POST',
            headers: { "Contetnt-Type": "multipart/form-data" },
            body: formData
        };
        function onClose() { window.location.reload(); }
        fetch(url, requestOptions)
            .then((response) => {
                if (response.status === 413)
                    return errorMessageDialog(i18next.t("11"))
                return response.ok ? response.text().then(() => {
                    currentObject.setState({ sending: false });
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">{folderName ? "Файл успішно збережено" : "Файл успішно додано"}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                }) : response.text().then((errorText) => {
                    console.log(errorText);
                    if (errorText === "BadExtension") {
                        this.setState({ badExtension: true, sending: false });
                    }
                    else if (errorText === "IncorrectName") {
                        this.setState({ incorrectName: true, sending: false });
                    }
                    else {
                        console.log(errorText);
                        require('../ModalDialog').unknownErrorMessageDialog();
                    }
                });
            });
    }
    render() {
        const { name, file, sending, submitted, incorrectName, folderTitle, badExtension, folderType } = this.state;
        return (
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple file-upload-form">
                        <div className="row">
                            <div className='form-group col-sm-12 col-xs-12 folders-breadcrumb'>
                                <label>Розташування: </label>
                                <label className="folder-name-label"><img src="/img/folder.svg" />{folderType === statutoryDocTypes.statutory ? i18next.t("statutoryDocuments") : (folderType === statutoryDocTypes.template ? i18next.t("templateDocuments") : i18next.t("announcementDocuments"))}</label>
                                <label className="folder-name-label"><img src="/img/folder.svg" />{folderTitle}</label>
                            </div>
                            <div className={'form-group col-sm-12 col-xs-12' + (submitted && !name ? ' has-error' : '')}>
                                <label htmlFor="name" className="required">{i18next.t("documentName")}</label>
                                <input className="form-control" name="name" value={name} disabled={(sending ? 'disabled' : '')} onChange={this.handleChange} />
                                {submitted && !name &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                                {submitted && name && incorrectName &&
                                    <div className="help-block">{i18next.t("badFieldFormat")}</div>
                                }
                            </div>
                            <div className="form-group col-sm-12 col-xs-12">
                                {file ?
                                    <label className={"upload-file-label" + (sending ? " disabled" : "")}>{file.name} <span className="close-btn" onClick={this.handleFileRemove}></span></label>
                                    :
                                    (
                                        this.props.fileName ?
                                            <label className={"upload-file-label" + (sending ? " disabled" : "")}>{this.props.fileName}</label>
                                            :
                                            <label className={"upload-file-label upload-file-btn" + (sending ? " disabled" : "")} onClick={() => document.getElementById("file-iput-controll").click()}>{i18next.t("addFile")}</label>
                                    )
                                }
                                {submitted && (!file && !this.props.fileName) && !badExtension &&
                                    <div className="help-block">{i18next.t("requiredField")}</div>
                                }
                                {submitted && badExtension &&
                                    <div className="help-block">{i18next.t("invalidFileFormatMessage", { extensions: allowedStatutoryFileExtensions.join(' ') })}</div>
                                }
                                <input className="file-select" name="file" id="file-iput-controll" style={{ display: "none" }} onChange={this.handleFileChange} type="file" />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary" onClick={this.handleSubmit}>{i18next.t("save")}</button>
                        <button type="button" className="btn btn-secondary" onClick={this.onCancel}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
