import React from 'react';
import { actionTypes, statutoryDocTypes } from '../../constants';
import i18next from "../../i18n";
import { dispatcherActionTypes, globalEventDispatcher } from '../helpers/GlobalEventDispatcher';
import { getOnPageCount, setOnPageCount } from '../helpers/HelperFunctions';
import { hideModalDialog, showModalDialog, unknownErrorMessageDialog, errorMessageDialog, viewFileFolderSystemFile } from '../ModalDialog';
import { FoldersListBlock } from '../table/FoldersListBlock';
import { Table } from '../table/Table';
import { TableButtonsBlock } from '../table/TableButtonsBlock';
import { ActionsPanel } from './ActionsPanel';
import { EditFileForm } from './EditFileForm';
import { EditFolderForm } from './EditFolderForm';
export class StatutoryDocsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: getOnPageCount(),
            pageCount: 1,
            sortBy: '',
            isDesk: false,
            filter: '',
            dir: '',
            checkedList: [],
            inPopup: this.props.inPopup,
            folders: [],
            dirActions: [],
            wasFiltered: false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.actionHandlerCallback = this.actionHandlerCallback.bind(this);
        this.onDirActionClickHandler = this.onDirActionClickHandler.bind(this);
        this.downloadFiles = this.downloadFiles.bind(this);
        this.deleteFiles = this.deleteFiles.bind(this);
        this.loadFilePreview = this.loadFilePreview.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.onChengeFolderHandler = this.onChengeFolderHandler.bind(this);
        this.loadDocuments = this.loadDocuments.bind(this);
        this.onCheckChangeHandler = this.onCheckChangeHandler.bind(this);
        this.onUnselectAllRows = this.onUnselectAllRows.bind(this);
    }
    componentDidMount() {
        const { page, pageSize, folders } = this.state;
        let { dir } = this.state;
        if (!dir) {
            let pathArray = window.location.href.split(this.props.baseUrl + "/");
            dir = (folders && folders.length > 0) ? folders[0].name : "";
            if (pathArray.length > 1)
                dir = pathArray[pathArray.length - 1].split('?')[0];
            dir = decodeURI(dir);
        }
        this.loadDocuments(dir, page, pageSize);
    }
    async loadDocuments(dir, page, pageSize, sortBy = '', isDesk = false) {
        let skip = (page - 1) * pageSize;
        let url = this.props.docType === statutoryDocTypes.statutory ? `/home/GetStatutoryDocuments?dir=${dir}&skip=${skip}&maxCount=${pageSize}&sortBy=${sortBy}&isDesk=${isDesk}` :
            this.props.docType === statutoryDocTypes.template ? `/home/GetTemplateDocuments?dir=${dir}&skip=${skip}&maxCount=${pageSize}&sortBy=${sortBy}&isDesk=${isDesk}` :
                `/home/GetAnnouncementDocuments?dir=${dir}&skip=${skip}&maxCount=${pageSize}&sortBy=${sortBy}&isDesk=${isDesk}`;
        const response = await fetch(url, { method: 'GET' });
        if (!response.ok) {
            unknownErrorMessageDialog();
            return;
        }
        const data = await response.json();
        const pageCount = Math.ceil(data.tableInfo.allCount / pageSize);
        if (this.state.inPopup) {
            data.tableInfo.actionsForAll = [];
            if (data.tableInfo.rows) {
                for (var i = 0; i < data.tableInfo.rows.length; i++) {
                    data.tableInfo.rows[i].actions = [];
                }
            }
        }
        this.setState({ dirActions: data.dirActions, baseDir: data.baseDir, folders: data.folders, documentsData: data.tableInfo, pageCount: pageCount, sortBy: data.tableInfo.sortBy, isDesk: data.tableInfo.isDesk, dir: data.dir });
    }
    handleSortChange(sortBy, isDesk) {
        const { dir, page, pageSize, filter } = this.state;
        this.loadDocuments(dir, page, pageSize, sortBy, isDesk, filter);
    }
    handlePageChange(page) {
        const { dir, pageSize, sortBy, isDesk, filter } = this.state;
        this.setState({ page: page });
        this.loadDocuments(dir, page, pageSize, sortBy, isDesk, filter);
    }
    handleChangePageSize(pageSize) {
        const { dir, sortBy, isDesk, filter } = this.state;
        setOnPageCount(pageSize);
        this.setState({
            pageSize: pageSize,
            page: 1
        });
        this.loadDocuments(dir, 1, pageSize, sortBy, isDesk, filter);
    }
    async downloadFiles(ids) {
        let { dir, baseDir } = this.state;
        for (var i = 0; i < ids.length; i++) {
            let downloadFunction = function (fileName) {
                fetch(`home/GetFileContent?path=${baseDir}/${dir}/${ids[i]}`, { method: 'POST' })
                    .then(response => response.blob())
                    .then(blobby => {
                        let objectUrl = window.URL.createObjectURL(blobby);
                        let anchor = document.createElement("a");
                        anchor.href = objectUrl;
                        anchor.download = fileName;
                        anchor.click();
                        window.URL.revokeObjectURL(objectUrl);
                    });
            }
            downloadFunction(ids[i]);
        }
    }
    async deleteFiles(idsArray) {
        let { dir, baseDir } = this.state;
        let ids = idsArray.join('|');
        let url = `/home/DeleteFile?basePath=${baseDir}/${dir}&names=${ids}`;
        const response = await fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) {
            var errorMessage = i18next.t("unknownErrorMessage");   
        }
        if (errorMessage) {
            showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{errorMessage}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => hideModalDialog("loadingDialog"), class: "btn-primary" }]);
        }
        else {
            hideModalDialog("loadingDialog");
            const { dir, pageSize, sortBy, isDesk, filter, page } = this.state;
            this.loadDocuments(dir, page, pageSize, sortBy, isDesk, filter);
        }
    }
    async loadFilePreview(fileName) {
        let { dir, baseDir } = this.state;
        viewFileFolderSystemFile(`home/GetFileContent?path=${baseDir}/${dir}/${fileName}`, fileName);
    }
    actionHandlerCallback(actionType, ids) {
        const currentObject = this;
        const { page, pageSize, dir, folders, baseDir } = this.state;
        if (ids.length > 0) {
            switch (actionType) {
                case actionTypes.edit:
                    {
                        let confirmDialog = function () {
                            currentObject.loadDocuments(dir, page, pageSize);
                        }
                        let folderName = '';
                        if (folders && folders.length > 0) {
                            var filtered = folders.filter(folder => folder.name === dir);
                            if (filtered && filtered.length > 0)
                                folderName = filtered[0].title;
                        }
                        showModalDialog("loadingDialog", i18next.t("editDocument"), "", <EditFileForm fileName={ids[0]} folderType={this.props.docType} folderTitle={folderName} baseDir={baseDir} folderName={`${dir}`} onSubmitedHandler={confirmDialog} onCloseHandler={() => { hideModalDialog("loadingDialog"); }} />, "feedPopup feedPopup__add  middle-form");
                    }
                    break;
                case actionTypes.remove:
                    {
                        let closeDialog = function () {
                            hideModalDialog("loadingDialog");
                        }
                        let confirmDialog = function () {
                            currentObject.deleteFiles(ids);
                        }
                        showModalDialog("loadingDialog", i18next.t("removing"), "", <div className="info-message">{i18next.t("removeDocumentsMessage")}</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: confirmDialog, class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                    }
                    break;
                case actionTypes.preview:
                    this.loadFilePreview(ids[0]);
                    break;
                case actionTypes.download:
                    this.downloadFiles(ids);
                    break;
            }
        }
    }
    deleteFolder(name) {
        let { dir, baseDir } = this.state;
        const currentObject = this;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };
        let url = `/home/DeleteFolder?path=${baseDir}/${dir}`;
        fetch(url, requestOptions)
            .then((response) => {
                return response.ok ? response.text().then(() => {
                    function onClose() {
                        hideModalDialog("loadingDialog");
                        if (currentObject.props.baseUrl)
                            window.location.href = currentObject.props.baseUrl;
                    }
                    showModalDialog("loadingDialog", i18next.t("done"), "", <div className="info-message">Папку успішно видалено</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                }) : response.text().then((errorText) => {
                    switch (errorText) {
                        case "Forbidden":
                            hideModalDialog("loadingDialog");
                            errorMessageDialog(i18next.t("12"));
                            break;
                        case "NotFound": {
                            function onClose() {
                                hideModalDialog("loadingDialog");
                                window.location.replace(`${window.location.pathname.split('/').slice(0, 3).join('/')}`);
                            }
                            currentObject.setState({ sending: false });
                            showModalDialog("loadingDialog", i18next.t("error"), "", <div className="info-message">{i18next.t("folderNotFound")}</div>, "feedPopup", true, [{ title: i18next.t("ok"), clickHandler: () => onClose(), class: "btn-primary" }], onClose);
                            break;
                        }
                        default:
                            console.log(errorText);
                            unknownErrorMessageDialog();
                            break;
                    }
                });
            });
    }
    onDirActionClickHandler(actionType) {
        const { dir, folders, baseDir } = this.state;
        const currentObject = this;
        switch (actionType) {
            case actionTypes.addDir:
            case actionTypes.edit:
                showModalDialog("loadingDialog", actionType === actionTypes.addDir ? i18next.t("addFolder") : i18next.t("editFolder"), "", <EditFolderForm baseDir={baseDir} oldName={actionTypes.edit === actionType ? dir : ""} onCloseHandler={() => { hideModalDialog("loadingDialog"); }} />, "feedPopup middle-form");
                break;
            case actionTypes.remove:
                {
                    let closeDialog = function () {
                        hideModalDialog("loadingDialog");
                    }
                    let confirmDialog = function () {
                        currentObject.deleteFolder(dir);
                    }
                    showModalDialog("loadingDialog", i18next.t("removing"), "", <div className="info-message">Ви впевнені, що бажаєте видалити папку з усіма документами в ній?</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: confirmDialog, class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                }
                break;
            case actionTypes.add:
                {
                    let folderName = '';
                    if (folders && folders.length > 0) {
                        var filtered = folders.filter(folder => folder.name === dir);
                        if (filtered && filtered.length > 0)
                            folderName = filtered[0].title;
                    }
                    showModalDialog('modalDialog', i18next.t("addDocument"), "", <EditFileForm baseDir={baseDir} folderType={this.props.docType} folderTitle={folderName} folderName={dir} onCloseHandler={() => { hideModalDialog('modalDialog'); }} />, "feedPopup feedPopup__add  middle-form");
                }
                break;
        }
    }
    onChengeFolderHandler(folder) {
        if (!this.props.baseUrl) {
            const { pageSize, sortBy, isDesk, filter } = this.state;
            this.setState({
                dir: folder,
                page: 1,
                checkedList: []
            });
            this.loadDocuments(folder, 1, pageSize, sortBy, isDesk, filter);
        }
    }
    onUnselectAllRows() {
        this.setState({ checkedList: [] });
        globalEventDispatcher.dispatch(dispatcherActionTypes.onUnselectTableAllRows);
    }
    onCheckChangeHandler(checkedList) {
        this.setState({ checkedList: checkedList });
    }
    render() {
        const { documentsData, page, pageSize, pageCount, sortBy, isDesk, dirActions, folders, dir, inPopup, checkedList } = this.state;
        return (
            <div className={"statutory-docs-form" + (inPopup ? " statutory-docs-popup" : "")}>
                {dirActions && dirActions.length > 0 && this.props.canEdit &&
                    [
                        <TableButtonsBlock buttons={dirActions} onClickHandler={this.onDirActionClickHandler} />
                    ]
                }
                <FoldersListBlock folders={folders} baseUrl={this.props.baseUrl} onChangeHandler={this.onChengeFolderHandler} current={dir} />
                {documentsData && documentsData.columns &&
                    <Table allowDoubleClickAction={true} key={dir} onCheckChangeHandler={this.onCheckChangeHandler} allCount={documentsData.allCount} handleSortChange={this.handleSortChange} actionHandlerCallback={this.actionHandlerCallback} columns={documentsData.columns} sortBy={sortBy} isDesk={isDesk} items={documentsData.rows} showPagination={true} page={page} pageSize={pageSize} pageCount={pageCount} handlePageChange={this.handlePageChange} handleChangePageSize={this.handleChangePageSize} />
                }
                {inPopup && checkedList.length > 0 &&
                    <div className="popup-actions-panel">
                        <ActionsPanel actions={[{
                            isMultiaction: true,
                            title: i18next.t("download"),
                            type: actionTypes.download
                        }]}
                            handlerUncheckAll={this.onUnselectAllRows} actionHandlerCallback={(actionType) => this.actionHandlerCallback(actionType, checkedList)} checkedCount={checkedList.length} />
                    </div>
                }
            </div>
        );
    }
}