import React from 'react';
import { statutoryDocTypes } from '../../constants';
import i18next from "../../i18n";
import { StatutoryDocsForm } from '../forms/StatutoryDocsForm';
export function TemplateDocumentsPage(props) {
    return (
        <div className="content">
            <h1>{i18next.t("templateDocuments")}</h1>
            <StatutoryDocsForm baseUrl="/templates" key={statutoryDocTypes.template} docType={statutoryDocTypes.template} />
        </div>
    );
}